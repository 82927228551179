/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { AfterContentInit, Component, EventEmitter, forwardRef, Inject, Input, OnInit, Output } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { cloneDeep, sum } from 'lodash';

import {
  ILegacyAnalyticsService,
  LEGACY_ANALYTICS_SERVICE
} from '../../../../ecomm/providers/legacy-providers/analytics.service';
import { MenuItem, MenuModifier, MenuModifierGroup } from '../../../../ecomm/types/store-info.types';
import { getCalorieRange } from '../../../../ecomm/utils/calorie-range';
import { areModifierGroupsValid } from '../../../../ecomm/utils/pdp-utils';
import {
  FlavorQuantitySelectionsType,
  FlavorSelectionService,
  ModGroups,
  ModifierModalElementChangeService,
  Modifiers
} from '../../../common';
import { ModifierModalComponent } from '../modifier-modal/modifier-modal.component';
import { NotificationService } from "../../../../ecomm/utils/notification/notification.service";

export type ModifierGroupFormData = {
  modifiers: Modifiers[] | null;
};

export type ModifierGroupsFormData = {
  modifierGroups: ModGroups[] | null;
};

@Component({
  selector: 'wri-modifier-element',
  templateUrl: './modifier-element.component.html',
  styleUrls: ['./modifier-element.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => ModifierElementComponent)
    }
  ]
})
export class ModifierElementComponent implements AfterContentInit, OnInit {
  @Input() simpleModifierData!: MenuModifier | null;
  @Input() complexModifierData!: MenuItem | null;
  @Input() parentId: string | undefined;
  @Input() maxSelectionsAllowed = 0;
  @Input() minModifierQuantity = 0;
  @Input() maxModifierQuantity = 0;
  @Input() aggregateQuantity = 0;
  @Input() quantitiesEnabled = false;
  @Input() selectedModifiers: Modifiers[] = [];
  @Input() class = '';
  @Input() type = 'simpleModifier';
  @Input() modGroupId: string | undefined;
  @Input() modGroupElementType: string
  @Output()
  resetPDPValidations = new EventEmitter();
  @Output() modifierQuantitySelections = new EventEmitter<{
    systemDefined: number;
    userDefined: number;
  }>();

  public selectedItemModifierGroups: ModGroups[] = [];
  /** Implement ControlValueAccessor */
  disabled = false;
  touched = false;
  private defaultSelectedItemModifierGroups: ModGroups[] = [];
  private hasSelectedModifierElementsChanged = false;
  requiredUnselectedModifierGroupLabels: string | undefined | null;
  checkedModElementIds: string[] = [];
  private isInitiallyEditable = true;

  constructor(
    public modalService: NgbModal,
    private modifierModalElementChangeService: ModifierModalElementChangeService,
    @Inject(LEGACY_ANALYTICS_SERVICE)
    private analyticsService: ILegacyAnalyticsService,
    private quantitySelectionService: FlavorSelectionService,
    private notificationService: NotificationService
  ) {}

  /** Implement ControlValueAccessor */
  set value(value: number | Modifiers[]) {
    this.onChange(value);
  }

  ngAfterContentInit(): void {
    if (
      this.simpleModifierData &&
      this.simpleModifierData.isDefault &&
      !this.simpleModifierData?.outOfStock &&
      this.simpleModifierData?.availableInSchedule
    ) {
      this.onChangeModifierElement(
        true,
        this.maxSelectionsAllowed > 1 ? 'multiple' : 'single',
        this.simpleModifierData,
        true // if triggered by default without manually clicking on checkbox
      );
    }
    if (
      this.complexModifierData &&
      this.complexModifierData.isDefault &&
      !this.complexModifierData?.outOfStock &&
      this.complexModifierData?.availableInSchedule
    ) {
      this.onChangeModifierElement(
        true,
        this.maxSelectionsAllowed > 1 ? 'multiple' : 'single',
        this.complexModifierData,
        true // if triggered by default without manually clicking on checkbox
      );
    }
  }

  ngOnInit(): void {
    this.getDefaultSelectedValue();
    this.defaultSelectedItemModifierGroups = cloneDeep(
      this.selectedItemModifierGroups
    );
  }

  async openModifierModal(modifierId: string) {
    const modalRef = this.modalService.open(ModifierModalComponent, {
      windowClass: 'modifier-common-modal',
      centered: true,
      scrollable: true,
      modalDialogClass: 'modal-fullscreen-md-down',
      backdrop: 'static',
      keyboard: false
    });
    modalRef.componentInstance.data = this.complexModifierData?.modifierGroups;
    modalRef.componentInstance.selectedItemModifierGroups =
      this.selectedItemModifierGroups;
    modalRef.componentInstance.hasSelectedModifierElementsChanged =
      this.hasSelectedModifierElementsChanged;

    const values = await modalRef.result;
    if (values) {
      const { itemModifierGroups, isValid, isApplied } = values;
      if (isApplied) {

        // sort based on selection
        const sortedModifierGroups = itemModifierGroups
          .filter(group => group.modifierGroupSortOrder != null) // Filters out null or undefined
          .sort((a, b) => a.modifierGroupSortOrder! - b.modifierGroupSortOrder!);

        this.requiredUnselectedModifierGroupLabels = null;
        // whenever a modifier is selected in customise modal - reset validations
        this.resetPDPValidations.emit();
        this.hasSelectedModifierElementsChanged = true;
        this.selectedItemModifierGroups = sortedModifierGroups;
        // update validity of modifier
        this.selectedModifiers = this.selectedModifiers.map((modifier) => {
          if (modifier.modifierId === modifierId) {
            const updatedModifier = { ...modifier, isValid: isValid };
            if (sortedModifierGroups.length > 0) {
              updatedModifier.modifierGroups = sortedModifierGroups;
            } else {
              delete updatedModifier.modifierGroups; // Delete `modifierGroups` if empty
            }
            return updatedModifier;
          }
          return modifier; // Unchanged modifiers
        });

        // trigger change in the form to reflect modifier modal values
        this.onTouched();
        this.onChange(this.selectedModifiers);
      } else {
        if (!isValid) this.requiredUnselectedModifierGroupLabels = this.getRequiredUnselectedModifierGroupLabels();
      }
    } else {
      this.requiredUnselectedModifierGroupLabels = this.getRequiredUnselectedModifierGroupLabels();
    }
  }

  isItemDisabled(modifierItemId: string): boolean {
    return this.selectedModifiers.length === this.maxSelectionsAllowed &&
      !this.selectedModifiers.some(
        (modifier) => modifier.modifierId === modifierItemId
      );
  }

  isItemChecked(modifierItemId: string): boolean {
    return this.selectedModifiers.some(
      (modifier) => modifier.modifierId === modifierItemId
    );
  }

  onChange: (v: number | Modifiers[]) => void = () => void 0;

  onTouched: () => void = () => void 0;

  onChangeModifierElement(
    checked: boolean,
    type: string,
    modifierData: MenuModifier | MenuItem,
    isTriggeredByDefault = false
  ) {
    // whenever a modifier is selected - reset validations
    this.resetPDPValidations.emit();
    if (checked) {
      // reset to default values when modifier checked
      if (!isTriggeredByDefault) {
        this.getDefaultSelectedValue(true);
        this.analyticsService.logGaEvent({
          event: 'choose_options',
          item_name: modifierData.name,
          count: 1
        });
        this.requiredUnselectedModifierGroupLabels = undefined;
        if (this.itemHasRequiredUndefaultedModifiers()) {
          // open customise modal
          if (this.quantitiesEnabled) {
            if (this.isModifierElementEligibleToBeChecked(modifierData.id))
              this.openModifierModal(modifierData.id);
            else
              this.requiredUnselectedModifierGroupLabels = null;
          } else {
            this.openModifierModal(modifierData.id);
          }
        } else {
          this.requiredUnselectedModifierGroupLabels = null;
        }
      } else {
        this.requiredUnselectedModifierGroupLabels =
          this.itemHasRequiredUndefaultedModifiers()
            ? this.getRequiredUnselectedModifierGroupLabels()
            : null;
      }
      if (type === 'multiple') {
        if (this.quantitiesEnabled) {
          // if quantitiesEnabled: true, do validation logic check
          if (this.isModifierElementEligibleToBeChecked(modifierData.id)) {

              this.selectedModifiers.push({
                modifierId: modifierData.id,
                quantity: 0,
                price: modifierData.price,
                name: modifierData.name,
                isValid: areModifierGroupsValid(
                  (modifierData as MenuItem)?.modifierGroups,
                  this.selectedItemModifierGroups
                ),
                modifierGroups: this.selectedItemModifierGroups
              });

          } else {
            this.notificationService.showError(
                `You do not have any more selections available.`
            );
          }
        }
        else {
          this.selectedModifiers.push({
            modifierId: modifierData.id,
            quantity: 1,
            price: modifierData.price,
            name: modifierData.name,
            isValid: areModifierGroupsValid(
              (modifierData as MenuItem)?.modifierGroups,
                this.selectedItemModifierGroups
            ),
            modifierGroups: this.selectedItemModifierGroups
          });
        }
      } else if (type === 'single') {
        this.selectedModifiers = [
          {
            modifierId: modifierData.id,
            quantity: 1,
            price: modifierData.price,
            name: modifierData.name,
            isValid: areModifierGroupsValid(
              (modifierData as MenuItem)?.modifierGroups,
              this.selectedItemModifierGroups
            ),
            modifierGroups: this.selectedItemModifierGroups
          }
        ];
      }
    } else {
      if (modifierData.quantity) {
        const modifierWithQuantityChangedIndex =
            this.selectedModifiers.findIndex(
                (modifier) => modifier.modifierId === modifierData.id
            );
        this.selectedModifiers[modifierWithQuantityChangedIndex].quantity = 0;
        this.selectedModifiers[modifierWithQuantityChangedIndex].touched =
            false;
      }
      this.selectedModifiers = this.selectedModifiers.filter(
        (mod) => mod.modifierId !== (modifierData ? modifierData.id : '')
      );
    }
    if (this.quantitiesEnabled) {
      if (modifierData.quantity && this.isInitiallyEditable) {
        // not to rebalance if quantity values present (which means editable)
        const modifierWithQuantityChangedIndex =
            this.selectedModifiers.findIndex(
                (modifier) => modifier.modifierId === modifierData.id
            );
        if (modifierWithQuantityChangedIndex > -1) {
          this.selectedModifiers[modifierWithQuantityChangedIndex].quantity =
              modifierData.quantity;
        }
        this.isInitiallyEditable = false;
      } else {
        this.quantitySelectionService.rebalanceSystemDefinedIfNeeded(
          modifierData?.metadata?.['item-type'] || 'Wings',
            this.selectedModifiers,
            this.aggregateQuantity,
            this.maxModifierQuantity,
            this.minModifierQuantity
        );
      }
    }

    this.buildModifierQuantitySelectionState();
    this.updateCheckedModElements();
    this.onTouched();
    this.onChange(this.selectedModifiers);
  }

  writeValue(newValue: number | Modifiers[]): void {
    this.value = newValue;
  }

  registerOnChange(
    onChange: (v: number | Modifiers[]) => Modifiers | undefined
  ): void {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: () => void): void {
    this.onTouched = onTouched;
  }

  markAsTouched() {
    if (!this.touched) {
      this.onTouched();
      this.touched = true;
    }
  }

  setDisabledState(disabled: boolean) {
    this.disabled = disabled;
  }

  returnCalorieRange(modifierData: MenuModifier | MenuItem): string | number {
    return getCalorieRange(modifierData);
  }

  private getDefaultSelectedValue(isTriggeredWithModifierChange = false) {
    const itemModifierGroups = this.complexModifierData?.modifierGroups?.filter(
      (m) => m.availableInSchedule
    );
    if (isTriggeredWithModifierChange)
      this.selectedItemModifierGroups = this.defaultSelectedItemModifierGroups;
    itemModifierGroups?.forEach((itemModifierGroup) => {
      itemModifierGroup.modifierGroupElements.forEach(
        (modifierGroupElement) => {
          if (
            modifierGroupElement.modifier?.isDefault &&
            !(modifierGroupElement.modifier as MenuModifier)?.outOfStock &&
            modifierGroupElement.modifier?.availableInSchedule
          ) {
            this.modifierModalElementChangeService.onChangeModalModifierElement(
              this.selectedItemModifierGroups, // this variable will be updated with default selected modifiers
              true,
              itemModifierGroup.maxSelectionsAllowed > 1
                ? 'multiple'
                : 'single',
              itemModifierGroup.id,
              modifierGroupElement.modifier!,
              itemModifierGroup.sortOrder
            );
          } else if (
            modifierGroupElement.item?.isDefault &&
            !(modifierGroupElement.item as MenuModifier)?.outOfStock &&
            modifierGroupElement.item?.availableInSchedule
          ) {
            this.modifierModalElementChangeService.onChangeModalModifierElement(
              this.selectedItemModifierGroups, // this variable will be updated with default selected modifiers
              true,
              itemModifierGroup.maxSelectionsAllowed > 1
                ? 'multiple'
                : 'single',
              itemModifierGroup.id,
              modifierGroupElement.item!,
              itemModifierGroup.sortOrder
            );
          }
        }
      );
    });
    this.requiredUnselectedModifierGroupLabels = null;
  }

  itemHasRequiredUnselectedModifierGroups(itemModifierGroup: MenuModifierGroup): boolean {
    const defaultModifier = itemModifierGroup.modifierGroupElements.find(
      (modifierGroupElement) =>
        (modifierGroupElement.modifier && modifierGroupElement.modifier.isDefault === true) ||
        (modifierGroupElement.item && modifierGroupElement.item.isDefault === true)
    );
    return itemModifierGroup.required && defaultModifier === undefined;
  }

  itemHasRequiredUndefaultedModifiers(): boolean {
    const itemModifierGroups = this.complexModifierData?.modifierGroups?.filter(
      (m) => m.availableInSchedule
    );
    let isConditionMatched = false;
    isConditionMatched = itemModifierGroups?.some((itemModifierGroup) => {
      return this.itemHasRequiredUnselectedModifierGroups(itemModifierGroup);
    }) || false;
    return isConditionMatched;
  }

  getRequiredUnselectedModifierGroupLabels(): string | undefined {
    const itemModifierGroups = this.complexModifierData?.modifierGroups?.filter(
      (m) => m.availableInSchedule
    );
    let label = '';
    const allLabels: string[] = [];
    itemModifierGroups?.forEach((itemModifierGroup) => {
      if (this.itemHasRequiredUnselectedModifierGroups(itemModifierGroup)) {
        allLabels.push(itemModifierGroup.name);
      }
    })
    if (allLabels.length > 0) {
      label += allLabels.join(', ')
      return label;
    }
    return;
  }

  getQuantity(id: string) {
    return this.selectedModifiers.find((ss) => ss.modifierId === id)?.quantity;
  }

  handleModifierQuantityChange(
    values: { quantity: number; previousQuantity: number },
    id: string
  ) {
    this.resetPDPValidations.emit();
    const { quantity, previousQuantity } = values;
    const modifierWithQuantityChangedIndex = this.selectedModifiers.findIndex(
      (modifier) => modifier.modifierId === id
    );

    this.selectedModifiers[modifierWithQuantityChangedIndex].quantity =
      quantity;
    this.selectedModifiers[modifierWithQuantityChangedIndex].touched = true;
    const modifierData = this.simpleModifierData || this.complexModifierData;
    this.quantitySelectionService.rebalanceSystemDefinedIfNeeded(
      modifierData?.metadata?.['item-type'] || 'Wings',
      this.selectedModifiers,
      this.aggregateQuantity,
      this.maxModifierQuantity,
      this.minModifierQuantity,
      this.getMaxAvailableToAssign(id),
      quantity,
      previousQuantity,
      id,
      this.modGroupElementType,
    );
    this.buildModifierQuantitySelectionState();
    this.onTouched();
     this.onChange(this.selectedModifiers);
     this.updateCheckedModElements();
  }

  updateCheckedModElements() {
    this.checkedModElementIds = [];
    for (let i = 0; i < this.selectedModifiers.length; i++) {
      this.checkedModElementIds.push(this.selectedModifiers[i].modifierId);
    }
  }

  private buildModifierQuantitySelectionState() {
    const quantitySelections =
      this.quantitySelectionService.updateFlavorQuantitySelections(
        this.selectedModifiers
      );
    this.emitModifierState(quantitySelections);
  }

  private emitModifierState(
    quantitySelections: FlavorQuantitySelectionsType
  ) {
    const sumOfAllSystemDefined = sum(
      Object.values(quantitySelections.systemDefined)
    );
    const sumOfAllUserDefined = sum(
      Object.values(quantitySelections.userDefined)
    );
    this.modifierQuantitySelections.emit({
      systemDefined: sumOfAllSystemDefined,
      userDefined: sumOfAllUserDefined
    });
  }

  getMaxAvailableToAssign(id: string): number {
    const current_modifier_quantity = this.getQuantity(id || '') || 0;
    const quantitySelections =
      this.quantitySelectionService.updateFlavorQuantitySelections(
        this.selectedModifiers
      );
    const sumOfAllSystemDefined = sum(
      Object.values(quantitySelections.systemDefined)
    );
    const sumOfAllUserDefined = sum(
      Object.values(quantitySelections.userDefined)
    );
    const any_unassigned_count =
      this.aggregateQuantity - (sumOfAllSystemDefined + sumOfAllUserDefined);
    const max_available_to_assign =
      current_modifier_quantity +
      any_unassigned_count +
      (sumOfAllSystemDefined -
        this.minModifierQuantity *
        Object.values(quantitySelections.systemDefined).length);
    return max_available_to_assign;
  }

  private isModifierElementEligibleToBeChecked(id: string): boolean {
    if (this.maxSelectionsAllowed > 1) {
      const currentSelectedModifierQuantity = this.selectedModifiers.reduce(
          (a, b) => a + b.quantity,
          0
      );
      if (
          this.quantitySelectionService.getSystemDefinedModifiers(
              this.selectedModifiers
          ).length === 0
      ) {
        if (currentSelectedModifierQuantity === this.aggregateQuantity)
          return false;
        if (
            currentSelectedModifierQuantity <= this.aggregateQuantity &&
            this.getMaxAvailableToAssign(id) < this.minModifierQuantity
        )
          return false;
      } else {
        if (this.getMaxAvailableToAssign(id) < this.minModifierQuantity)
          return false;
      }
    }
    return true;
  }

}
