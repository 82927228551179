import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import {
  AbstractControl,
  UntypedFormBuilder,
  UntypedFormGroup,
  ValidationErrors,
  Validators,
} from '@angular/forms';
import { Store } from '@ngrx/store';
import { filter, Subscription } from 'rxjs';

import { RegexPatterns } from '../../../../ecomm/constants/regex-patterns';
import { Customer } from '../../../../ecomm/types/customer.types';
import {
  emailValidator,
  formatFromPhone,
  formatToPhone,
  phoneValidator,
} from '../../../common/form-validators';
import { ConfirmedValidator } from '../../../common/form-validators/confirm.validator';
import {
  CustomerFeature,
  CustomerFeatureState,
} from '../../../../ecomm/store/features/customer';
import { ValidatePasswordPipe } from '../../../common';
import { PasswordRequirements } from '../../../../ecomm/types/password-requirements.types';
import { EmailDomainValidation } from '../../../../ecomm/types/email-domain-validation.types';
import { levenshteinDistance } from '../../../../ecomm/utils/levenshtein';

export type GuestSignupFormData = {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  password: string;
  confirmPassword: string;
  sendOffers: boolean;
};

export type GuestSignupFormDataWithErrors = {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  password: string;
  confirmPassword: string;
  sendOffers: boolean;
  errors: Record<string, ValidationErrors | null>;
};

@Component({
  selector: 'wri-guest-signup-form',
  styleUrls: ['./guest-signup-form.component.scss'],
  templateUrl: './guest-signup-form.component.html'
})
export class GuestSignupFormComponent implements OnInit, OnDestroy, OnChanges {
  @Input()
  initialValues: Partial<GuestSignupFormData> = {};
  @Input()
  isFormValid = true;

  @Input()
  canShowErrors = false;

  @Input()
  userDetails: Customer | null = null;

  @Input()
  passwordRequirements = [];

  @Input()
  emailDomainValidations = null

  @Output()
  valuesChanged = new EventEmitter<GuestSignupFormDataWithErrors>();

  @Output()
  emitLoginClick = new EventEmitter();

  /**
   * @ignore
   */
  signupForm: UntypedFormGroup = new UntypedFormGroup({});
  /**
   * @ignore
   */
  private subscription = new Subscription();
  isUserLoggedIn = false;

  public hidePassword = true;
  public hideConfirmPassword = true;

  public hasEmail: boolean | null = null;
  public initialEntry: boolean | null = null;

  /**
   * @ignore
   */
  constructor(
    private store: Store,
    private fb: UntypedFormBuilder
  ) { }

  ngOnChanges(changes: SimpleChanges): void {
    const { userDetails, passwordRequirements, emailDomainValidations } = changes;
    if (userDetails) {
      this.initForm(userDetails.currentValue || null);
    }

    if (!passwordRequirements?.isFirstChange()) {
      this.updatePasswordValidators();
    }

    if (!emailDomainValidations?.isFirstChange()) {
      this.updateEmailValidators();
    }
  }

  /**
   * @ignore
   */
  async ngOnInit(): Promise<void> {
    const customerState$ = this.store
      .select(CustomerFeature.selectCustomerState)
      .pipe(filter<CustomerFeatureState>(Boolean));

    customerState$.subscribe((customerState: CustomerFeatureState) => {
      this.hasEmail = customerState.customer?.email ? true : false;
    });

    this.signupForm = this.createForm();

    this.handleFormUpdate(this.signupForm.value);
    this.subscription.add(
      this.signupForm.valueChanges.subscribe((values) => {
        return this.handleFormUpdate(values);
      })
    );
  }

  private updatePasswordValidators(): void {
    const passwordControl = this.signupForm.get('password');
    if (passwordControl) {
      passwordControl.setValidators([
        this.checkPasswordValidation(this.passwordRequirements)
      ]);
      passwordControl.updateValueAndValidity();
    }
  }

  checkPasswordValidation(passwordRequirements: PasswordRequirements[]) {
    return (control: AbstractControl): ValidationErrors | null => {
      let passedCases = 0;
      const password = control.value;

      if (!password) {
        return null;
      }

      for (const req of passwordRequirements) {
        const passed = new ValidatePasswordPipe().transform(req, password);

        if (passed) {
          passedCases += 1;
        }
      }

      return passedCases === passwordRequirements.length
        ? null
        : { notMetReq: true };
    };
  }

  private updateEmailValidators() {
    const emailControl = this.signupForm.get('email');
    if (emailControl) {
      const combinedValidators = Validators.compose([
        Validators.required, // Initial validator
        emailValidator(),    // Initial email validator
        this.checkEmailValidation(this.emailDomainValidations), // Custom validator
      ]);
      emailControl.setValidators(combinedValidators)
      emailControl.updateValueAndValidity();
    }
  }

  checkEmailValidation(emailDomainValidations: EmailDomainValidation) {
    if (!emailDomainValidations) return  null;
    const { distanceThreshold, validDomains } = emailDomainValidations;
    return (control: AbstractControl): ValidationErrors | null => {
      const userEnteredDomain = control.value.split('@')[1];
      if (!userEnteredDomain) return null;

      // checking if there's an exact match in the validDomains array
      if (validDomains.includes(userEnteredDomain)) {
        return null;
      }

      for (const [domain] of Object.entries(validDomains)) {
        const distance = levenshteinDistance(userEnteredDomain, validDomains[domain]);
        if (distance === 0) {
          return null;
        }
        if (distance <= distanceThreshold) {
          return {
            warning: `Did you mean @${validDomains[domain]}?`
          }
        }
      }
      return null
    }
  }

  handleEmailBlur() {
    const emailControl = this.signupForm.get('email');
    if (emailControl) {
      emailControl.markAsTouched();
      emailControl.updateValueAndValidity({ onlySelf: true });
      this.initialEntry = true;
    }
  }

  public logIn($event: MouseEvent) {
    $event.preventDefault();
    this.emitLoginClick.emit();
  }

  /**
   * @ignore
   */
  ngOnDestroy(): void {
    if (!this.subscription.closed) {
      this.subscription.unsubscribe();
    }
  }

  /**
   * @ignore
   */
  private createForm(): UntypedFormGroup {
    return this.fb.group(
      {
        firstName: [
          this.initialValues.firstName ?? '',
          {
            validators: [
              Validators.required,
              Validators.pattern(RegexPatterns.firstName)
            ]
          }
        ],
        lastName: [
          this.initialValues.lastName ?? '',
          {
            validators: [
              Validators.required,
              Validators.pattern(RegexPatterns.lastName)
            ]
          }
        ],
        email: [
          this.initialValues.email ?? '',
          {
            validators: [Validators.required, emailValidator()]
          }
        ],
        phone: [
          this.initialValues.phone ?? '',
          {
            validators: [Validators.required, phoneValidator()]
          }
        ],
        password: [
          this.initialValues.password ?? '',
          {
            validators: [
              this.checkPasswordValidation(this.passwordRequirements)
            ]
          }
        ],
        confirmPassword: [this.initialValues.confirmPassword ?? ''],
        sendOffers: [this.initialValues.sendOffers ?? true]
      },
      {
        validator: ConfirmedValidator('password', 'confirmPassword')
      }
    );
  }

  private initForm(userDetails: Customer | null) {
    const firstName =
      this.initialValues.firstName ?? userDetails?.firstName ?? '';
    const lastName = this.initialValues.lastName ?? userDetails?.lastName ?? '';
    const email = this.initialValues.email ?? userDetails?.email ?? '';
    const phone = this.initialValues.phone ?? userDetails?.primaryPhone ?? '';

    this.signupForm.patchValue({ firstName, lastName, email, phone });
    this.isUserLoggedIn = userDetails?.email !== undefined;
  }

  /**
   * @ignore
   */
  public getPhoneFormatter() {
    return formatToPhone;
  }

  /**
   * @ignore
   */
  private handleFormUpdate(values: GuestSignupFormData) {
    this.valuesChanged.emit({
      ...values,
      phone: formatFromPhone(values.phone),
      errors: this.getAllValidationErrors()
    });
  }

  /**
   * @ignore
   */
  private getAllValidationErrors(): Record<string, ValidationErrors | null> {
    return Object.entries(this.signupForm.controls).reduce(
      (errors, [controlName, control]) => ({
        ...errors,
        ...(control.errors ? { [controlName]: control.errors } : {})
      }),
      {}
    );
  }

  handlePasswordIconClick() {
    this.hidePassword = !this.hidePassword;
  }

  handleConfirmPasswordIconClick() {
    this.hideConfirmPassword = !this.hideConfirmPassword;
  }
}
