<ng-container
  *ngIf="defaultTemplate then leftCheckboxTemplate; else rightCheckboxTemplate"
></ng-container>
<ng-template #leftCheckboxTemplate>
  <div class="checkbox-control-container">
    <input
      [id]="id"
      [attr.data-testid]="id | prepend : 'input-for-'"
      class="wri-custom-checkbox-control"
      tabindex="0"
      [value]="value"
      [checked]="value"
      [disabled]="disabled"
      [class.can-show-errors]="canShowErrors"
      (change)="writeValue($event.target.checked)"
      (blur)="onTouched()"
      (focus)="onTouched()"
      type="checkbox"
      [attr.aria-label]="label | prepend : 'chk-input-'"
    />
    <label
      [ngClass]="{
      'payment-option-label': customClass === 'paymentLabel'
    }"
      class="wri-custom-control custom-checkbox"
      [attr.for]="id"
      [attr.data-testid]="id | prepend : 'label-for-'"
    >
      {{ label }}
    </label>
  </div>
</ng-template>


<ng-template #rightCheckboxTemplate>
  <div class="checkbox-control-container checkbox-placement-right">
    <label
      [ngClass]="{
      'payment-option-label': customClass === 'paymentLabel'
    }"
      class="wri-custom-control custom-checkbox"
      [attr.for]="id"
      [attr.data-testid]="id | prepend : 'label-for-'"
    >
      {{ label }}

      <span data-testid="extra-label" *ngIf="extraLabelTemplate" [ngClass]="extraLabelClass?? 'extra-label-class'"> {{ extraLabelTemplate }} </span>

    </label>
    <input
      [id]="id"
      [attr.data-testid]="id | prepend : 'input-for-'"
      class="wri-custom-checkbox-control"
      tabindex="0"
      [value]="value"
      [checked]="value"
      [disabled]="disabled"
      [class.can-show-errors]="canShowErrors"
      (change)="writeValue($event.target.checked)"
      (blur)="onTouched()"
      (focus)="onTouched()"
      type="checkbox"
      [attr.aria-label]="label | prepend : 'chk-input-'"
    />
  </div>
</ng-template>

