<ng-container *ngIf="giftCardForm !== null">
  <div class="giftcard-payment-method">
    <!-- Display added vaulted Gift cards -->
    <ng-container *ngIf="vaultedGiftCardView">
      <div *ngFor="let addedCard of addedGiftCardsVaulted">
        <div
          class="added-giftcard"
          data-testid="added-giftcard-wrapper"
          [ngClass]="{'gift-card-payment-unavailable':!isVaultedGiftCardPaymentSupported || !vaultedGiftCardPay}"
        >
          <div class="vaulted-check-box-wrapper">
            <div class="checkbox-redeemed-wrapper">
              <div class="flex card-icon-wrapper">
                <wri-icon icon="wri-gift-card"></wri-icon>
              </div>
              <div class="check-box-balance-wrapper">
                <wri-checkbox
                  [defaultTemplate]="false"
                  [id]="addedCard.id | prepend : 'added-gift-card-'"
                  [attr.data-testid]="addedCard.id | prepend : 'added-gift-card-'"
                  class="span-full-width-lg"
                  label="Wingstop gift card"
                  [extraLabelTemplate]="'ending in '+ addedCard?.alias + ' (Balance: ' +( addedCard.balance | currency : 'USD' : 'symbol') + ')'"
                  [value]="addedGiftCardForm.controls[addedCard.id]?.value"
                  (change)="
                    toggleAddedCardStatus(addedCard.id, $event.target.checked)
                  "
                  tabindex="0"
                  customClass="paymentLabel"
                  [disabled]="!isVaultedGiftCardPaymentSupported || !vaultedGiftCardPay"
                  [ngClass]="{
                    'gift-card-unavailable-label': !isVaultedGiftCardPaymentSupported || !vaultedGiftCardPay
                  }"
                ></wri-checkbox>
                <div
                  *ngIf="addedGiftCardForm.value[addedCard.id]"
                  class="giftcard-payment-method-redeemed-amount"
                >
                  Amount to be redeemed: {{ addedCard.requestedAmount | currency }}
                </div>
                <div class="gift-card-unavailable" *ngIf="!isVaultedGiftCardPaymentSupported || !vaultedGiftCardPay"  data-testid="vaulted-gift-card-disabled">
                  Gift card payment is currently unavailable at this location
                </div>
                <div
                  *ngIf="addedCard.type === 'anonymous'"
                  class="giftcard-payment-method-remove-vaulted-card-text"
                  (click)="remove(addedCard.type, addedCard.id)"
                  [attr.data-testid]="
                addedCard.id | prepend : 'remove-added-gift-card-for-'
              "
                >
                  Remove card
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </ng-container>

    <!-- Display added anonymous Gift cards -->
    <div *ngFor="let addedCard of addedGiftCardsAnonymous">
      <div
        class="added-giftcard"
        data-testid="added-giftcard-wrapper"
      >
        <div class="vaulted-check-box-wrapper">
          <div class="checkbox-redeemed-wrapper">
            <div class="check-box-balance-wrapper">
              <wri-checkbox
                [defaultTemplate]="false"
                [id]="addedCard.id | prepend : 'added-gift-card-'"
                [attr.data-testid]="addedCard.id | prepend : 'added-gift-card-'"
                class="span-full-width-lg"
                label="Wingstop gift card"
                [value]="addedGiftCardForm.controls[addedCard.id]?.value"
                (change)="
                  toggleAddedCardStatus(addedCard.id, $event.target.checked)
                "
                tabindex="0"
                customClass="paymentLabel"
              ></wri-checkbox>
              <div
                class="giftcard-payment-method-vaulted-balance-amount"
                [attr.data-testid]="addedCard.id | prepend : 'vaulted-balance-amount-'"
              >
                ending in {{ addedCard.alias + ' ' }} (Balance:
                {{ addedCard.balance | currency : 'USD' : 'symbol' }})
              </div>
              <div
                *ngIf="addedGiftCardForm.value[addedCard.id]"
                class="giftcard-payment-method-redeemed-amount"
              >
                Amount to be redeemed: {{ addedCard.requestedAmount | currency }}
              </div>
              <div
                *ngIf="addedCard.type === 'anonymous'"
                class="giftcard-payment-method-remove-vaulted-card-text"
                (click)="remove(addedCard.type, addedCard.id)"
                [attr.data-testid]="
                  addedCard.id | prepend : 'remove-added-gift-card-for-'
                "
              >
                Remove card
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Display add gift card form -->
    <div
      class="giftcard-payment-method-form payment-method-card"
      [ngClass]="{'gift-card-payment-unavailable':!isGiftCardPaymentSupported}"
    >
      <div class="giftcard-payment-method-form-name">

        <div class="flex card-icon-wrapper">
          <wri-icon icon="wri-gift-card"></wri-icon>
        </div>

        <wri-checkbox
          [defaultTemplate]="false"
          [id]="'gift-card-pay'"
          class="span-full-width-lg"
          label="Add a Wingstop gift card"
          [value]="value"
          (change)="writeValue($event.target.checked)"
          customClass="paymentLabel"
          [disabled]="!isGiftCardPaymentSupported"
          [ngClass]="{
            'gift-card-unavailable-label': !isGiftCardPaymentSupported
          }"
        ></wri-checkbox>
      </div>

      <div class="gift-card-unavailable" *ngIf="!isGiftCardPaymentSupported">
        Gift card payment is currently unavailable at this location
      </div>

      <div *ngIf="value && isLoading">
        <wri-section-loading-indicator> </wri-section-loading-indicator>
      </div>

      <form
        *ngIf="value && !isLoading"
        [formGroup]="giftCardForm"
        class="giftcard-payment-method-form-data"
        data-testid="gift-card-form"
      >
        <wri-text-input
          [id]="'card-number'"
          class="card-number"
          type="tel"
          data-testid="card-number"
          label="Card Number *"
          placeholder="Card Number"
          formControlName="cardNumber"
          maxlength="50"
          [formatter]="getGiftCardFormatter()"
        >
          <wri-form-error
            [control]="giftCardForm.controls['cardNumber']"
            errorKey="required"
            display="Card number is required"
          >
          </wri-form-error>
        </wri-text-input>
        <wri-text-input
          [id]="'security-code'"
          class="security-code"
          type="tel"
          label="PIN Code *"
          placeholder="XXXX"
          formControlName="securityCode"
          data-testid="security-code"
          maxlength="10"
          [formatter]="getGiftCardFormatter()"
        >
          <wri-form-error
            [control]="giftCardForm.controls['securityCode']"
            errorKey="required"
            display="PIN Code is required"
          ></wri-form-error>
        </wri-text-input>
        <div *ngIf="vaultedGiftCardVault" class="enable-vaulted-gift-card">
          <wri-checkbox
            *ngIf="userLoggedIn"
            [attr.data-testid]="'enable-vaulted-gift-card-checkbox'"
            class="span-full-width-lg"
            label="By saving your card information in your account, you agree
              that this card will be saved and may be used by you for future purchases.
              You can change this in your account settings at anytime."
            tabindex="0"
            formControlName="vault"
          ></wri-checkbox>
        </div>
      </form>

      <div
        *ngIf="value && !isLoading"
        class="giftcard-payment-method-form-actions"
      >
        <div class="giftcard-payment-method-form-action-group">
          <button
            class="giftcard-payment-method-form-action wri-btn wri-btn-primary add"
            (click)="add()"
            type="submit"
            [disabled]="isLoading"
            data-testid="add-gift-card"
          >
            Add
          </button>
        </div>
        <div class="giftcard-payment-method-recaptcha-container">
          <wri-google-recaptcha-branding />
        </div>
      </div>
    </div>
  </div>
</ng-container>
