/* eslint-disable @typescript-eslint/no-explicit-any */
import { Inject, Injectable } from '@angular/core';
import { from, map, of, switchMap, tap } from 'rxjs';
import { AsynchronousDispatcher } from '../../../ecomm/utils/asynchronus-dispatcher/asynchronous-dispatcher.service';
import { StoreSnapshotService } from '../../../ecomm/utils/store-snapshot/store-snapshot.service';

import { Router } from '@angular/router';
import { Order, OrderSubmitRequest } from '../../../ecomm/types/order.types';
import { OrderService } from '../../../ecomm/services/order/order.service';
import { asyncTap } from '../../../ecomm/utils/async-tap';
import { getCategoryFromItemId } from '../store-info/store-info.utilities';
import * as Workflow from '../../../ecomm/types/workflow';
import { noop } from 'lodash';
import { Cart } from '../../../ecomm/types/cart.types';
import { CartService } from '../../../ecomm/services/cart/cart.service';
import { ActiveOfferFeature } from '../../../ecomm/store/features/active-offer';
import { FeaturesState } from '../../../ecomm/store/types/features-state';
import { OrderFeature } from '../../../ecomm/store/features/order';
import { CartFeature } from '../../../ecomm/store/features/cart';
import { StoreInfoFeature } from '../../../ecomm/store/features/store-info';
import { HandoffMode } from '../../../ecomm/types/selected-handoff-mode.types';
import { AnalyticsService } from '../../../ecomm/providers/legacy-providers/analytics.service';
import { AuthService } from '../../../ecomm/utils/auth/auth.service';
import { NotificationService } from '../../../ecomm/utils/notification/notification.service';
import { FeesCalculationService } from '../../utils/fees-calculation/fees-calculation';
import { RegionalConfigurationFeature } from "../../store/features/regional-configuration";

export type BillingMethod = 'payInStore' | 'onlinePay';

export const BillingMethod: Record<BillingMethod, BillingMethod> = {
  onlinePay: 'onlinePay',
  payInStore: 'payInStore'
};

@Injectable({ providedIn: 'root' })
export class OrderWorkflowService {
  private saveState = Workflow.onAny<Order>(
    asyncTap((res) => {
      return this.asynchronusDispatcher.dispatch(
        OrderFeature.actions.setState({
          lastSessionOrder: res.data,
          error: res.error
        })
      );
    })
  );
  private saveCartState = Workflow.onAny<Cart>(
    asyncTap((res) => {
      return this.asynchronusDispatcher.dispatch(
        CartFeature.actions.setState({
          cart: res.data,
          error: res.error
        })
      );
    })
  );

  private reportErrors = Workflow.onError<any>(
    tap((res) => this.notificationService.showError(res.error))
  );

  private showCancelOrderSuccessToast = Workflow.onSuccess<boolean>(
    tap(() => {
      this.notificationService.showSuccess('Your order has been canceled.');
    })
  );

  public getPastOrder = Workflow.createWorkflow(
    undefined,
    (orderId: string) => this.orderService.getByOrderId(orderId),
    this.reportErrors
  );
  private resetCartState = Workflow.onSuccess<Order>(
    asyncTap(() =>
      this.asynchronusDispatcher.dispatch(CartFeature.actions.resetToDefault())
    )
  );

  private resetActiveOfferState = Workflow.onSuccess<Order>(
    asyncTap(() =>
      this.asynchronusDispatcher.dispatch(
        ActiveOfferFeature.actions.resetToDefault()
      )
    )
  );

  private navigateToConfirmation = Workflow.onSuccess<any>(
    tap(() => this.router.navigate(['/order/confirmation']))
  );
  private navigateToBagIfNotFinalized = Workflow.onSuccess<Cart>(
    tap((cart) => {
      if (cart.data.status !== 'finalized') {
        this.router.navigate(['/order/my-bag']);
      }
    })
  );

  constructor(
    @Inject(FeesCalculationService)
    public feesCalculationService: FeesCalculationService,
    private analyticsService: AnalyticsService,
    @Inject(AsynchronousDispatcher)
    private asynchronusDispatcher: AsynchronousDispatcher<FeaturesState>,
    @Inject(StoreSnapshotService)
    private storeSnapshotService: StoreSnapshotService<FeaturesState>,
    private notificationService: NotificationService,
    private router: Router,
    private orderService: OrderService,
    private cartService: CartService,
    private authService: AuthService
  ) {}

  public placeOrder = (
    placeOrderData: OrderSubmitRequest,
    onErrorCallback: Workflow.PostWorkflowFn<Order> = tap(noop)
  ) =>
    Workflow.createWorkflow(
      this.setOrderLoading,
      (placeOrderData: OrderSubmitRequest) =>
        from(this.storeSnapshotService.get()).pipe(
          map((storeSnapshot) => {
            const regionalConfigurationState = RegionalConfigurationFeature.selectRegionalConfigurationState(storeSnapshot);
            const regionalConfigurationOptions = regionalConfigurationState?.regionalConfigurationOptions;
            return { regionalConfigurationOptions, placeOrderData };
          }),
          switchMap(({ regionalConfigurationOptions, placeOrderData }) =>
            from(this.authService.getCurrentAuth()).pipe(
              tap(() => {
                this.analyticsService.logGaEvent({
                  event: 'purchase_attempt',
                  purchase_attempt_timestamp: new Date().toISOString(),
                  cart_id: placeOrderData.request.cartId,
                  transaction_id: placeOrderData.request.transactionId
                });
              }),
              map((auth) => ({
                ...placeOrderData,
                request: {
                  ...placeOrderData.request,
                  accessToken: auth.user?.access_token ?? undefined
                }
              })),
              switchMap((req) => this.orderService.submitOrder(req, regionalConfigurationOptions))
            )
          )
        ),
      this.reportErrors,
      this.saveState,
      this.resetCartState,
      this.resetActiveOfferState,
      this.logGAPurchaseEvent(placeOrderData),
      this.logGAPaymentSaveTapEvent(placeOrderData),
      this.logGAPaymentFailedEvents(placeOrderData),
      this.navigateToConfirmation,
      this.fetchCartOnFailure(placeOrderData.request.cartId),
      onErrorCallback
    )(placeOrderData);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  private setOrderLoading = <_ extends unknown[]>() => {
    return from(
      this.asynchronusDispatcher.dispatch(OrderFeature.actions.setIsLoading())
    );
  };

  public getOrder = (fromConfirmationPage?: boolean) =>
    Workflow.createWorkflow(
      this.setOrderLoading,
      () =>
        from(this.storeSnapshotService.get()).pipe(
          map(OrderFeature.selectLastSessionOrder),
          map((order) => order?.id ?? null),
          switchMap((id) =>
            id
              ? this.orderService.getByOrderId(id, fromConfirmationPage)
              : of({})
          )
        ),
      this.reportErrors,
      this.saveState
    )();

  public cancelOrder = Workflow.createWorkflow(
    this.setOrderLoading,
    (orderId: string) => this.orderService.cancelOrder(orderId),
    this.reportErrors,
    this.showCancelOrderSuccessToast
  );

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  private setCartLoading = <_ extends unknown[]>() => {
    return from(
      this.asynchronusDispatcher.dispatch(CartFeature.actions.setIsLoading())
    );
  };

  private logGAPurchaseEvent = (req: OrderSubmitRequest) =>
    Workflow.onSuccess<Order>(
      asyncTap(async (res) => {
        const storeSnapsot = await this.storeSnapshotService.get();
        const storeInfoState = StoreInfoFeature.selectStoreInfo(storeSnapsot);
        const isRegistered = (await this.authService.getCurrentAuth())
          .isAuthenticated;

        this.analyticsService.logGaEvent({
          event: 'purchase',
          ecommerce: {
            actionField: {
              id: res.data.id || '',
              coupon: res.data.cart.offer?.code,
              revenue: res.data.cart.total || 0,
              tax: res.data.cart.totalTax || 0,
              shipping: this.feesCalculationService.calculateDeliveryFees(
                res.data.cart.fees
              ),
              currencyCode: 'USD'
            },
            coupon: res.data.cart.offer?.code,
            currency: 'USD',
            favorite: false,
            items:
              res.data.cart.items.map((item) => ({
                item_category:
                  getCategoryFromItemId(
                    item.productId,
                    storeInfoState?.categories
                  ) || '',
                item_id: item.productId,
                item_name: item.productName,
                price: item.itemSubtotal,
                quantity: item.quantity
              })) || [],
            order_method:
              (res.data.cart.handoffMode as HandoffMode) || 'carryout',
            order_time: res.data.cart.asap ? 'asap' : 'later',
            order_total: res.data.total || 0,
            registered: isRegistered,
            shipping: this.feesCalculationService.calculateDeliveryFees(
              res.data.cart.fees
            ),
            store_name: storeInfoState?.storeDetails.name || '',
            tip_amount: res.data.cart.tip?.amount || 0,
            transaction_id: res.data.id || '',
            value: res.data.subtotal || 0,
            payment_provider: 'Fiserv',
            discount: res.data.cart.offer?.priceAdjustment || 0,
            tax: res.data.cart.totalTax || 0,
            fees:
              res.data.cart.fees.map((fee) => ({
                amount: fee.amount,
                description: fee.description
              })) || [],
            fees_total:
              res.data.cart.fees
                .map((fee) => fee.amount)
                .reduce((x, y) => x + y, 0) || 0,
            payment_type_1:
              req.request.billingMethod === 'payInStore'
                ? 'in_store'
                : req.request.payments
                ? this.getLogEventPaymentType(
                    req.request.payments[0].type ?? null
                  )
                : null,
            payment_type_2: req.request.payments
              ? req.request.payments.length > 1
                ? this.getLogEventPaymentType(
                    req.request.payments[1].type ?? null
                  )
                : null
              : null
          },
          digital_menu: false,
          promo_code: res.data.cart.offer?.code,
          reorder: false,
          roundup_enabled: !!res.data.cart.fees.find(
            (f) => f.category === 'donation'
          ),
          roundup_amount:
            res.data.cart.fees.find((f) => f.category === 'donation')?.amount ||
            0,
          supports_coke_freestyle:
            !!storeInfoState?.storeDetails?.locationAmenities?.find(
              (a) => a.name === 'cokeFreestyle'
            )
        });
      })
    );

  private logGAPaymentSaveTapEvent = (req: OrderSubmitRequest) =>
    Workflow.onSuccess<Order>(
      asyncTap(async () => {
        req?.request?.payments?.some(
          (p) => p.type === 'registeredCredit' && (p as any).shouldVaultCard
        )
          ? this.analyticsService.logGaEvent({
              event: 'payment_save_tap'
            })
          : null;
      })
    );

  private logGAPaymentFailedEvents = (req: OrderSubmitRequest) =>
    Workflow.onError<Order>(
      tap(() => {
        this.analyticsService.logGaEvent({
          event: 'purchase_failed',
          transaction_id: req.request.transactionId,
          payment_type_1:
            req.request.billingMethod === 'payInStore'
              ? 'in_store'
              : req.request.payments
              ? this.getLogEventPaymentType(
                  req.request.payments[0].type ?? null
                )
              : null,
          payment_type_2: req.request.payments
            ? req.request.payments.length > 1
              ? this.getLogEventPaymentType(
                  req.request.payments[1].type ?? null
                )
              : null
            : null
        });
      })
    );

  private getLogEventPaymentType(type: string) {
    switch (type) {
      case 'applePay':
        return 'apple_pay';
      case 'payPal':
        return 'paypal';
      case 'venmo':
        return 'venmo';
      case 'giftCard':
        return 'gift_card';
      case 'vaultedGiftCard':
        return 'vaulted_gift_card';
      case 'anonymousCredit':
        return 'anonymous_credit_card';
      case 'registeredCredit':
        return 'registered_credit_card';
      case 'registeredVaulted':
        return 'vaulted_credit_card';
      default:
        return type;
    }
  }

  private fetchCartOnFailure = (cartId: string) =>
    Workflow.onFailure<Order>(
      asyncTap(() =>
        Workflow.createWorkflow(
          this.setCartLoading,
          (cartId: string) => this.cartService.getCartById(cartId),
          this.reportErrors,
          this.saveCartState,
          this.navigateToBagIfNotFinalized
        )(cartId)
      )
    );
}
