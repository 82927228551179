import { AfterContentInit, Component, ViewChild } from '@angular/core';
import {
  faFacebookF,
  faInstagram,
  faSpotify,
  faTiktok,
  faXTwitter,
} from '@fortawesome/free-brands-svg-icons';
import { GlobalService } from '@wingstop/services/global.services';
import { AppStateSelectors } from '@wingstop/store/app/app-state.selectors';
import { BehaviorSubject, Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ExternalLinkConfirmationModalComponent } from '../../../../lib/ngfe-app/src/lib/ui';
import { AnalyticsService } from '../../../../lib/ngfe-app/src/lib/ecomm/providers/legacy-providers/analytics.service';
import { State } from "@ngrx/store";
import { IAppStore } from "@wingstop/store/app-store";

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements AfterContentInit{
  icons = {
    faFacebookF,
    faInstagram,
    faXTwitter,
    faSpotify,
    faTiktok,
  };

  public language = 'English';
  public copyrightYear: string;
  public digitalMenu = false;
  public kiosk = false;
  public shouldShow$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  @ViewChild(ExternalLinkConfirmationModalComponent)
  externalLinkConfirmationModalComponent!: ExternalLinkConfirmationModalComponent;
  public OTUniversalEnabled = false;
  private subscriptions: Subscription[] = [];
  private ngUnsubscribe: Subject<boolean> = new Subject();

  constructor(
    private readonly appStateSelectors: AppStateSelectors,
    private readonly analyticsService: AnalyticsService,
    private readonly appStore: State<IAppStore>
  ) {
    const params = new URL(window.location.href).searchParams;
    this.kiosk = !!params.get('kiosk');
    this.copyrightYear = GlobalService.getNow().format('YYYY');

    this.subscriptions.push(
      this.appStateSelectors.isDigitalMenu
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((isDigitalMenu: boolean) => {
          this.digitalMenu = isDigitalMenu;
          this.shouldShow$.next(!this.kiosk && !this.digitalMenu);
        })
    );
  }

  ngAfterContentInit() {
    this.appStore.subscribe((value) => {
      if (
        value?.regionalConfiguration?.regionalConfigurationOptions &&
        value?.regionalConfiguration?.regionalConfigurationOptions[
          'feature_enable_onetrust_universal_consent'
          ]
      ) {
        this.OTUniversalEnabled =
          value?.regionalConfiguration?.regionalConfigurationOptions[
            'feature_enable_onetrust_universal_consent'
            ].value !== 'false';
      }
    });
  }

  public externalSite(event: MouseEvent, whereTo: string = null) {
    event.preventDefault();
    this.externalLinkConfirmationModalComponent.openModal(whereTo);
  }

  public openSocialLinks(event: MouseEvent, whereTo: string = null) {
    event.preventDefault();
    window.open(whereTo);
  }

  onAppDownloadClick(app: string) {
    this.analyticsService.logGaEvent({
      event: 'download_app',
      page: window.location.pathname,
      app_store_type: app,
    });
  }

  handleCookieSettingsKeyDown(event: KeyboardEvent): void {
    if (event.key === 'Enter' || event.key === ' ') {
      this.openOneTrustPreferences();
      event.preventDefault();
    }
  }


  openOneTrustPreferences() {
    const event = new CustomEvent('wri-open-one-trust-preferences-clicked');
    (window as any).dispatchEvent(event);
  }
}
