<div>
  <div 
    class="date-time-wrapper"
    [ngClass]="{
      'error-border': errorText
    }"
  >
    <div class="sub-wrapper">
      <div class="input-date-wrapper">
        <label data-testid="date-label" class="date-time-label">Day</label>
        <div class="select-wrapper">
          <select
            [ngClass]="
              selectedDateDropdownIndex !== -1 ? 'active-date-time-select' : ''
            "
            class="custom-date-select"
            id="day-select"
            (change)="onDayChange($event)"
            data-testid="custom-date-select"
            [(ngModel)]="selectedDateDropdownIndex"
          >
            <option
              disabled="disabled"
              [value]="-1"
            >
              Select a day
            </option>
            <option
              data-testid="select-date-option"
              *ngFor="let dateTime of fulfillmentTimes?.days; index as i; trackBy: trackByFn"
              [value]="i"
            >
              {{ getFormattedDate(dateTime?.date) }}
            </option>
          </select>
        </div>
      </div>
      <div class="input-time-wrapper">
        <label data-testid="time-label" class="date-time-label">Time</label>
        <div class="select-wrapper">
          <select
            [ngClass]="
              selectedTimeDropdownIndex !== -1 ? 'active-date-time-select' : ''
            "
            class="custom-time-select"
            id="day-select"
            [disabled]="selectedDateDropdownIndex === -1"
            (change)="onTimeChange($event)"
            data-testid="custom-time-select"
            [(ngModel)]="selectedTimeDropdownIndex"
          >
            <option
              disabled="disabled"
              [value]="-1"
            >
              Select a time
            </option>
            <option
              data-testid="select-time-option"
              *ngFor="let time of selectedDropdownTimes; index as i"
              [value]="i"
             
            >
              {{ getFormattedTime(time) }}
            </option>
          </select>
        </div>
      </div>
    </div>
  </div>
  <span *ngIf="errorText" class="error-text"  data-testid="error-text">{{errorText}}</span>
</div>
