import { Injectable } from '@angular/core';

import { MenuItem, MenuModifier } from '../../../ecomm/types/store-info.types';
import { ModGroups } from '../types/modifiers';

@Injectable()
export class ModifierModalElementChangeService {
  public onChangeModalModifierElement(
    itemModGroups: ModGroups[], // this variable will be updated with selected modifiers
    checked: boolean,
    type: string,
    modifierGroupId: string,
    modifierData: MenuModifier | MenuItem,
    modifierGroupSortOrder: number
  ) {
    if (checked) {
      if (type === 'multiple') {
        if (itemModGroups.some((a) => a.modifierGroupId === modifierGroupId)) {
          const index = itemModGroups.findIndex(
            (a) => a.modifierGroupId === modifierGroupId
          );
          const modifierIndex = itemModGroups[index].modifiers.findIndex(
            (a) => a.modifierId === modifierData.id
          );
          if (modifierIndex === -1) {
            itemModGroups[index].modifiers?.push({
              modifierId: modifierData.id,
              quantity: 1,
              name: modifierData.name,
              price: modifierData.price
            });
          }
        } else {
          itemModGroups.push({
            modifierGroupId,
            modifierGroupSortOrder,
            modifiers: [
              {
                modifierId: modifierData.id,
                quantity: 1,
                name: modifierData.name,
                price: modifierData.price
              }
            ]
          });
        }
      } else if (type === 'single') {
        if (itemModGroups.some((a) => a.modifierGroupId === modifierGroupId)) {
          const index = itemModGroups.findIndex(
            (a) => a.modifierGroupId === modifierGroupId
          );
          itemModGroups[index].modifiers = [
            {
              modifierId: modifierData.id,
              quantity: 1,
              name: modifierData.name,
              price: modifierData.price
            }
          ];
        } else {
          itemModGroups.push({
            modifierGroupId,
            modifierGroupSortOrder,
            modifiers: [
              {
                modifierId: modifierData.id,
                quantity: 1,
                name: modifierData.name,
                price: modifierData.price
              }
            ]
          });
        }
      }
    } else {
      const index = itemModGroups.findIndex(
        (a) => a.modifierGroupId === modifierGroupId
      );
      itemModGroups[index].modifiers = itemModGroups[index].modifiers?.filter(
        (mod) => mod.modifierId !== (modifierData ? modifierData.id : '')
      );
      if (itemModGroups[index].modifiers?.length === 0) {
        itemModGroups.splice(index, 1);
      }
    }
  }
}
