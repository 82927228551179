import { Pipe, PipeTransform } from '@angular/core';
import { ModGroups } from '../types';

@Pipe({
	name: 'displayAdditionalPrice'
})
export class DisplayAdditionalPricePipe implements PipeTransform {
	transform(basePrice: number, modifierGroups: ModGroups[]): number {
		if (!modifierGroups || !Array.isArray(modifierGroups)) {
			return basePrice;
		}

		const modifiersTotal = modifierGroups.reduce((total, group) => {
			if (group.modifiers && Array.isArray(group.modifiers)) {
				const groupTotal = group.modifiers.reduce((modTotal, modifier) => {
					return modTotal + (modifier.price || 0);
				}, 0);
				return total + groupTotal;
			}
			return total;
		}, 0);

		return basePrice + modifiersTotal;
	}
}