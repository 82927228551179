import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ECOMM_API_CONFIG, EcommAPIConfig } from '../../config/ecomm-config.provider';

import { handleAPIResponse } from '../../utils/handle-api-response';
import { MaybeResponse } from '../../types/maybe-response';
import { FeatureFlagService } from '../../utils/feature-flag/feature-flag.service';
import { JWTDto, JWTResponse } from "../../types/jwt.types";

@Injectable({providedIn: 'root'})
export class JWTRepository {
  constructor(
    private readonly http: HttpClient,
    @Inject(ECOMM_API_CONFIG) private readonly config: EcommAPIConfig,
    private readonly featureFlagService: FeatureFlagService
  ) {
  }


  public getJWTToken(
    email: string
  ): Observable<MaybeResponse<JWTDto>> {
    const url = `${this.config.baseUrl}/jwt-worker`;
    return this.http
      .post<JWTResponse>(url, {email})
      .pipe(
        handleAPIResponse(
          JWTResponse,
          [],
          this.featureFlagService.featureFlags.enableDevLogs
        )
      );
  }
}


