/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import * as MaybeResponse from '../../types/maybe-response';
import { JWTRepository } from "../../repositories/jwt/jwt.repository";
import { JWT } from "../../types/jwt.types";
import { fromDto } from "../../utils/from-dto";

@Injectable({providedIn: 'root'})
export class JWTService {
  constructor(
    private readonly jwtRepository: JWTRepository
  ) {
  }

  public getJWTToken(email: string): Observable<MaybeResponse.MaybeResponse<JWT>> {
    return this.jwtRepository
      .getJWTToken(email)
      .pipe(
        map(MaybeResponse.mapData(fromDto))
      );
  }
}
