import {
  Component,
  EventEmitter,
  forwardRef,
  HostBinding,
  Inject,
  Input,
  OnChanges,
  OnInit,
  Output
} from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

import {
  APPLE_PAY_PROVIDER,
  ApplePayProviderType
} from '../../../../../ecomm/providers/apple-pay/apple-pay.provider';
import { Cart } from '../../../../../ecomm/types/cart.types';
import { FeesCalculationService } from '../../../../../ecomm/utils/fees-calculation/fees-calculation';
import { NotificationService } from '../../../../../ecomm/utils/notification/notification.service';
import { PaymentWorkflowService } from '../../../../../ecomm/workflows/payment/payment-workflow.service';
import { CheckboxComponent } from '../../../../common';
import {
  ConditionalPaymentMethodLike,
  ExpressPaymentMethodLike,
  PaymentInfo,
  PaymentMethodLike,
  PaymentType
} from '../payment-method/payment-method.types';
import { FeatureFlagService } from '../../../../../ecomm/utils/feature-flag/feature-flag.service';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'wri-apple-pay-payment-method',
  templateUrl: './apple-pay-payment-method.component.html',
  styleUrls: ['./apple-pay-payment-method.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => ApplePayPaymentMethodComponent)
    }
  ]
})
export class ApplePayPaymentMethodComponent
  extends CheckboxComponent
  implements
  ExpressPaymentMethodLike,
  ConditionalPaymentMethodLike,
  OnInit,
  OnChanges {
  @Input() cart: Cart | null = null;
  @Input() expressPayPrerequisitesMet = false;
  @Input() paymentsSelected: PaymentMethodLike[] = [];
  @Input() isPaymentMethodSupported = false;

  @Output() expressPay = new EventEmitter<PaymentInfo>();
  @Output() expressPayClicked = new EventEmitter<string>();
  @Output() paymentSupported = new EventEmitter<boolean>();

  @HostBinding('class.method-supported')
  methodSupported = false;
  public isApplePaySupported = new BehaviorSubject<boolean>(false);
  private readonly APPLE_PAY_ERROR_MSG =
    'There was an issue with Apple Pay. Please try again.';
  private readonly UPDATE_GUEST_INFO =
    'Please update the form before using Apple Pay.';

  constructor(
    @Inject(APPLE_PAY_PROVIDER)
    private _applePayProvider: ApplePayProviderType,
    private paymentWorkflowService: PaymentWorkflowService,
    private notificationService: NotificationService,
    private feesCalculationService: FeesCalculationService,
    private featureFlagService: FeatureFlagService
  ) {
    super();
  }

  async ngOnInit(): Promise<void> {
    this.isApplePaySupported.next(
      (await this._applePayProvider.isSupported()) &&
      this.isPaymentMethodSupported &&
      this.featureFlagService.featureFlags.enableApplePay
    );

    if (this.isApplePaySupported.getValue()) {
      this.methodSupported = true;
      this.paymentSupported.emit(true);
    } else {
      console.debug('Apple Pay is not supported');
    }
  }

  async ngOnChanges(): Promise<void> {
    if (this.isApplePaySupported.getValue()) {
      this.methodSupported = true;
      this.paymentSupported.emit(true);
    } else {
      console.debug('Apple Pay is not supported');
    }
  }

  onButtonClick() {
    this.expressPayClicked.emit(PaymentType.applePay);
    if (this.paymentsSelected.length === 0) {
      if (!this.expressPayPrerequisitesMet) {
        this.notificationService.showError(this.UPDATE_GUEST_INFO);
        this.scrollToSection('guest-signup-form');
      } else {
        this.makePayment();
      }
    }
  }

  public onWorkflowCancel(event?: unknown) {
    console.log(event);
    this.notificationService.showError('Apple Pay cancelled');
  }

  private makePayment() {
    const request = this.getApplePayRequest();
    const session = this._applePayProvider.get(request);
    if (!session) {
      this.notificationService.showError(this.APPLE_PAY_ERROR_MSG);
      return;
    }
    const context = { ...this, session };
    session.onvalidatemerchant = this.onValidateMerchant.bind(context);
    session.onpaymentauthorized = this.onPaymentAuthorized.bind(context);
    session.oncancel = this.onWorkflowCancel.bind(context);
    session.begin();
  }

  private async onValidateMerchant(
    this: ApplePayPaymentMethodComponent & {
      session: ReturnType<ApplePayProviderType['get']>;
    },
    event: ApplePayJS.ApplePayValidateMerchantEvent
  ) {
    const response =
      await this.paymentWorkflowService.validateMerchantForApplePay(
        event.validationURL
      );
    if (response) this.session?.completeMerchantValidation(response);
    else {
      this.notificationService.showError(this.APPLE_PAY_ERROR_MSG);
      this.session?.abort();
    }
  }

  private onPaymentAuthorized(
    this: ApplePayPaymentMethodComponent & {
      session: ReturnType<ApplePayProviderType['get']>;
    },
    event: ApplePayJS.ApplePayPaymentAuthorizedEvent
  ) {
    const result = {
      status: event.payment
        ? this._applePayProvider.STATUS_SUCCESS()
        : this._applePayProvider.STATUS_FAILURE()
    };
    this.session?.completePayment(result);
    if (result.status === this._applePayProvider.STATUS_SUCCESS()) {
      typeof this.writeValue === 'function' && this.writeValue(true);
      this.expressPay.emit({
        billingMethod: 'onlinePay',
        payments: [
          {
            type: 'applePay',
            requestedAmount: this.cart?.total,
            tokenId: event.payment.token.transactionIdentifier,
            publicKeyHash: event.payment.token.paymentData.header.publicKeyHash,
            ephemeralPublicKey:
              event.payment.token.paymentData.header.ephemeralPublicKey,
            transactionId: event.payment.token.paymentData.header.transactionId,
            version: event.payment.token.paymentData.version,
            data: event.payment.token.paymentData.data,
            signature: event.payment.token.paymentData.signature,
            billingPostalCode: event.payment.billingContact?.postalCode || null
          }
        ]
      });
    } else {
      this.notificationService.showError(this.APPLE_PAY_ERROR_MSG);
    }
  }

  // get request
  private getApplePayRequest(): ApplePayJS.ApplePayPaymentRequest {
    return {
      countryCode: 'US',
      currencyCode: 'USD',
      merchantCapabilities: [
        'supports3DS' as ApplePayJS.ApplePayMerchantCapability
      ] as ApplePayJS.ApplePayMerchantCapability[],
      requiredBillingContactFields: ['email', 'phone', 'name', 'postalAddress'],
      supportedNetworks: ['visa', 'masterCard', 'amex', 'discover'],
      total: {
        label: 'Wingstop',
        type: 'final',
        amount: `${this.cart?.total || 0}`
      },
      lineItems: this.getLineItems()
    };
  }

  private getLineItems(): ApplePayJS.ApplePayLineItem[] {
    const lineItems: ApplePayJS.ApplePayLineItem[] = [];
    // Product Line Items
    this.cart?.items.forEach((item) => {
      lineItems.push({
        label:
          item.productName + (item.quantity > 1 ? ' x' + item.quantity : ''),
        amount: `${item.unitPrice}`,
        type: 'final'
      });
    });

    // Subtotal
    lineItems.push({
      label: 'Subtotal',
      amount: `${this.cart?.subtotal || 0}`,
      type: 'final'
    });

    // Delivery fees
    if (this.feesCalculationService.isDelivery(this.cart?.handoffMode)) {
      lineItems.push({
        label: 'Delivery Fees',
        amount: `${this.feesCalculationService.calculateDeliveryFees(
          this.cart?.fees
        )}`,
        type: 'final'
      });
    }

    // Other tax & fees
    lineItems.push({
      label:
        this.cart?.fees && this.cart?.fees.length > 0 ? 'Tax & Fees' : 'Tax',
      amount: `${this.feesCalculationService.calculateFees(this.cart?.fees) +
        (this.cart?.totalTax || 0)
        }`,
      type: 'final'
    });

    // Discount
    if (this.cart?.offer) {
      lineItems.push({
        label: 'Discount',
        amount: `${-Math.abs(this.cart.offer?.priceAdjustment)}`, // Ensure it is a negative number
        type: 'final'
      });
    }

    //Tip
    lineItems.push({
      label: 'Tip',
      amount: `${this.cart?.tip?.amount || 0}`,
      type: 'final'
    });

    //Round up fee
    this.cart?.fees.filter((r) => {
      if (r.category === 'donation') {
        lineItems.push({
          label: r?.description || '',
          amount: `${r?.amount || 0}`,
          type: 'final'
        });
      }
    });

    return lineItems;
  }

  private scrollToSection(elementId: string, extraOffset = 0) {
    const element = document.getElementById(elementId);
    const headerOffset = 45 + extraOffset;
    const elementPosition = element?.getBoundingClientRect().top || 0;
    const offsetPosition = elementPosition + window.pageYOffset - headerOffset;
    window.scrollTo({
      behavior: 'smooth',
      top: offsetPosition
    });
  }
}
