import * as t from 'io-ts';

import { ecommApiResponse, Optional, optional, recordOf } from './common.types';
import { FulfillmentTimes } from './fulfillment-times.types';

/* #region MenuMetadata */
export const MenuMetadataDto = t.intersection(
  [
    t.type({}, 'MenuMetadataDtoRequired'),
    t.partial(
      {
        layout: t.string,
        'item-type': t.string,
        'rub-type': t.string,
        'group-name': t.string,
        'heat-scale': t.string,
        'display-name': t.string,
        'home-product-carousel': t.string,
        'menu-product-carousel': t.string,
        'cart-product-carousel': t.string
      },
      'MenuMetadataDtoOptional'
    ),
    recordOf(t.string)
  ],
  'MenuMetadataDto'
);

export type MenuMetadataDto = t.TypeOf<typeof MenuMetadataDto>;
export type MenuMetadata = MenuMetadataDto;
/* #endregion */

/* #region MenuImage */
export const MenuImageDto = t.intersection(
  [
    t.type(
      {
        type: t.string,
        uri: t.string
      },
      'MenuImageDtoRequired'
    ),
    t.partial({}, 'MenuImageDtoOptional')
  ],
  'MenuImageDto'
);

export type MenuImageDto = t.TypeOf<typeof MenuImageDto>;
export type MenuImage = MenuImageDto;
/* #endregion */

/* #region MenuAvailabilitySchedule */
export const MenuAvailabilityScheduleDto = t.intersection(
  [
    t.type(
      {
        startDay: t.string,
        endDay: t.string,
        startTime: t.string,
        endTime: t.string
      },
      'MenuAvailabilityScheduleDtoRequired'
    ),
    t.partial({}, 'MenuAvailabilityScheduleDtoOptional')
  ],
  'MenuAvailabilityScheduleDto'
);

export type MenuAvailabilityScheduleDto = t.TypeOf<
  typeof MenuAvailabilityScheduleDto
>;
export type MenuAvailabilitySchedule = MenuAvailabilityScheduleDto;
/* #endregion */

/* #region MenuUpsellItem */
export const MenuUpsellItemDto = t.intersection(
  [
    t.type(
      {
        id: t.string,
        name: t.string,
        slug: t.string,
        priceDifference: t.number,
        images: t.array(MenuImageDto)
      },
      'MenuUpsellItemDtoRequired'
    ),
    t.partial(
      {
        minCalories: optional(t.number),
        maxCalories: optional(t.number),
        alwaysAvailable: optional(t.boolean),
        availabilitySchedule: optional(t.array(MenuAvailabilityScheduleDto))
      },
      'MenuUpsellItemDtoOptional'
    )
  ],
  'MenuUpsellItemDto'
);

export type MenuUpsellItemDto = t.TypeOf<typeof MenuUpsellItemDto>;
export type MenuUpsellItem = Omit<MenuUpsellItemDto, 'images'> & {
  images: MenuImage[];
  outOfStock?: boolean;
  availableInSchedule?: boolean;
};
/* #endregion */

/* #region MenuUpsells */
export const MenuUpsellsDto = t.intersection(
  [
    t.type(
      {
        upsellItems: t.array(MenuUpsellItemDto)
      },
      'MenuUpsellsDtoRequired'
    ),
    t.partial(
      {
        message: optional(t.string)
      },
      'MenuUpsellsDtoOptional'
    )
  ],
  'MenuUpsellsDto'
);

export type MenuUpsellsDto = t.TypeOf<typeof MenuUpsellsDto>;
export type MenuUpsells = Omit<MenuUpsellsDto, 'upsellItems'> & {
  upsellItems: MenuUpsellItem[];
};
/* #endregion */

/* #region MenuPOSSystem */
export const MenuPOSSystemDto = t.intersection(
  [
    t.type(
      {
        id: t.string,
        name: t.string
      },
      'MenuPOSSystemDtoRequired'
    ),
    t.partial({}, 'MenuPOSSystemDtoOptional')
  ],
  'MenuPOSSystemDto'
);

export type MenuPOSSystemDto = t.TypeOf<typeof MenuPOSSystemDto>;
export type MenuPOSSystem = MenuPOSSystemDto;
/* #endregion */

/* #region MenuPOSId */
export const MenuPOSIdDto = t.intersection(
  [
    t.type(
      {
        POSItemId: t.string,
        POSAlternativeId: t.string,
        POSSystem: MenuPOSSystemDto
      },
      'MenuPOSIdDtoRequired'
    ),
    t.partial({}, 'MenuPOSIdDtoOptional')
  ],
  'MenuPOSIdDto'
);

export type MenuPOSIdDto = t.TypeOf<typeof MenuPOSIdDto>;
export type MenuPOSId = Omit<MenuPOSIdDto, 'POSSystem'> & {
  POSSystem: MenuPOSSystem;
};
/* #endregion */

/* #region MenuModifier */
export type MenuModifierDto = {
  isNew: boolean;
  isPopular: boolean;
  isLimitedTime: boolean;
  highSodium?: Optional<boolean>;
  minCalories?: Optional<number>;
  maxCalories?: Optional<number>;
  images: MenuImageDto[];
  metadata?: Optional<MenuMetadataDto>;
  id: string;
  name: string;
  description?: Optional<string>;
  shortDescription?: Optional<string>;
  isDefault: boolean;
  availabilitySchedule: MenuAvailabilityScheduleDto[];
  alwaysAvailable: boolean;
  price: number;
  quantity?: number
};
export type MenuModifier = Omit<
  MenuModifierDto,
  'images' | 'metadata' | 'availabilitySchedule'
> & {
  images: MenuImage[];
  metadata?: Optional<MenuMetadata>;
  availabilitySchedule: MenuAvailabilitySchedule[];
  availableInSchedule?: boolean;
  outOfStock?: boolean;
  quantity?: number;
};
/* #endregion */

/** #region MenuModifierGroupItem */
export type MenuModifierGroupItemDto = {
  sortOrder: number;
  item?: Optional<MenuItemDto>;
  modifier?: Optional<MenuModifierDto>;
};
export type MenuModifierGroupItem = Omit<
  MenuModifierGroupItemDto,
  'item' | 'modifier'
> & {
  item?: Optional<MenuItem>;
  modifier?: Optional<MenuModifier>;
  availableInSchedule?: boolean;
};
/* #endregion */

/** #region MenuModifierGroup */
export type MenuModifierGroupDto = {
  id: string;
  name: string;
  type: string;
  message: string;
  minSelectionsRequired: number;
  maxSelectionsAllowed: number;
  aggregateQuantity: number;
  minModifierQuantity: number;
  maxModifierQuantity: number;
  quantityIncrements: number;
  required: boolean;
  quantitiesEnabled: boolean;
  posIdNotAvailable: boolean;
  sortOrder: number;
  modifierGroupElements: Array<MenuModifierGroupItemDto>;
};
export type MenuModifierGroup = Omit<
  MenuModifierGroupDto,
  'modifierGroupElements'
> & {
  modifierGroupElements: MenuModifierGroupItem[];
  availableInSchedule?: boolean;
};
/* #endregion */

/* #region  MenuItem */
export type MenuItemDto = {
  isNew: boolean;
  isPopular: boolean;
  isLimitedTime: boolean;
  highSodium?: Optional<boolean>;
  minCalories?: Optional<number>;
  maxCalories?: Optional<number>;
  images: Array<MenuImageDto>;
  metadata?: Optional<MenuMetadataDto>;
  id: string;
  name: string;
  shortName?: Optional<string>;
  slug: string;
  defaultCategoryId: string;
  description?: Optional<string>;
  shortDescription?: Optional<string>;
  alwaysAvailable: boolean;
  sortOrder: number;
  upsells?: Optional<MenuUpsellsDto>;
  isDefault: boolean;
  availabilitySchedule: MenuAvailabilityScheduleDto[];
  price: number;
  modifierGroups?: Optional<Array<MenuModifierGroupDto>>;
  quantity?: Optional<number>;
};

export const MenuItemDto: t.Type<MenuItemDto> = t.recursion<MenuItemDto>(
  'MenuItemDto',
  (MenuItemDtoC) =>
    t.intersection(
      [
        t.type(
          {
            id: t.string,
            name: t.string,
            slug: t.string,
            defaultCategoryId: t.string,
            price: t.number,
            isNew: t.boolean,
            isPopular: t.boolean,
            isDefault: t.boolean,
            isLimitedTime: t.boolean,
            alwaysAvailable: t.boolean,
            sortOrder: t.number,
            images: t.array(MenuImageDto),
            availabilitySchedule: t.array(MenuAvailabilityScheduleDto)
          },
          'MenuItemDtoRequired'
        ),
        t.partial(
          {
            quantity: optional(t.number),
            description: optional(t.string),
            shortName: optional(t.string),
            shortDescription: optional(t.string),
            minCalories: optional(t.number),
            maxCalories: optional(t.number),
            highSodium: optional(t.boolean),
            upsells: optional(MenuUpsellsDto),
            metadata: optional(MenuMetadataDto),
            modifierGroups: optional(
              t.array(
                t.intersection(
                  [
                    t.type(
                      {
                        id: t.string,
                        name: t.string,
                        type: t.string,
                        message: t.string,
                        minSelectionsRequired: t.number,
                        maxSelectionsAllowed: t.number,
                        aggregateQuantity: t.number,
                        minModifierQuantity: t.number,
                        maxModifierQuantity: t.number,
                        quantityIncrements: t.number,
                        required: t.boolean,
                        quantitiesEnabled: t.boolean,
                        posIdNotAvailable: t.boolean,
                        sortOrder: t.number,
                        modifierGroupElements: t.array(
                          t.intersection(
                            [
                              t.type(
                                {
                                  sortOrder: t.number
                                },
                                'MenuModifierGroupItemDtoRequired'
                              ),
                              t.partial(
                                {
                                  item: optional(MenuItemDtoC),
                                  modifier: optional(
                                    t.intersection(
                                      [
                                        t.type(
                                          {
                                            id: t.string,
                                            name: t.string,
                                            isDefault: t.boolean,
                                            isNew: t.boolean,
                                            isPopular: t.boolean,
                                            isLimitedTime: t.boolean,
                                            images: t.array(MenuImageDto),
                                            alwaysAvailable: t.boolean,
                                            price: t.number,
                                            availabilitySchedule: t.array(
                                              MenuAvailabilityScheduleDto
                                            )
                                          },
                                          'MenuModifierDtoRequired'
                                        ),
                                        t.partial(
                                          {
                                            description: optional(t.string),
                                            shortDescription: optional(
                                              t.string
                                            ),
                                            highSodium: optional(t.boolean),
                                            minCalories: optional(t.number),
                                            maxCalories: optional(t.number),
                                            metadata: optional(MenuMetadataDto),
                                            quantity: optional(t.number),
                                          },
                                          'MenuModifierDtoOptional'
                                        )
                                      ],
                                      'MenuModifierDto'
                                    )
                                  )
                                },
                                'MenuModifierGroupItemDtoOptional'
                              )
                            ],
                            'MenuModifierGroupItemDto'
                          )
                        )
                      },
                      'MenuModifierGroupDtoRequired'
                    ),
                    t.partial({}, 'MenuModifierGroupDtoOptional')
                  ],
                  'MenuModifierGroupDto'
                )
              )
            )
          },
          'MenuItemDtoOptional'
        )
      ],
      'MenuItemDto'
    )
);

export type MenuItem = Omit<
  MenuItemDto,
  'images' | 'metadata' | 'upsells' | 'availabilitySchedule' | 'modifierGroups'
> & {
  images: Array<MenuImage>;
  metadata?: Optional<MenuMetadata>;
  upsells?: Optional<MenuUpsells>;
  availabilitySchedule: MenuAvailabilitySchedule[];
  modifierGroups?: Optional<Array<MenuModifierGroup>>;
  availableInSchedule?: boolean;
  outOfStock?: boolean;
  quantity?: Optional<number>
};

/* #endregion */

/* #region  MenuItemGroup */
const MenuItemGroupDto = t.intersection(
  [
    t.type(
      {
        id: t.string,
        name: t.string,
        slug: t.string,
        message: t.string,
        images: t.array(MenuImageDto),
        items: t.array(MenuItemDto)
      },
      'MenuItemGroupDtoRequired'
    ),
    t.partial(
      {
        description: optional(t.string),
        shortDescription: optional(t.string)
      },
      'MenuItemGroupDtoOptional'
    )
  ],
  'MenuItemGroupDto'
);

export type MenuItemGroupDto = t.TypeOf<typeof MenuItemGroupDto>;
export type MenuItemGroup = Omit<MenuItemGroupDto, 'images' | 'items'> & {
  images: MenuImage[];
  items: MenuItem[];
  availableInSchedule?: boolean;
};
/* #endregion */

/* #region  MenuProduct */
const MenuProductDto = t.intersection(
  [
    t.type(
      {
        id: t.string,
        slug: t.string,
        sortOrder: t.number
      },
      'MenuProductDtoRequired'
    ),
    t.partial(
      {
        item: optional(MenuItemDto),
        itemGroup: optional(MenuItemGroupDto)
      },
      'MenuProductDtoOptional'
    )
  ],
  'MenuProductDto'
);

export type MenuProductDto = t.TypeOf<typeof MenuProductDto>;
export type MenuProduct = Omit<MenuProductDto, 'item' | 'itemGroup'> & {
  item?: Optional<MenuItem>;
  itemGroup?: Optional<MenuItemGroup>;
};
/* #endregion */

/* #region  MenuCategory */
const MenuCategoryDto = t.intersection(
  [
    t.type(
      {
        id: t.string,
        name: t.string,
        slug: t.string,
        sortOrder: t.number,
        products: t.array(MenuProductDto)
      },
      'MenuCategoryRequired'
    ),
    t.partial(
      {
        metadata: optional(MenuMetadataDto)
      },
      'MenuCategoryOptional'
    )
  ],
  'MenuCategoryDto'
);

export type MenuCategoryDto = t.TypeOf<typeof MenuCategoryDto>;
export type MenuCategory = Omit<MenuCategoryDto, 'products' | 'metadata'> & {
  products: MenuProduct[];
  metadata?: Optional<MenuMetadata>;
  availableInSchedule?: boolean;
};
/* #endregion */

/* #region  StoreDetailsAmenity */
const StoreDetailsAmenityDto = t.intersection(
  [
    t.type(
      { name: t.string, description: t.string },
      'StoreDetailsAmenityDtoRequired'
    ),
    t.partial({}, 'StoreDetailsAmenityDtoOptional')
  ],
  'StoreDetailsAmenityDto'
);

export type StoreDetailsAmenityDto = t.TypeOf<typeof StoreDetailsAmenityDto>;
export type StoreDetailsAmenity = StoreDetailsAmenityDto;
/* #endregion */

/* #region  StoreDetailsHandoffMode */
const StoreDetailsHandoffModeDto = t.intersection(
  [
    t.type(
      {
        handoffMode: t.string,
        tippingAllowed: t.boolean
      },
      'StoreDetailsHandoffModeDtoRequired'
    ),
    t.partial(
      {
        markupRate: optional(t.number),
        minOrderAmount: optional(t.number),
        maxOrderAmount: optional(t.number),
        maxPayInStoreOrderAmount: optional(t.number),
        paymentTypes: optional(t.array(optional(t.string)))
      },
      'StoreDetailsHandoffModeDtoOptional'
    )
  ],
  'StoreDetailsHandoffModeDto'
);

export type StoreDetailsHandoffModeDto = t.TypeOf<
  typeof StoreDetailsHandoffModeDto
>;
export type StoreDetailsHandoffMode = StoreDetailsHandoffModeDto;
/* #endregion */

/* #region  StoreDetailsDisclaimer */
const StoreDetailsDisclaimerDto = t.intersection(
  [
    t.type(
      {
        id: t.string,
        name: t.string,
        message: t.string
      },
      'StoreDetailsDisclaimerDtoRequired'
    ),
    t.partial({}, 'StoreDetailsDisclaimerDtoOptional')
  ],
  'StoreDetailsDisclaimerDto'
);

export type StoreDetailsDisclaimerDto = t.TypeOf<
  typeof StoreDetailsDisclaimerDto
>;
export type StoreDetailsDisclaimer = StoreDetailsDisclaimerDto;
/* #endregion */

/* #region  StoreDetailsFeeType */
const StoreDetailsFeeType = t.union(
  [
    t.literal('notDetermined'),
    t.literal('fixedAmount'),
    t.literal('percentage'),
    t.literal('roundUp')
  ],
  'StoreDetailsFeeType'
);

export type StoreDetailsFeeType = t.TypeOf<typeof StoreDetailsFeeType>;
/* #endregion */

/* #region  StoreDetailsFee */
const StoreDetailsFeeDto = t.intersection(
  [
    t.type(
      {
        amount: t.number,
        taxable: t.boolean,
        name: t.string,
        internalName: t.string,
        description: t.string,
        feeType: StoreDetailsFeeType,
        categoryName: t.string
      },
      'StoreDetailsFeeDtoRequired'
    ),
    t.partial({}, 'StoreDetailsFeeDtoOptional')
  ],
  'StoreDetailsFeeDto'
);

export type StoreDetailsFeeDto = t.TypeOf<typeof StoreDetailsFeeDto>;
export type StoreDetailsFee = StoreDetailsFeeDto;
/* #endregion */

/* #region  StoreHours */
const StoreHoursDto = t.intersection(
  [
    t.type(
      {
        startDay: t.string,
        endDay: t.string,
        startTime: t.string,
        endTime: t.string
      },
      'StoreHoursDtoRequired'
    ),
    t.partial({}, 'StoreHoursDtoOptional')
  ],
  'StoreHours'
);

export type StoreHoursDto = t.TypeOf<typeof StoreHoursDto>;
export type StoreHours = StoreHoursDto;

/* #endregion */

/* #region StoreDetails */
const StoreDetailsDto = t.intersection(
  [
    t.type(
      {
        id: t.string,
        name: t.string,
        number: t.string,
        streetAddress1: t.string,
        city: t.string,
        state: t.string,
        postalCode: t.string,
        country: t.string,
        phoneNumber: t.string,
        lat: t.number,
        long: t.number,
        currencyType: t.string,
        timeZone: t.string,
        locationDisclaimers: t.array(StoreDetailsDisclaimerDto),
        handoffModes: t.array(StoreDetailsHandoffModeDto),
        slug: t.string
      },
      'StoreDetailsDtoRequired'
    ),
    t.partial(
      {
        svsMerchantNumber: optional(t.string),
        description: optional(t.string),
        streetAddress2: optional(t.string),
        county: optional(t.string),
        locationAmenities: optional(t.array(StoreDetailsAmenityDto)),
        message: optional(t.string),
        storeHours: optional(t.array(StoreHoursDto)),
        businessDay: optional(t.string),
        storeFees: optional(t.array(StoreDetailsFeeDto))
      },
      'StoreDetailsDtoOptional'
    )
  ],
  'StoreDetailsDto'
);

export type StoreDetailsDto = t.TypeOf<typeof StoreDetailsDto>;
export type StoreDetails = Omit<
  StoreDetailsDto,
  'storeFees' | 'locationAmenities' | 'locationDisclaimers' | 'handoffModes'
> & {
  storeFees: Array<StoreDetailsFee>;
  locationAmenities: Optional<Array<StoreDetailsAmenity>>;
  locationDisclaimers: Array<StoreDetailsDisclaimer>;
  handoffModes: Array<StoreDetailsHandoffMode>;
};
/* #endregion */

/* #region StoreInfo */
const StoreInfoDto = t.intersection(
  [
    t.type(
      {
        storeDetails: StoreDetailsDto,
        channelHandoffModeHours: t.any
      },
      'StoreInfoDtoRequired'
    ),
    t.partial(
      {
        categories: optional(t.array(MenuCategoryDto))
      },
      'StoreInfoDtoOptional'
    )
  ],
  'StoreInfoDto'
);

export type StoreInfoDto = t.TypeOf<typeof StoreInfoDto>;
export type StoreInfo = Omit<StoreInfoDto, 'categories' | 'storeDetails'> & {
  categories: MenuCategory[];
  storeDetails: StoreDetails;
  fulfillmentTimes?: FulfillmentTimes;
};
/* #endregion */

/* #region StoreInfoResponse */
export const StoreInfoResponse = ecommApiResponse(StoreInfoDto);

export type StoreInfoResponse = t.TypeOf<typeof StoreInfoResponse>;
/* #endregion */

/* #region MenuItemResponse */
export const MenuItemResponse = ecommApiResponse(MenuItemDto);

export type MenuItemResponse = t.TypeOf<typeof MenuItemResponse>;
/* #endregion */

/* #region OverrideDetails */
export const OverrideDetailsDto = t.intersection(
  [
    t.type(
      {},
      'OverrideDetailsDtoRequired'
    ),
    t.partial({
      description: optional(t.string),
      type: optional(t.string)}, 
      'OverrideDetailsDtoOptional')
  ],
  'OverrideDetailsDto'
);

export type OverrideDetailsDto = t.TypeOf<
  typeof OverrideDetailsDto
>;
export type OverrideDetails = OverrideDetailsDto;
/* #endregion */

/** #region LocationInfo */
export type LocationInfo = {
  streetAddress1?: Optional<string>;
  streetAddress2?: Optional<string>;
  description?: Optional<string>;
  city?: Optional<string>;
  state?: Optional<string>;
  postalCode?: Optional<string>;
  country?: Optional<string>;
  phoneNumber?: Optional<string>;
  lat?: Optional<number>;
  long?: Optional<number>;
  name?: Optional<string>;
  storeHours?: Optional<StoreHours[]>;
  businessDay?: Optional<string>;
  storeTimings?: Optional<StoreHours[]>;
  carryoutOverride?: Optional<OverrideDetails>;
  deliveryOverride?: Optional<OverrideDetails>;
};
/* #endregion */
