import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { clamp } from 'lodash';
import {
  BehaviorSubject,
  NEVER,
  Observable,
  Subscription,
  combineLatestWith,
  filter,
  map
} from 'rxjs';

import { AnalyticsService } from '../../../../ecomm/providers/legacy-providers/analytics.service';
import {
  MenuItem,
  MenuProduct,
  StoreInfo
} from '../../../../ecomm/types/store-info.types';
import { WingCalculatorService } from '../../services/wing-calculator/wing-calculator.service';
import { RegionalConfigurationFeature, RegionalConfigurationFeatureState } from '../../../../ecomm/store/features/regional-configuration';
import { Store } from '@ngrx/store';
import { PartialOutageModalComponent } from '../../../common';

type ProductWithCategorySlug = MenuProduct & { categorySlug: string };
type MenuItemWithSlugs = MenuItem & {
  categorySlug: string;
  productSlug: string;
  itemSlug?: string;
};

type WingCalculatorConfigCount = {
  hungerIndex: number;
  peopleCount: number[];
};

@Component({
  selector: 'wri-wing-calculator',
  styleUrls: ['./wing-calculator.component.scss'],
  template: `
    <ng-container *ngIf="isLoading; then loading; else loaded"></ng-container>
    <wri-partial-outage-modal></wri-partial-outage-modal>
    <ng-template #loading>
      <wri-page-loading-indicator
        data-testid="wri-loading-spinner"
      ></wri-page-loading-indicator>
    </ng-template>

    <ng-template #loaded>
      <wri-wing-calculator-controls
        (hungerLevel)="hungerLevel$.next($event)"
        (peopleCount)="peopleCount$.next($event)"
        (shouldShow)="shouldShow$.next($event)"
      ></wri-wing-calculator-controls>

      <ng-container *ngIf="isEmpty; then empty; else filled"></ng-container>

      <ng-template #empty>
        <p
          *ngIf="shouldShow$ | async"
          data-testid="empty-message"
          class="empty-message"
        >
          PLEASE CALL SO ONE OF OUR WING EXPERTS CAN HELP!
        </p>
      </ng-template>

      <ng-template #filled>
        <ul
          *ngIf="shouldShow$ | async"
          data-testid="item-list"
          class="item-list"
          [class.single-column]="items.length === 1"
          [class.two-column]="items.length === 2"
        >
          <li
            *ngFor="let item of items"
            [attr.data-testid]="item.id | prepend : 'item-'"
            class="item"
            (click)="handleCta(item)"
          >
            <div class="card-image-title-wrapper">
              <img
                class="card-img wri-card-img"
                srcset="{{(item.images.length > 0 ?item.images[0].uri : null) | imageResolution: 'srcset':'wing-calculator-products': '/assets/images/logo-green-product.png'}}"
                src="{{
                  (item.images.length > 0 ? item.images[0].uri : null)
                    | imageResolution
                      : 'src'
                      : 'wing-calculator-products'
                      : '/assets/images/logo-green-product.png'
                }}"
                onerror="this.onerror=null;this.src='/assets/images/logo-green-product.png';this.srcset='/assets/images/logo-green-product.png'"
                role="img"
                alt="{{ item?.description | prepend : 'image-for' }}"
              />
              <h3 class="item-button-title">{{ item.name }}</h3>
              <p class="item-button-description">{{ item.description }}</p>
            </div>
            <button class="item-button-cta wri-btn wri-btn-primary" (click)="handleCta(item)">
              order now
            </button>
          </li>
        </ul>
      </ng-template>
    </ng-template>
  `
})
export class WingCalculatorComponent implements OnInit, OnDestroy {
  public static readonly CONFIG_METADATA = 'wing-calculator-config';
  isLoading = false;
  isEmpty = true;
  items: MenuItem[] = [];
  hungerLevel$ = new BehaviorSubject<number>(0);
  peopleCount$ = new BehaviorSubject<number>(1);
  shouldShow$ = new BehaviorSubject<boolean>(false);
  menuItems$: Observable<MenuItemWithSlugs[]> = NEVER;
  private subscription = new Subscription();

  @ViewChild(PartialOutageModalComponent) partialOutageModalComponent:
    | PartialOutageModalComponent
    | undefined;

  constructor(
    private wingCalculatorService: WingCalculatorService,
    private analyticsService: AnalyticsService,
    private router: Router,
    private store: Store
  ) {}

  async ngOnInit(): Promise<void> {
    this.subscribeToRegionalConfigState();
    this.analyticsService.logGaEvent({
      event: 'wing_calculator_view'
    });

    this.isLoading = true;
    this.menuItems$ = this.wingCalculatorService.init().pipe(
      combineLatestWith(this.hungerLevel$, this.peopleCount$),
      map(([globalStoreInfo, hungerLevel, peopleCount]) => {
        const hungerUnbounded = clamp(hungerLevel, 0, 2) !== hungerLevel;
        const peopleUnbounded = clamp(peopleCount, 1, 20) !== peopleCount;
        return !(hungerUnbounded || peopleUnbounded)
          ? this.allFromStoreInfo(globalStoreInfo.data)
              .filter((i) => this.onlyWellDefined(i))
              .filter((i) =>
                this.isAcceptableForHungerLevelAndPeopleCount(
                  i,
                  hungerLevel,
                  peopleCount
                )
              )
          : [];
      })
    );

    this.subscription.add(
      this.menuItems$.subscribe((items) => {
        this.isLoading = false;
        this.isEmpty = items.length === 0;
        this.items = items;
        this.analyticsService.logGaEvent({
          event: 'run_wing_calculator'
        });
      })
    );
  }

  private subscribeToRegionalConfigState(): void {
    const regionalConfigState$ = this.store
      .select(RegionalConfigurationFeature.selectRegionalConfigurationState)
      .pipe(filter<RegionalConfigurationFeatureState>(Boolean));

    this.subscription.add(
      regionalConfigState$.subscribe((state) => {
        this.partialOutageModalComponent?.showModal(state);
      })
    );
  }

  ngOnDestroy(): void {
    if (this.subscription && !this.subscription.closed) {
      this.subscription.unsubscribe();
    }
  }

  public handleCta(item: MenuItemWithSlugs) {
    this.analyticsService.logGaEvent({
      event: 'wing_calculator_order',
      product_name: item.name
    });
    this.router.navigate(
      ['menu', item.categorySlug, item.productSlug, item.itemSlug].filter(
        Boolean
      )
    );
  }

  private allFromStoreInfo = (
    info: StoreInfo | undefined
  ): MenuItemWithSlugs[] => {
    return (
      info?.categories
        .flatMap((c) => c.products.map((p) => ({ ...p, categorySlug: c.slug })))
        .flatMap((p: ProductWithCategorySlug) =>
          p.item
            ? [{ ...p.item, productSlug: p.slug, categorySlug: p.categorySlug }]
            : p.itemGroup
            ? p.itemGroup.items?.map((i) => ({
                ...i,
                productSlug: p.slug,
                categorySlug: p.categorySlug,
                itemSlug: i.slug
              }))
            : []
        ) ?? []
    );
  };

  private onlyWellDefined = (item: MenuItemWithSlugs) =>
    this.getWingCalculatorConfigCounts(item) !== undefined;

  private getWingCalculatorConfigCounts = (
    item: MenuItem
  ): WingCalculatorConfigCount[] | undefined => {
    const rawConfig = item.metadata?.[WingCalculatorComponent.CONFIG_METADATA];
    if (!rawConfig) return undefined;

    try {
      const parsed = JSON.parse(rawConfig);
      if (!Array.isArray(parsed)) {
        return;
      }

      const everyConfigValid = parsed.every(
        (conf) =>
          typeof conf === 'object' &&
          Object.hasOwn(conf, 'hungerIndex') &&
          typeof conf['hungerIndex'] === 'number' &&
          Object.hasOwn(conf, 'peopleCount') &&
          Array.isArray(conf['peopleCount']) &&
          conf['peopleCount'].every((pc) => typeof pc === 'number')
      );
      if (!everyConfigValid) {
        return undefined;
      }

      return parsed;
    } catch {
      return undefined;
    }
  };

  private isAcceptableForHungerLevelAndPeopleCount = (
    item: MenuItemWithSlugs,
    myHungerLevel: number,
    myPeopleCount: number
  ) => {
    const config = this.getWingCalculatorConfigCounts(item);

    return config
      ? config.some(
          ({ hungerIndex, peopleCount }) =>
            hungerIndex === myHungerLevel && peopleCount.includes(myPeopleCount)
        )
      : false;
  };
}
