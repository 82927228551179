import { Injectable } from '@angular/core';
import { configureStore, IAppStore } from '@wingstop/store/app-store';
import { AppStateActions } from '@wingstop/store/app/app-state.actions';
import { AnalyticsService } from "@wingstop/services/analytics.service.service";
import { State } from "@ngrx/store";

@Injectable()
export class ReduxProvider {
  constructor(
    private appStateActions: AppStateActions,
    private analyticsService: AnalyticsService,
    private appStore: State<IAppStore>
  ) { }

  public load(): Promise<any> {
    return new Promise((resolve, reject) => {
      configureStore().then((store) => {
        const resolvePromise = () => {
          resolve(store);
        };

        this.appStore.subscribe((value) => {
          const { customer } = value?.customer || {};
          if (customer) {
            this.analyticsService.logGaEvent({ user_id: customer?.customerId });
          }
        });

        // Resync the basket if possible
        this.appStateActions.refreshAuthentication().then((a) => {
          this.appStateActions.refreshBasket().then(resolvePromise);
        });
      });
    });
  }
}
