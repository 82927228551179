import { Injectable } from '@angular/core';
import * as Workflow from '../../../ecomm/types/workflow';
import { JWTService } from "../../services/jwt/jwt.service";
import { tap } from "rxjs";
import { NotificationService } from "../../utils/notification/notification.service";

@Injectable({ providedIn: 'root' })
export class JWTWorkflowService {

  static readonly ERROR_MSG = "There was an error retrieving your privacy preferences. We've reverted to your last saved session preferences. Proceed to Cookie & Tracking Technologies Settings in the footer if you wish to make changes.";
    constructor(
        private readonly jwtService: JWTService,
        private readonly notificationService: NotificationService
    ) {
    }

    public getJWTToken = (email: string, showError = false, fromSignUp = false) =>
        Workflow.createWorkflow(
            undefined,
            () => this.jwtService.getJWTToken(email),
            this.reportErrors(showError, fromSignUp)
        )()

    private readonly reportErrors = <T>(showError = true, fromSignUp = false) =>
        Workflow.onError<T>(
          tap(() => {
            if (showError && !fromSignUp) {
              this.notificationService.showWarning(JWTWorkflowService.ERROR_MSG);
            }
          })
        );

}
