<div class="location-name" data-testid="location-name">
  <span> {{ locationData?.name }} </span>
  <span>
    <div>
        <ng-container
          *ngIf="customerState?.customer | favLocation : locationData?.id"
        >
          <a
            data-testid="unfav-icon"
            wriFocusOutline
            role="button"
            aria-label="Location favorited"
            (click)="unFavoriteLocation()"
          >
            <wri-icon class="heart-icon" icon="wri-heart-filled"></wri-icon>
          </a>
        </ng-container>

        <ng-container
          *ngIf="!(customerState?.customer | favLocation : locationData?.id)"
        >
          <a
            data-testid="fav-icon"
            role="button"
            wriFocusOutline
            aria-label="Location unfavorited"
            (click)="onFavoriteLocation()"
          >
            <wri-icon class="heart-icon" icon="wri-heart"></wri-icon>
          </a>
        </ng-container>
      </div>
  </span>
</div>
<div class="location-address" data-testid="location-address">
  {{ locationData?.streetAddress }}, {{ locationData?.locality }},
  {{ locationData?.region }} {{ locationData?.postalCode }}
</div>

<ng-container *ngIf="locationData?.newlyOpenedStatus">
  <div class="store-badge">
    <span class="store-badge-text" data-testid="new-location">
      New Location
    </span>
  </div>
</ng-container>

<ng-container *ngIf="locationData?.comingSoonStatus && locationData?.openDate">
  <div class="store-badge">
    <span class="store-badge-text">Coming Soon</span>
  </div>
  <div class="coming-soon-description">
    Prepare your taste buds for a new location!
  </div>
</ng-container>

<div class="location-grid-wpr" *ngIf="!(locationData?.comingSoonStatus && locationData?.openDate)">
  <div>
    <div
      *ngIf="locationData?.carryoutOverride && locationData?.carryoutOverride.description"
      class="override-hrs-error-text">
      {{locationData?.carryoutOverride.description}}
    </div>
    <wri-todays-hours
      [businessDay]="locationData?.businessDay"
      [hours]="carryoutOrBusinessHours"
      [override]="locationData?.carryoutOverride?.type"
    ></wri-todays-hours>
  </div>
  <wri-location-amenities
    [offersDelivery]="offersDelivery"
    [amenities]="locationData?.amenities"
  ></wri-location-amenities>
</div>
<div class="wri-card-btn-wrapper">
  <button
    (click)="carryoutStoreClick(locationData?.id)"
    class="wri-btn wri-btn-primary"
    aria-label="Carryout"
    data-testid="carryout-order-btn"
    *ngIf="!locationData?.comingSoonStatus && !(locationData?.carryoutOverride?.type === 'longTerm') && hasCarryout"
  >
    Carryout
  </button>

  <button
    (click)="showLocationInfo()"
    class="wri-btn wri-btn-secondary"
    aria-label="Location Info"
    data-testid="location-info-btn"
  >
    Location Info
  </button>
</div>

<wri-location-info-modal [locationData]="locationData" [carryoutOrBusinessHours]="carryoutOrBusinessHours">
</wri-location-info-modal>

<ng-template #commonModal let-close="close" let-dismiss="dismiss">
  <wri-common-modal [close]="close" [dismiss]="dismiss">
    <div class="wri-modal__wrapper">
      <span class="wri-modal__title"
      >Favorite Locations</span
      >
      <span class="wri-modal__description">
        Log in or create an account to save a favorite location for quicker ordering.
      </span>
      <div class="wri-modal__btn-wrapper">
        <button
          data-testid="login-for-fav-locations-modal-login"
          class="wri-btn wri-btn-primary"
          aria-label="Log in"
          (click)="login()"
        >
          LOG IN
        </button>
        <button
          data-testid="login-for-fav-locations-modal-cancel"
          class="wri-btn wri-btn-tertiary"
          aria-label="Cancel"
          (click)="closeModal()"
        >
          Cancel
        </button>
      </div>
    </div>
  </wri-common-modal>
</ng-template>

<ng-template #switchLocationConfirmationModal let-close="close" let-dismiss="dismiss">
  <wri-common-modal [close]="close" [dismiss]="dismiss">
    <div class="wri-modal__wrapper">
      <span class="wri-modal__title">
        Switch Locations?
      </span>
      <span class="wri-modal__description">
        If you switch locations, some items may be unavailable and prices may vary.
      </span>
      <div class="wri-modal__btn-wrapper">
        <button
          data-testid="permission-modal-dismiss"
          class="wri-btn wri-btn-primary"
          aria-label="Continue"
          (click)="navigateToMenuPage(); closeModal();"
        >
          Continue
        </button>
        <button
          data-testid="permission-modal-dismiss"
          class="wri-btn wri-btn-tertiary"
          aria-label="Cancel"
          (click)="closeModal()"
        >
          Cancel
        </button>
      </div>
    </div>
  </wri-common-modal>
</ng-template>
