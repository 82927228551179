<div class="mobile-only">
  <wri-cart-location></wri-cart-location>
</div>
<router-outlet></router-outlet>
<app-size-detector></app-size-detector>

<ng-template #oneTrustRetryModal let-close="close" let-dismiss="dismiss">
  <wri-common-modal [close]="close" [dismiss]="dismiss" [closeIcon]="false">
    <div class="one-trust-retry-modal">
      <div class="wri-modal__wrapper">
        <div class="wri-modal__title">Oops!</div>
        <div
          data-testid="one-trust-retry-modal-description"
          class="wri-modal__description">
          <p>
            Something went wrong when trying to sync your saved preferences.
            Please refresh the page to try again or proceed with your local
            session settings.
          </p>
        </div>
        <div class="wri-modal__btn-wrapper">
          <button
            data-testid="one-trust-retry-modal-yes"
            class="wri-btn wri-btn-primary"
            aria-label="refresh"
            (click)="reloadApp($event); close($event)">
            Refresh
          </button>
          <button
            data-testid="one-trust-retry-modal-no"
            class="wri-btn wri-btn-tertiary"
            aria-label="Use Session Settings"
            (click)="openOneTrustPreferencesManually($event); close($event)">
            Use Session Settings
          </button>
        </div>
      </div>
    </div>
  </wri-common-modal>
</ng-template>
