import {
  Component,
  Input,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { filter, Subscription } from 'rxjs';

import { AnalyticsService } from '../../../../ecomm/providers/legacy-providers/analytics.service';
import {
  CartFeature,
  CartFeatureState,
} from '../../../../ecomm/store/features/cart';
import { Cart } from '../../../../ecomm/types/cart.types';
import { CurrentUrlService } from '../../../../ecomm/utils/current-url/current-url.service';
import { FeesCalculationService } from '../../../../ecomm/utils/fees-calculation/fees-calculation';
import { PageType } from '../../../common/types/location-menu.types';

@Component({
  selector: 'wri-cart-price-summary',
  templateUrl: './cart-price-summary.component.html',
  styleUrls: ['./cart-price-summary.component.scss'],
})
export class CartPriceSummaryComponent implements OnInit, OnDestroy {
  @Input() cart: Cart | undefined;
  @Input() handoffModeFromOrderDetails = false;
  @Input() showRoundUpFeesSelection = false;
  @Input()
  roundupCharityData = {};

  @ViewChild('commonModal') commonModal!: TemplateRef<HTMLElement>;

  public cartFeatureState: CartFeatureState | undefined;
  private subscription: Subscription = new Subscription();
  public currentUrlScreenName = '';

  constructor(
    private modalService: NgbModal,
    private store: Store,
    private currentUrlService: CurrentUrlService,
    public feesCalculationService: FeesCalculationService,
    private analyticsService: AnalyticsService
  ) {}

  public ngOnInit(): void {
    this.subscription.add(
      this.currentUrlService.getScreenName().subscribe((url) => {
        this.currentUrlScreenName = url;
      })
    );
    this.subscribeToCartState();
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  openFeesModal() {
    this.modalService.open(this.commonModal, {
      windowClass: 'common-modal',
      centered: true,
      size: 'sm',
    });
    const pageTitle = PageType[this.currentUrlScreenName as keyof PageType];
    this.analyticsService.logGaEvent({
      event: 'service_fees_info_tap',
      info_tap: pageTitle,
    });
  }

  private subscribeToCartState(): void {
    const cartState$ = this.store
      .select(CartFeature.selectCartState)
      .pipe(filter<CartFeatureState>(Boolean));

    this.subscription.add(
      cartState$.subscribe((state) => {
        this.cartFeatureState = state;
      })
    );
  }

  isDonationCategoryAvailableInCart(): boolean {
    return this.cart?.fees.some((a) => a.category === 'donation') || false;
  }

  getDonationAmount(): number {
    if (this.isDonationCategoryAvailableInCart()) {
      return (
        this.cart?.fees.find((a) => a.category === 'donation')?.amount || 0
      );
    }
    return 0;
  }

  getDonationDescription(): string {
    if (this.isDonationCategoryAvailableInCart()) {
      return (
        this.cart?.fees.find((a) => a.category === 'donation')?.description ||
        ''
      );
    }
    return '';
  }

  getCartItemsQuantity() {
    return (
      this.cart?.items?.reduce(
        (total, cartItem) => total + cartItem.quantity,
        0
      ) ?? 0
    );
  }
}
