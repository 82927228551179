import { Injectable } from '@angular/core';
import { camelCase, sum } from 'lodash';
import { Subject } from 'rxjs';

import { NotificationService } from '../../../ecomm/utils/notification/notification.service';
import { FlavorRebalancePipe, PluralizePipe } from '../pipes';
import { Modifiers } from '../types/modifiers';

export type FlavorQuantitySelectionsType = {
  systemDefined: { [key: string]: number };
  userDefined: { [key: string]: number };
};

@Injectable()
export class FlavorSelectionService {
  private notify = new Subject<{ quantity: number; id: string }>();
  notifyObservable$ = this.notify.asObservable();

  constructor(private notificationService: NotificationService) {}

  public updateQuantity(data: { quantity: number; id: string }) {
    if (data) {
      this.notify.next(data);
    }
  }

  public updateFlavorQuantitySelections(
    selectedModifiers: Modifiers[]
  ): FlavorQuantitySelectionsType {
    const flavorQuantitySelections: FlavorQuantitySelectionsType = {
      systemDefined: ({} as { [key: string]: number }) || null,
      userDefined: ({} as { [key: string]: number }) || null
    };
    selectedModifiers.forEach((selectedModifier) => {
      // handles when user defines/changes quantity
      if (selectedModifier.touched) {
        flavorQuantitySelections.userDefined = {
          ...flavorQuantitySelections.userDefined,
          ...{ [camelCase(selectedModifier.name)]: selectedModifier.quantity }
        };
        delete flavorQuantitySelections.systemDefined[
          camelCase(selectedModifier.name)
        ];
      } else {
        // handles system defined quantity
        flavorQuantitySelections.systemDefined = {
          ...flavorQuantitySelections.systemDefined,
          ...{ [camelCase(selectedModifier.name)]: selectedModifier.quantity }
        };
      }
    });
    return flavorQuantitySelections;
  }

  getSystemDefinedModifiers(selectedModifiers: Modifiers[]): Modifiers[] {
    return selectedModifiers.filter(
      (el) => el.touched === undefined || el.touched === false
    );
  }

  totalNumberOfFlavorsSelected(selectedModifiers: Modifiers[]): number {
    return selectedModifiers.length;
  }

  pluralize(count: number, word: string) {
    return new PluralizePipe().transform(count, word);
  }

  /** All rebalancing logic in this method */
  public rebalanceSystemDefinedIfNeeded(
    itemType: string | undefined,
    selectedModifiers: Modifiers[],
    aggregateQuantity: number,
    maxModifierQuantity: number,
    minModifierQuantity: number,
    maxAvailableToAssign?: number,
    quantity?: number,
    previousQuantity?: number,
    id?: string,
    modGroupElementType?: string
  ): void {
    const currentSelectedFlavorsQuantity = selectedModifiers.reduce(
      (a, b) => a + b.quantity,
      0
    );
    let flavorQuantitySelections =
      this.updateFlavorQuantitySelections(selectedModifiers);
    let availableCountForRebalancingInSystemDefined =
      this.getAvailableCountForRebalancingInSystemDefined(
        aggregateQuantity,
        flavorQuantitySelections
      );
    if (quantity !== undefined) {
      if (quantity === 0) {
        /** handle special case when quantity = 0
         * Remove the selected modifier if quantity = 0
         */
        selectedModifiers.splice(
          selectedModifiers.findIndex(({ modifierId }) => modifierId === id),
          1
        );
      } else {
        this.checkQuantityValidityAndResetIfNeeded(
          itemType,
          selectedModifiers,
          maxModifierQuantity,
          minModifierQuantity,
          maxAvailableToAssign || 0,
          quantity,
          previousQuantity || 0,
          id || '',
          modGroupElementType
        );
      }
    }

    flavorQuantitySelections =
      this.updateFlavorQuantitySelections(selectedModifiers);
    availableCountForRebalancingInSystemDefined =
      this.getAvailableCountForRebalancingInSystemDefined(
        aggregateQuantity,
        flavorQuantitySelections
      );

    if (currentSelectedFlavorsQuantity <= aggregateQuantity) {
      // distribute availableCountForRebalancingInSystemDefined b/w all system defined flavors
      if (this.getSystemDefinedModifiers(selectedModifiers).length > 0) {
        this.reBalancingQuantity(
          availableCountForRebalancingInSystemDefined,
          maxModifierQuantity,
          this.getSystemDefinedModifiers(selectedModifiers)
        );
      }
    } else if (currentSelectedFlavorsQuantity > aggregateQuantity) {
      // handle error scenario - change quantity to last valid quantity
      if (this.getSystemDefinedModifiers(selectedModifiers).length === 0) {
        this.resetToValidQuantity(
          selectedModifiers,
          id || '',
          previousQuantity || 0
        );
      } else {
        this.reBalancingQuantity(
          availableCountForRebalancingInSystemDefined,
          maxModifierQuantity,
          this.getSystemDefinedModifiers(selectedModifiers)
        );
      }
    }
  }

  private reBalancingQuantity(
    quantity: number,
    maxModifierQuantity: number,
    selectedModifiers: Modifiers[]
  ): void {
    new FlavorRebalancePipe().transform(
      quantity,
      maxModifierQuantity,
      selectedModifiers
    );
  }

  private resetToValidQuantity(
    selectedModifiers: Modifiers[],
    id: string,
    validFlavorQuantity: number
  ): void {
    const invalidModifierIndex = selectedModifiers.findIndex(
      (sm) => sm.modifierId === id
    );
    selectedModifiers[invalidModifierIndex].quantity = validFlavorQuantity || 0;
    this.updateQuantity({ quantity: validFlavorQuantity || 0, id: id || '' });
  }

  private isQuantityMoreThanMaxLimit(
    quantity: number,
    maxModifierQuantity: number,
    maxAvailableToAssign: number
  ): boolean {
    return quantity > Math.min(maxAvailableToAssign, maxModifierQuantity);
  }

  private isQuantityLessThanMinLimit(
    quantity: number,
    minModifierQuantity: number
  ): boolean {
    return quantity < minModifierQuantity;
  }

  private getQuantitiesOfItemType(
    itemType: string | undefined,
    quantity: number
  ): string {
    if (itemType) {
      return `${quantity} ${itemType.toLowerCase()}`;
    }
    return `${this.pluralize(quantity, 'wing')}`;
  }

  /** Method to test if quantity valid;
   * if invalid - quantity is set to relevant valid number and relevant error toast shown */
  private checkQuantityValidityAndResetIfNeeded(
    itemType: string | undefined,
    selectedModifiers: Modifiers[],
    maxModifierQuantity: number,
    minModifierQuantity: number,
    maxAvailableToAssign: number,
    quantity: number,
    previousQuantity: number,
    id: string,
    modGroupElementType: string
  ): void {
    if (this.isQuantityLessThanMinLimit(quantity, minModifierQuantity)) {
      this.resetToValidQuantity(selectedModifiers, id, previousQuantity);
      const baseMessage = `You need at least ${this.getQuantitiesOfItemType(itemType, minModifierQuantity)}`;
      const msg = modGroupElementType
        ? `${baseMessage} per option.`
        : `${baseMessage} per ${this.isFlavorOrWings(itemType) ? 'flavor' : 'option'}.`;

      this.notificationService.showError(msg);
    } else if (
      this.isQuantityMoreThanMaxLimit(
        quantity,
        maxModifierQuantity,
        maxAvailableToAssign
      )
    ) {
      this.resetToValidQuantity(selectedModifiers, id, previousQuantity);
      if (quantity > maxModifierQuantity) {
        const baseMessage = `You cannot assign more than ${this.getQuantitiesOfItemType(
          itemType,
          maxModifierQuantity
        )}`;
        const msg = modGroupElementType
          ? `${baseMessage} per option.`
          : `${baseMessage} per ${this.isFlavorOrWings(itemType) ? 'flavor' : 'option'}.`;
        this.notificationService.showError(msg);
      } else {
        const total = this.totalNumberOfFlavorsSelected(selectedModifiers);
        if (modGroupElementType) {
          return this.notificationService.showError(`You have ${total} options selected and do not have any more available. Please reduce your amounts.`);
        }
        const msg = this.isFlavorOrWings(itemType)
          ? `You have ${this.pluralize(
              total,
              'flavor'
            )} selected and do not have any more wings available to split. Please reduce your wing amounts.`
          : total > 1
          ? `You have ${total} options selected and do not have any more available. Please reduce your amounts.`
          : 'You do not have any more selections available.';

        this.notificationService.showError(msg);
      }
    }
  }

  private getAvailableCountForRebalancingInSystemDefined(
    aggregateQuantity: number,
    flavorQuantitySelections: FlavorQuantitySelectionsType
  ): number {
    return (
      aggregateQuantity -
      sum(Object.values(flavorQuantitySelections.userDefined))
    );
  }

  private isFlavorOrWings(itemType: string | undefined): boolean {
    return itemType?.toLowerCase() === 'flavor' || itemType?.toLowerCase() === 'wings';
  }
}
