import { Directive, ElementRef, HostListener, Renderer2 } from '@angular/core';

@Directive({
  selector: '[wriExternalLinks]',
})
export class ExternalLinksDirective {
  constructor(renderer: Renderer2, private el: ElementRef<HTMLElement>) {
    renderer.setStyle(this.el.nativeElement, 'font-weight', '600');
    renderer.setStyle(this.el.nativeElement, 'text-decoration', 'underline');
  }

  @HostListener('keyup.enter', ['$event'])
  handleEnter(event: KeyboardEvent) {
    event.stopImmediatePropagation();
    event.stopPropagation();
    event.preventDefault();
    this.el.nativeElement.blur();
    this.el.nativeElement.click();
  }
}
