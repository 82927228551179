<label *ngIf="simpleModifierData" class="modifier-elements-wrapper" [for]="'chk-' + modGroupId + parentId + simpleModifierData.id"
[attr.data-disabled]="
simpleModifierData?.outOfStock ||
isItemDisabled(simpleModifierData.id)
">
  <div
    *ngIf="modGroupElementType === 'multiple-quantity' ? true : simpleModifierData.images && simpleModifierData.images.length > 0"
    class="mod-image-container"
  >
    <img
      data-testid="modifier-img"
      class="modifier-img"
      aria-live="polite"
      alt="{{ simpleModifierData.name }}"
      srcset="{{ simpleModifierData?.images[0]?.uri | imageResolution: 'srcset':'pdp-modifiers': '/assets/images/logo-green-product.png'}}"
      src="{{ simpleModifierData?.images[0]?.uri | imageResolution: 'src':'pdp-modifiers': '/assets/images/logo-green-product.png'}}"
      onerror="this.onerror=null;this.src='/assets/images/logo-green-product.png';this.srcset='/assets/images/logo-green-product.png'"
      role="img"
    />
  </div>
  <ng-container
    *ngIf="
      maxSelectionsAllowed > 1;
      then isMultiselectCheckbox;
      else isSingleselectRadio
    "
  ></ng-container>

  <ng-template #isMultiselectCheckbox>
    <div class="mod-elements-details checkbox-control-container">
      <div>
        <span
          class="mod-name"
          [ngClass]="
            simpleModifierData.price ||
            simpleModifierData?.minCalories ||
            simpleModifierData?.maxCalories ||
            simpleModifierData?.outOfStock
              ? 'mod-name-top'
              : ''
          "
          data-testid="mod-name"
          [ngStyle]="simpleModifierData?.outOfStock && { color: '#A1A1A1' }"
          >{{ simpleModifierData.name }}</span
        >
        <ng-container *ngIf="simpleModifierData?.outOfStock; else metadata">
          <wri-unavailable-item></wri-unavailable-item>
        </ng-container>
        <ng-template #metadata>
          <div class="mod-meta-text">
            <span *ngIf="simpleModifierData.price" data-testid="mod-price">
              +{{ simpleModifierData.price | currency
              }}<span
                *ngIf="returnCalorieRange(simpleModifierData)"
                >,
              </span>
            </span>
            <ng-container *ngIf="simpleModifierData?.minCalories !== undefined">
              <ng-container
                *ngIf="
                  !(
                    simpleModifierData.minCalories === null &&
                    simpleModifierData.maxCalories === null
                  )
                "
              >
                <span data-testid="mod-calories">{{
                  returnCalorieRange(simpleModifierData)
                }}</span>
              </ng-container>
            </ng-container>
          </div>
        </ng-template>
      </div>
      <input
        [id]="'chk-' + modGroupId + parentId + simpleModifierData.id"
        [attr.data-testid]="simpleModifierData.id | prepend : 'input-for-'"
        class="mod-elements-checkbox-input"
        tabindex="0"
        [ngClass]="isItemChecked(simpleModifierData?.id) ? 'modifier-input-checked' : ''"
        [value]="simpleModifierData.isDefault"
        [checked]="
          !simpleModifierData?.outOfStock && simpleModifierData.isDefault && isItemChecked(simpleModifierData?.id)
        "
        [disabled]="
          simpleModifierData?.outOfStock ||
          isItemDisabled(simpleModifierData.id)
        "
        (change)="
          onChangeModifierElement(
            !isItemChecked(simpleModifierData?.id),
            'multiple',
            simpleModifierData
          )
        "
        (blur)="onTouched()"
        (focus)="onTouched()"
        type="checkbox"
        [attr.aria-label]="simpleModifierData.name"
      />
    </div>
  </ng-template>

  <ng-template #isSingleselectRadio>
    <div
      class="mod-elements-details radio-control-container">
      <div class="mod-elements-details-text">
        <span
          class="mod-name"
          [ngClass]="
          simpleModifierData.price ||
          simpleModifierData?.minCalories ||
          simpleModifierData?.maxCalories ||
          simpleModifierData?.outOfStock
            ? 'mod-name-top'
            : ''
        "
        data-testid="mod-name"
        [ngStyle]="simpleModifierData?.outOfStock && { color: '#A1A1A1' }">
            {{simpleModifierData.name}}
        </span>
        <ng-container *ngIf="simpleModifierData?.outOfStock; else singleMetadata">
          <wri-unavailable-item></wri-unavailable-item>
        </ng-container>
        <ng-template #singleMetadata>
          <div class="mod-meta-text">
            <span *ngIf="simpleModifierData.price" data-testid="mod-price">
              +{{ simpleModifierData.price | currency
              }}<span
                *ngIf="returnCalorieRange(simpleModifierData)"
                >,
              </span>
            </span>
            <ng-container *ngIf="simpleModifierData?.minCalories !== undefined">
              <ng-container
                *ngIf="
                  !(
                    simpleModifierData.minCalories === null &&
                    simpleModifierData.maxCalories === null
                  )
                "
              >
                <span data-testid="mod-calories">{{
                  returnCalorieRange(simpleModifierData)
                }}</span>
              </ng-container>
            </ng-container>
          </div>
        </ng-template>
      </div>
      <input
        [ngClass]="class"
        class="mod-elements-radio-input"
        [name]="'mod-elements-' + simpleModifierData.id"
        [id]="'chk-' + modGroupId + parentId + simpleModifierData.id"
        [ngClass]="isItemChecked(simpleModifierData?.id) ? 'modifier-input-checked' : ''"
        [attr.data-testid]="simpleModifierData.id | prepend : 'input-for-'"
        tabindex="0"
        [checked]="isItemChecked(simpleModifierData.id)"
        (change)="
          onChangeModifierElement(
            !isItemChecked(simpleModifierData?.id),
            'single',
            simpleModifierData
          )
        "
        (blur)="onTouched()"
        (focus)="onTouched()"
        type="checkbox"
        [attr.aria-label]="simpleModifierData.name"
        [disabled]="simpleModifierData?.outOfStock || quantitiesEnabled? isItemDisabled(simpleModifierData?.id) : false"
      />
    </div>
  </ng-template>
</label>

<div
  *ngIf="
    maxSelectionsAllowed > 1 &&
    quantitiesEnabled &&
    !simpleModifierData?.outOfStock
  "
  class="flavor-quantity-wrapper"
>
  <wri-flavor-quantity
    *ngIf="isItemChecked(simpleModifierData?.id)"
    [itemType]="simpleModifierData?.metadata?.['item-type'] || 'Wings'"
    [flavorId]="simpleModifierData?.id"
    [minModifierQuantity]="minModifierQuantity"
    [maxModifierQuantity]="maxModifierQuantity"
    [quantity]="getQuantity(simpleModifierData?.id)"
    (valuesChanged)="
      handleModifierQuantityChange($event, simpleModifierData?.id)
    "
  >
  </wri-flavor-quantity>
</div>

<label
  *ngIf="complexModifierData"
  class="modifier-elements-wrapper"
  [for]="'chk-' + modGroupId + parentId + complexModifierData.id"
  [attr.data-disabled]="maxSelectionsAllowed > 1 ? complexModifierData?.outOfStock ||
  isItemDisabled(complexModifierData.id) : complexModifierData?.outOfStock"
>
  <div
    *ngIf="modGroupElementType === 'multiple-quantity' ? true :complexModifierData.images && complexModifierData.images.length > 0"
    class="mod-image-container"
  >
    <img
      data-testid="modifier-img"
      class="modifier-img"
      aria-live="polite"
      alt="{{ complexModifierData.name }}"
      srcset="{{ complexModifierData?.images[0]?.uri | imageResolution: 'srcset':'pdp-modifiers': '/assets/images/logo-green-product.png'}}"
      src="{{complexModifierData?.images[0]?.uri | imageResolution: 'src':'pdp-modifiers': '/assets/images/logo-green-product.png'}}"
      onerror="this.onerror=null;this.src='/assets/images/logo-green-product.png';this.srcset='/assets/images/logo-green-product.png'"
      role="img"
    />
  </div>
  <ng-container
    *ngIf="
      maxSelectionsAllowed > 1;
      then isMultiselectCheckbox;
      else isSingleselectRadio
    "
  ></ng-container>

  <ng-template #isMultiselectCheckbox>
    <div class="mod-elements-details checkbox-control-container">
      <div>
        <span
          class="mod-name"
          [ngClass]="
            complexModifierData.price ||
            complexModifierData?.minCalories ||
            complexModifierData?.maxCalories ||
            complexModifierData?.outOfStock
              ? 'mod-name-top'
              : ''
          "
          data-testid="mod-name"
          [ngStyle]="complexModifierData?.outOfStock && { color: '#A1A1A1' }"
          >{{ complexModifierData.name }}</span
        >
        <ng-container
          *ngIf="
            complexModifierData?.outOfStock;
            else complexModifierMultiMetadata
          "
        >
          <wri-unavailable-item></wri-unavailable-item>
        </ng-container>
        <ng-template #complexModifierMultiMetadata>
          <div class="mod-meta-text">
            <span *ngIf="complexModifierData.price" data-testid="mod-price">
              +{{ complexModifierData.price | currency
              }}<span
                *ngIf="returnCalorieRange(complexModifierData)"
                >,
              </span>
            </span>
            <ng-container
              *ngIf="complexModifierData?.minCalories !== undefined"
            >
              <ng-container
                *ngIf="
                  !(
                    complexModifierData.minCalories === null &&
                    complexModifierData.maxCalories === null
                  )
                "
              >
                <span data-testid="mod-calories">{{
                  returnCalorieRange(complexModifierData)
                }}</span>
              </ng-container>
            </ng-container>
          </div>
        </ng-template>
      </div>
      <input
        [id]="'chk-' + modGroupId + parentId + complexModifierData.id"
        [attr.data-testid]="complexModifierData.id | prepend : 'input-for-'"
        [ngClass]="isItemChecked(complexModifierData?.id) ? 'modifier-input-checked' : ''"
        class="mod-elements-checkbox-input"
        tabindex="0"
        [value]="complexModifierData.isDefault"
        [checked]="
          !complexModifierData?.outOfStock &&
          complexModifierData.isDefault &&
          isItemChecked(complexModifierData?.id)
        "
        [disabled]="
          complexModifierData?.outOfStock ||
          isItemDisabled(complexModifierData.id)
        "
        (change)="
          onChangeModifierElement(
            !isItemChecked(complexModifierData?.id),
            'multiple',
            complexModifierData
          )
        "
        (blur)="onTouched()"
        (focus)="onTouched()"
        type="checkbox"
        [attr.aria-label]="complexModifierData.name"
      />
    </div>
  </ng-template>

  <ng-template #isSingleselectRadio>
    <div class="mod-elements-details radio-control-container">
      <div class="mod-elements-details-text">
        <span
          class="mod-name"
          [ngClass]="
            complexModifierData.price ||
            complexModifierData?.minCalories ||
            complexModifierData?.maxCalories ||
            complexModifierData?.outOfStock
              ? 'mod-name-top'
              : ''
          "
          data-testid="mod-name"
          [ngStyle]="complexModifierData?.outOfStock && { color: '#A1A1A1' }"
        >
          {{ complexModifierData.name }}
        </span>
        <ng-container
          *ngIf="complexModifierData?.outOfStock; else complexModSingleMetadata"
        >
          <wri-unavailable-item></wri-unavailable-item>
        </ng-container>
        <ng-template #complexModSingleMetadata>
          <div class="mod-meta-text">
            <span 
              data-testid="mod-price"
              *ngIf="(complexModifierData.price | displayAdditionalPrice:selectedItemModifierGroups) > 0"
            >
              +{{ complexModifierData.price | displayAdditionalPrice:selectedItemModifierGroups  | currency }}
              <span *ngIf="returnCalorieRange(complexModifierData)">,</span>
            </span>
            <ng-container *ngIf="complexModifierData?.minCalories !== undefined">
              <ng-container
                *ngIf="
                  !(
                    complexModifierData.minCalories === null &&
                    complexModifierData.maxCalories === null
                  )
                "
              >
                <span data-testid="mod-calories">{{
                  returnCalorieRange(complexModifierData)
                }}</span>
              </ng-container>
            </ng-container>
          </div>
        </ng-template>
      </div>
      <input
        [ngClass]="class"
        class="mod-elements-radio-input"
        [name]="'mod-elements-' + complexModifierData.id"
        [id]="'chk-' + modGroupId + parentId + complexModifierData.id"
        [attr.data-testid]="complexModifierData.id | prepend : 'input-for-'"
        tabindex="0"
        [checked]="isItemChecked(complexModifierData.id)"
        (change)="
          onChangeModifierElement(
            !isItemChecked(complexModifierData?.id),
            'single',
            complexModifierData
          )
        "
        (blur)="onTouched()"
        (focus)="onTouched()"
        type="checkbox"
        [attr.aria-label]="complexModifierData.name"
        [disabled]="complexModifierData?.outOfStock"
      />
    </div>
  </ng-template>
</label>

<div
  *ngIf="complexModifierData && !complexModifierData?.outOfStock"
  class="mod-customise-wrapper"
>
  <div
    *ngIf="complexModifierData.images && complexModifierData.images.length > 0"
    class="mod-customise-filler"
  ></div>
  <div
    class="mod-customise-inner-wrapper mod-customise-negative-margin"
    *ngIf="
      complexModifierData.modifierGroups &&
      complexModifierData.modifierGroups.length > 0 &&
      isItemChecked(complexModifierData.id)
    "
  >
    <a wriFocusOutline class="customize-btn" (click)="openModifierModal(complexModifierData.id)">
      Customize
    </a>
    <div class="mod-customise-selected-modifers">
      <span class="error-label" *ngIf="requiredUnselectedModifierGroupLabels">{{requiredUnselectedModifierGroupLabels}}</span>
      <ng-container *ngIf="requiredUnselectedModifierGroupLabels === null">
        <ng-container
          *ngFor="
            let selectedItemModifierGroup of selectedItemModifierGroups;
            index as i
          "
        >
          <ng-container
            *ngFor="
              let selectedItemModifier of selectedItemModifierGroup.modifiers;
              index as j
            "
          >
            <ng-container
              *ngIf="
                (i < selectedItemModifierGroups.length &&
                  !(i === 0 && j === 0)) ||
                (i >= selectedItemModifierGroups.length && j > 0)
              "
              >, </ng-container>
            {{ selectedItemModifier.name }}</ng-container>
        </ng-container>
      </ng-container>
    </div>
  </div>
</div>

<div
  *ngIf="
  maxSelectionsAllowed > 1 &&
  quantitiesEnabled &&
  !complexModifierData?.outOfStock &&
  complexModifierData"
  class="flavor-quantity-wrapper"
>
  <wri-flavor-quantity
    *ngIf="isItemChecked(complexModifierData?.id)"
    [itemType]="complexModifierData?.metadata?.['item-type'] || 'Wings'"
    [flavorId]="complexModifierData?.id"
    [minModifierQuantity]="minModifierQuantity"
    [maxModifierQuantity]="maxModifierQuantity"
    [quantity]="getQuantity(complexModifierData?.id)"
    (valuesChanged)="
      handleModifierQuantityChange($event, complexModifierData?.id)
    "
  >
  </wri-flavor-quantity>
</div>
