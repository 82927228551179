import { Component, Input } from '@angular/core';
import { AnalyticsService } from '../../../../ecomm/providers/legacy-providers/analytics.service';
import { Router } from '@angular/router';

@Component({
  selector: 'wri-phone-number',
  styleUrls: ['./phone-number.component.scss'],
  template: `
    <a
      (click)="logAnalytics()"
      *ngIf="phoneNumber"
      class="phone"
      [href]="phoneNumber | prepend : 'tel:'"
      wriFocusOutline
    >
      <wri-icon icon="wri-phone"></wri-icon>
      <span wriExternalLinks>{{ phoneNumber }}</span>
    </a>
  `
})
export class PhoneNumberComponent {
  @Input()
  phoneNumber!: string;

  constructor(
    private analyticsService: AnalyticsService,
    private router: Router
  ) {}

  logAnalytics() {
    this.analyticsService.logGaEvent({
      event: 'call_store',
      page: this.analyticsService.formatPageUrl(
        this.router.routerState.snapshot.url
      )
    });
  }
}
