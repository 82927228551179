<div
  *ngIf="isPayInStorePaymentSupported"
  class="instore-payment-method"
  [ngClass]="{
    'in-store-payment-unavailable':
      (cartSubTotal &&
        selectedHandoffModeDetails?.maxPayInStoreOrderAmount &&
        cartSubTotal >
          selectedHandoffModeDetails?.maxPayInStoreOrderAmount / 100) ||
      false
  }"
>
  <!-- Display add gift card form -->
  <div
    class="in-store-payment-method-form payment-method-card"
    data-testid="in-store-payment-method"
  >
    <div class="in-store-payment-method-form-name">
      <div class="check-box-wrapper pay-in-store">

        <div class="flex card-icon-wrapper">
          <wri-icon icon="wri-in-store"></wri-icon>
        </div>

        <wri-checkbox
          [defaultTemplate]="false"
          [id]="'pay-in-store'"
          class="span-full-width-lg"
          label="Pay in restaurant"
          [extraLabelTemplate]="value? ( 'Amount: ' + (totalPrice|currency : 'USD' : 'symbol')): null"
          [extraLabelClass]="'extra-label-class-bold'"
          [value]="value"
          (change)="writeValue($event.target.checked)"
          customClass="paymentLabel"
          [disabled]="
            (cartSubTotal &&
              selectedHandoffModeDetails?.maxPayInStoreOrderAmount &&
              cartSubTotal >
                selectedHandoffModeDetails?.maxPayInStoreOrderAmount / 100) ||
            false
          "
          [ngClass]="{
            'in-store-unavailable-label':
              (cartSubTotal &&
                selectedHandoffModeDetails?.maxPayInStoreOrderAmount &&
                cartSubTotal >
                  selectedHandoffModeDetails?.maxPayInStoreOrderAmount / 100) ||
              false
          }"
        ></wri-checkbox>
      </div>
    </div>
    <div
      data-testid="in-store-unavailable"
      class="in-store-unavailable"
      *ngIf="
        (cartSubTotal &&
          selectedHandoffModeDetails?.maxPayInStoreOrderAmount &&
          cartSubTotal >
            selectedHandoffModeDetails?.maxPayInStoreOrderAmount / 100) ||
        false
      "
    >
      Orders over
      {{ selectedHandoffModeDetails.maxPayInStoreOrderAmount / 100 | currency }}
      must be paid online
    </div>
  </div>
</div>
