
<wri-page-loading-indicator *ngIf="isLoading"></wri-page-loading-indicator>
<wri-partial-outage-modal></wri-partial-outage-modal>
<div class="checkout-signup-container">
  <div class="grid">
    <section class="image-wrapper">
      <img
        src="https://cdn.bfldr.com/NDQASMJ1/as/3zcpcs68t3qqrqp8ctvzjhpz/register-image?auto=webp&format=png"
        alt="Register image"
        class="register-image"
      />
    </section>
    <section class="benefits-wrapper">
      <div class="title">
        Join the crew
      </div>
      <div class="desc">
        When you sign up with Wingstop, we hit you with a little more flavor, a little more often. Welcome to the club.
      </div>
      <div class="benefits">
        <div class="faster-checkouts">
          <wri-icon icon="wri-faster-checkouts"></wri-icon>
          <div>
            Faster checkouts
          </div>
        </div>
        <div class="fav-locations">
          <wri-icon icon="wri-heart-filled"></wri-icon>
          <div>
            Favorite Spots
          </div>
        </div>
        <div class="flavor-events">
          <wri-icon icon="wri-flavor-events"></wri-icon>
          <div>
            Flavor News
          </div>
        </div>
        <div class="birthday-gift">
          <wri-icon icon="wri-free-birthday-gift"></wri-icon>
          <div>
            Birthday Gift
          </div>
        </div>
      </div>
    </section>
    <section class="signup-sidebar-wrapper">
      <wri-sign-up-form (signUpFormData)="onSignUpFormSubmit($event)" [passwordRequirements]="passwordRequirements$ | async"></wri-sign-up-form>
      <div class="divider">
        <hr/>
        <span>OR</span>
        <hr/>
      </div>
      <div class="social-sign-up">
        <button
          (click)="onSocialSignupClick('apple')"
          class="apple-wrapper"
          data-testid="apple-btn"
          data-cy="Apple">
          <wri-icon icon="wri-apple"></wri-icon>
          <span class="apple-text">Sign up with Apple</span>
        </button>
        <button
          (click)="onSocialSignupClick('facebook')"
          class="facebook-wrapper"
          data-testid="facebook-btn"
          data-cy="Facebook">
          <wri-icon icon="wri-facebook"></wri-icon>
          <span class="facebook-text">Sign up with Facebook</span>
        </button>
        <button
          (click)="onSocialSignupClick('google')"
          class="google-wrapper"
          data-testid="google-btn"
          data-cy="Google">
          <img
            src="/assets/images/google-logo.svg"
            alt="Sign up with Google"
            aria-hidden="true"
          />
          <span class="google-text">Sign up with Google</span>
        </button>
      </div>
      <div>
        <div class='login-text'>
          Already have an account?
          <a wriFocusOutline role='button' [attr.aria-label]="'log in'" (click)="onLogin()">Log In</a>
        </div>
        <div class="terms-conditions">
          By creating this account, I am confirming I am 18 years of age or older, will be automatically enrolled, will
          receive email updates, promotions, and offers from Wingstop, and agree to Wingstop’s
          <a wriFocusOutline role='button' [attr.aria-label]="'Privacy Policy'" (click)="onPrivacyPolicy()">Privacy Policy</a>, and Wingstop’s
          <a wriFocusOutline role='button' [attr.aria-label]="'Terms & Conditions'" (click)="onTermsAndConditions()">Terms & Conditions.</a>
        </div>
        <div class="align-center">
          <wri-google-recaptcha-branding />
        </div>
      </div>
    </section>
  </div>
</div>
