import { ecommApiResponse } from "./common.types";
import * as t from "io-ts";


/* #region JWT */
export const JWTDto = t.intersection([
  t.type({
    token: t.string
  }),
  t.partial({})
]);

export type JWTDto = t.TypeOf<
  typeof JWTDto
>;
export type JWT = JWTDto

export const JWTResponse = ecommApiResponse(
  JWTDto
);
export type JWTResponse = t.TypeOf<
  typeof JWTResponse
>;
/* #endregion */
