import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import { Options } from 'ngx-slider-v2';
import { BehaviorSubject, Subscription } from 'rxjs';

import { AnalyticsService } from '../../../../ecomm/providers/legacy-providers/analytics.service';
import { ActivatedRoute, Router } from '@angular/router';
import { inRange } from 'lodash';

@Component({
  selector: 'wri-wing-calculator-controls',
  styleUrls: ['./wing-calculator-controls.component.scss'],
  template: `
    <div class="scatter-wing-content">
      <h2>Need a hand with your order?</h2>
      <div data-testid="people-count" class="people-count">
        <p>How many in your crew?</p>

        <div class="people-count-slider-wpr">

          <a
            wriFocusOutline
            (click)="changePeopleCount(-1)"
            data-testid="decrease-count"
            role="button"
            [ngClass]="{'disabled-btn' : selectedPeopleCount <= peopleCountSliderConfig.floor }"
          >
            <wri-icon
              role="text"
              [attr.aria-label]="'decrease count'"
              data-testid="minus-icon"
              class="slider-btn minus-icon"
              icon="wri-subtract-circle"
            ></wri-icon>
          </a>


          <ngx-slider
            data-testid="people-count-slider"
            [options]="peopleCountSliderConfig"
            [value]="selectedPeopleCount"
            (valueChange)="
              browserRefresh = false;
              peopleCount$.next($event);
              logWingCalculatorCalculateEvent()
            "
            aria-live="polite"></ngx-slider>

          <a
            wriFocusOutline
            (click)="changePeopleCount(+1)"
            data-testid="increase-count"
            role="button"
            [ngClass]="{'disabled-btn' : selectedPeopleCount >= peopleCountSliderConfig.ceil }"
          >
            <wri-icon
              role="text"
              [attr.aria-label]="'increase count'"
              data-testid="add-icon"
              class="slider-btn plus-icon"
              icon="wri-add-circle"
            ></wri-icon>
          </a>

        </div>
      </div>
      <div data-testid="hunger-level" class="hunger-level">
        <p>How hungry?</p>
        <ul data-testid="hunger-level-picker" class="hunger-level-picker">
          <li
            *ngFor="let level of hungerLevels"
            class="hunger-level-picker-option"
          >
            <button
              [attr.data-testid]="
                level.value | prepend : 'hunger-level-button-'
              "
              class="hunger-level-button"
              [class.selected]="level.value === selectedHungerLevel"
              (click)="
                browserRefresh = false;
                hungerLevel$.next(level.value);
                logWingCalculatorCalculateEvent()
              ">
              {{ level.display }}
            </button>
          </li>
        </ul>
      </div>
      <div class="run-the-numbers-wrapper">
        <button
          *ngIf="!_shouldShow"
          class="wri-btn wri-btn-primary run-the-numbers"
          (click)="runTheNumbers()"
        >
          run the numbers
        </button>
      </div>
    </div>
  `
})
export class WingCalculatorControlsComponent implements OnInit, OnDestroy {
  private subscription = new Subscription();
  _shouldShow = false;
  browserRefresh = true;
  increaseDecreaseButtonNotClicked = true;

  @Input()
  shouldNavigateToDedicatedPage = false;

  // Hunger Level
  @Output()
  hungerLevel = new EventEmitter<number>();
  hungerLevel$ = new BehaviorSubject(0);
  selectedHungerLevel = 0;
  readonly hungerLevels = [
    {
      display: 'Snacky',
      enum: 'snacky',
      value: 0
    },
    {
      display: 'Hungry',
      enum: 'hungry',
      value: 1
    },
    {
      display: 'Starving',
      enum: 'starving',
      value: 2
    }
  ] as const;

  // People Count
  @Output()
  peopleCount = new EventEmitter<number>();
  peopleCount$ = new BehaviorSubject(1);
  selectedPeopleCount = 1;
  readonly peopleCountSliderConfig: Options = {
    floor: 1,
    ceil: 20,
    ariaLabel: "How many in your crew?"
  };

  // Should Show
  @Output()
  shouldShow = new EventEmitter<boolean>();

  constructor(
    private analyticsService: AnalyticsService,
    private router: Router,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.subscription.add(
      this.hungerLevel$.subscribe((hungerLevel) => {
        const hungerParam = this.getQueryParamValue('hunger');
        const hungerParamValue = this.hungerLevels.find(hungerLevel => hungerLevel.enum === hungerParam)?.value || 0;
        this.selectedHungerLevel = this.browserRefresh && this.checkIfValid('hunger')
          ? hungerParamValue
          : hungerLevel;
        this.hungerLevel.emit(this.selectedHungerLevel);
      })
    );
    this.subscription.add(
      this.peopleCount$.subscribe((peopleCount) => {
        const peopleParam = this.getQueryParamValue('people');
        this.selectedPeopleCount = this.browserRefresh && this.checkIfValid('people') && this.increaseDecreaseButtonNotClicked
          ? Number(peopleParam)
          : peopleCount;
        this.peopleCount.emit(this.selectedPeopleCount);
      })
    );
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  private checkIfValid(param: string): boolean {
    switch (param) {
      case 'people': {
        const peopleParam = this.getQueryParamValue(param);
        if (peopleParam && inRange(Number(peopleParam), this.peopleCountSliderConfig.floor, this.peopleCountSliderConfig.ceil)) {
          this.runTheNumbers();
          return true;
        }
        return false;
      }
      case 'hunger': {
        const hungerParam = this.getQueryParamValue(param);
        if (hungerParam && this.hungerLevels.some(hungerLevel => hungerLevel.enum === hungerParam)) {
          this.runTheNumbers();
          return true;
        }
        return false;
      }
      default:
        return false;
    }
  }

  private getQueryParamValue(param: string) {
    return this.route.snapshot.queryParamMap.get(param);
  }

  public runTheNumbers() {
    if (this.shouldNavigateToDedicatedPage) {
      this.router.navigate(['/order/wing-calculator'],
        {
          queryParams: {
            people: this.selectedPeopleCount,
            hunger: this.hungerLevels[this.selectedHungerLevel].enum
          }
        });
    } else {
      this._shouldShow = true;
    }
    this.shouldShow.emit(true);
    this.logWingCalculatorCalculateEvent();
  }

  public logWingCalculatorCalculateEvent() {
    this.analyticsService.logGaEvent({
      event: 'wing_calculator_calculate',
      wing_calculator_people_count: this.selectedPeopleCount,
      wing_calculator_hunger_index: this.selectedHungerLevel,
    });
  }

  changePeopleCount(amount: number) {
    this.increaseDecreaseButtonNotClicked = false;
    const newValue = this.selectedPeopleCount + amount;
    if (newValue >= this.peopleCountSliderConfig.floor && newValue <= this.peopleCountSliderConfig.ceil) {
      this.selectedPeopleCount = newValue;
      this.peopleCount$.next(this.selectedPeopleCount);
    }
  }
}
