import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Inject,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import {
  AbstractControl,
  NonNullableFormBuilder,
  UntypedFormGroup
} from '@angular/forms';
import {
  BehaviorSubject,
  combineLatest,
  filter,
  map,
  NEVER,
  Observable,
  startWith,
  Subscription
} from 'rxjs';

import { Cart } from '../../../../../ecomm/types/cart.types';
import { OrderSubmitRequestPayment } from '../../../../../ecomm/types/order.types';
import { CustomerVaultedCards } from '../../../../../ecomm/types/payment.types';
import { StoreInfo } from '../../../../../ecomm/types/store-info.types';
import { ApplePayPaymentMethodComponent } from '../apple-pay-payment-method/apple-pay-payment-method.component';
import { CreditCardPaymentMethodComponent } from '../credit-card-payment-method/credit-card-payment-method.component';
import { GiftCardPaymentMethodComponent } from '../gift-card-payment-method/gift-card-payment-method.component';
import { GooglePayPaymentMethodComponent } from '../google-pay-payment-method/google-pay-payment-method.component';
import { InStorePaymentMethodComponent } from '../in-store-payment-method/in-store-payment-method.component';
import { PaypalPaymentMethodComponent } from '../paypal-payment-method/paypal-payment-method.component';
import { VenmoPaymentMethodComponent } from '../venmo-payment-method/venmo-payment-method.component';
import {
  AllowedSplits,
  CreditCardPaymentMethodStatusEvent,
  ExpressPaymentMethodLike,
  GiftCardPaymentMethodStatusEvent,
  PaymentInfo,
  PaymentMethodLike,
  PaymentType
} from './payment-method.types';
import {
  APPLE_PAY_PROVIDER,
  ApplePayProviderType
} from '../../../../../ecomm/providers/apple-pay/apple-pay.provider';
import { PaymentMethodSupportedPipe } from '../../../../common';
import { FeatureFlagService } from '../../../../../ecomm/utils/feature-flag/feature-flag.service';
import { RegionalConfigurationFeatureState } from '../../../../../ecomm/store/features/regional-configuration';

@Component({
  selector: 'wri-payment-method',
  templateUrl: './payment-method.component.html',
  styleUrls: ['./payment-method.component.scss'],
})
export class PaymentMethodComponent
  implements
    OnInit,
    OnDestroy,
    PaymentMethodLike,
    ExpressPaymentMethodLike,
    AfterViewInit,
    OnChanges
{
  @Input()
  public cart: Cart | null = null;

  @Input()
  public storeInfoData: StoreInfo | null = null;

  @Input()
  public expressPayPrerequisitesMet = false;

  @Input()
  public paymentsSelected: PaymentMethodLike[] = [];

  @Input()
  public isUserLoggedIn: boolean | null = null;

  @Input()
  public vaultedCards: CustomerVaultedCards | undefined;
  @Output()
  valid = new EventEmitter<boolean>();
  @Output()
  validGiftCards = new EventEmitter<boolean>();
  @Output()
  selected = new EventEmitter<boolean>();
  @Output()
  expressPay = new EventEmitter<PaymentInfo>();
  @Output()
  expressPayClicked = new EventEmitter<string>();
  public giftCardPaymentStatus$ =
    new BehaviorSubject<GiftCardPaymentMethodStatusEvent>({
      appliedGiftCardCount: 0,
      giftCardCount: 0,
      priceAfterGiftCards: this.cart?.total ?? 0,
      appliedGiftCardCountWithZeroBalance: 0,
    });
  public creditCardPaymentStatus$ =
    new BehaviorSubject<CreditCardPaymentMethodStatusEvent>({
      appliedCreditCardCount: 0,
      appliedCreditCardCountWithZeroBalance: 0,
      creditCardCount: 0,
      priceAfterCreditCards: this.cart?.total ?? 0,
      newCreditCard: false,
    });
  public selectedPaymentMethodForm: UntypedFormGroup = new UntypedFormGroup({});
  public creditCardAmount = 0;
  expressPaySupported = false;
  expressPayDoneLoading = false;
  buttonCount = new BehaviorSubject<number>(0);
  @ViewChild(ApplePayPaymentMethodComponent)
  applePayComponent!: ApplePayPaymentMethodComponent;
  @ViewChild(PaypalPaymentMethodComponent)
  paypalPayComponent!: PaypalPaymentMethodComponent;
  @ViewChild(VenmoPaymentMethodComponent)
  venmoPayChildComponent!: VenmoPaymentMethodComponent;
  @ViewChild(GooglePayPaymentMethodComponent)
  googlePayComponent!: GooglePayPaymentMethodComponent;
  @ViewChild(GiftCardPaymentMethodComponent)
  private giftCardPaymentMethod!: GiftCardPaymentMethodComponent;
  @ViewChild(CreditCardPaymentMethodComponent)
  private creditCardPaymentMethod!: CreditCardPaymentMethodComponent;
  @ViewChild(InStorePaymentMethodComponent)
  private inStorePaymentMethod!: InStorePaymentMethodComponent;
  private subscription = new Subscription();
  digitalWalletPaymentType: string | null;
  applePayReadyFlag = false;
  enableNewPaymentFlow = false

  @Input()
  public regionalConfigState: RegionalConfigurationFeatureState | null = null;

  @Output()
  payWithDigitalWallet = new EventEmitter<string|null>();

  @Input()
  resetDigitalWalletRadioButton = false;

  constructor(
    private fb: NonNullableFormBuilder,
    private cdr: ChangeDetectorRef,
    @Inject(APPLE_PAY_PROVIDER)
    private _applePayProvider: ApplePayProviderType,
    public featureFlagService: FeatureFlagService
  ) {}

  async ngOnChanges(changes: SimpleChanges): Promise<void> {
    const { regionalConfigState, resetDigitalWalletRadioButton } = changes;
    if (regionalConfigState) {
      this.areVaultedGiftCardsViewEnabled =
        await this.featureFlagService.getFeatureFlagValue(
          'enableVaultedGiftCardView',
          'feature_enable_vaulted_gift_card_view'
        );
      this.areVaultedCreditCardsViewEnabled =
        await this.featureFlagService.getFeatureFlagValue(
          'enableVaultedCreditCardView',
          'feature_enable_vaulted_credit_card_view'
        );

      this.areVaultedGiftCardsPayEnabled =
        await this.featureFlagService.getFeatureFlagValue(
          'enableVaultedGiftCardPay',
          'feature_enable_vaulted_gift_card_pay'
        );
      this.areVaultedCreditCardsPayEnabled =
        await this.featureFlagService.getFeatureFlagValue(
          'enableVaultedCreditCardPay',
          'feature_enable_vaulted_credit_card_pay'
        );

      this.areVaultedGiftCardsVaultEnabled =
        await this.featureFlagService.getFeatureFlagValue(
          'enableVaultedGiftCardVault',
          'feature_enable_vaulted_gift_card_vault'
        );
      this.areVaultedCreditCardsVaultEnabled =
        await this.featureFlagService.getFeatureFlagValue(
          'enableVaultedCreditCardVault',
          'feature_enable_vaulted_credit_card_vault'
        );
      this.enableNewPaymentFlow = await this.featureFlagService.getFeatureFlagValue(
          'enableDigitalWalletPlaceOrderButton',
          'feature_enable_digital_wallet_place_order_button'
      );
    }

    if (resetDigitalWalletRadioButton) {
      const resetFlag = changes.resetDigitalWalletRadioButton.currentValue;

      if (resetFlag) {
        this.resetRadioSelection();
      }
    }
  }

  private _userLoggedIn = false;

  get userLoggedIn(): boolean {
    return this._userLoggedIn;
  }

  @Input() set userLoggedIn(input: boolean) {
    this._userLoggedIn = input;
  }

  areVaultedGiftCardsViewEnabled: boolean | undefined;
  areVaultedCreditCardsViewEnabled: boolean | undefined;

  areVaultedGiftCardsPayEnabled: boolean | undefined;
  areVaultedCreditCardsPayEnabled: boolean | undefined;

  areVaultedGiftCardsVaultEnabled: boolean | undefined;
  areVaultedCreditCardsVaultEnabled: boolean | undefined;

  ngOnInit(): void {
    this.creditCardAmount = this.cart?.total ?? 0;
    this.selectedPaymentMethodForm = this.fb.group({
      [PaymentType.inStore]: false,
      [PaymentType.giftCard]: false,
      [PaymentType.creditCard]: false,
      [PaymentType.applePay]: false,
      [PaymentType.venmo]: false,
      [PaymentType.payPal]: false,
      [PaymentType.googlePay]: false,
    });
    this.subscription.add(
      this.getPaymentTypeControl(PaymentType.inStore)
        .valueChanges.pipe(filter(Boolean))
        .subscribe(this.handlePaymentTypeChange(PaymentType.inStore))
    );
    this.subscription.add(
      this.getPaymentTypeControl(PaymentType.giftCard)
        .valueChanges.pipe(filter(Boolean))
        .subscribe(this.handlePaymentTypeChange(PaymentType.giftCard))
    );
    this.subscription.add(
      this.getPaymentTypeControl(PaymentType.creditCard)
        .valueChanges.pipe(filter(Boolean))
        .subscribe(this.handlePaymentTypeChange(PaymentType.creditCard))
    );
    this.subscription.add(
      this.getPaymentTypeControl(PaymentType.applePay)
        .valueChanges.pipe(filter(Boolean))
        .subscribe(this.handlePaymentTypeChange(PaymentType.applePay))
    );
    this.subscription.add(
      this.getPaymentTypeControl(PaymentType.venmo)
        .valueChanges.pipe(filter(Boolean))
        .subscribe(this.handlePaymentTypeChange(PaymentType.venmo))
    );
    this.subscription.add(
      this.getPaymentTypeControl(PaymentType.payPal)
        .valueChanges.pipe(filter(Boolean))
        .subscribe(this.handlePaymentTypeChange(PaymentType.payPal))
    );
    this.subscription.add(
      this.getPaymentTypeControl(PaymentType.googlePay)
        .valueChanges.pipe(filter(Boolean))
        .subscribe(this.handlePaymentTypeChange(PaymentType.googlePay))
    );
    this.subscription.add(
      combineLatest([
        this.selectedPaymentMethodForm.valueChanges,
        this.giftCardPaymentStatus$,
        this.creditCardPaymentStatus$,
      ])
        .pipe(
          map(
            ([selectionForm, giftCardPaymentStatus, creditCardPaymentStatus]) =>
              Object.values(selectionForm).some(Boolean) ||
              giftCardPaymentStatus.appliedGiftCardCount > 0 ||
              creditCardPaymentStatus.appliedCreditCardCount > 0
          ),
          startWith(true)
        )
        .subscribe((isValid) => {
          const areGiftCardsInvalid =
            (this.giftCardPaymentStatus$.value.appliedGiftCardCount > 0 ||
              this.giftCardPaymentStatus$.value
                .appliedGiftCardCountWithZeroBalance > 0) &&
            this.giftCardPaymentStatus$.value.priceAfterGiftCards > 0 &&
            this.creditCardPaymentStatus$.value.appliedCreditCardCount === 0 &&
            !this.creditCardPaymentStatus$.value.newCreditCard;
          this.validGiftCards.emit(!areGiftCardsInvalid);
          return this.valid.emit(isValid);
        })
    );

    this.subscription.add(
      combineLatest([
        this.selectedPaymentMethodForm.valueChanges.pipe(startWith({})),
        this.giftCardPaymentStatus$,
        this.creditCardPaymentStatus$,
      ])
        .pipe(
          map(
            ([selectionForm, giftCardPaymentStatus, creditCardPaymentStatus]) =>
              Object.values(selectionForm).some(Boolean) ||
              giftCardPaymentStatus.appliedGiftCardCount > 0 ||
              creditCardPaymentStatus.appliedCreditCardCount > 0
          ),
          startWith(false)
        )
        .subscribe((selected) => this.selected.emit(selected))
    );
  }

  async ngAfterViewInit() {
    if (await this.isApplePayReady()) this.incrementCount();
    if (this.isPaymentMethodReady('enablePaypal', 'payPal'))
      this.incrementCount();
    if (this.isPaymentMethodReady('enableGooglePay', 'googlePay'))
      this.incrementCount();
    this.expressPayDoneLoading = await this.expressPayDoneLoadingMethod();

    this.cdr.detectChanges();
  }

  public areGiftCardsVaulted(): boolean | undefined {
    return (
      this.vaultedCards?.vaultedGiftCard &&
      this.vaultedCards.vaultedGiftCard.length > 0
    );
  }

  public areCreditCardsVaulted(): boolean | undefined {
    return (
      this.vaultedCards?.vaultedCreditCard &&
      this.vaultedCards.vaultedCreditCard.length > 0
    );
  }

  async expressPayDoneLoadingMethod() {
    return (
      (await this.isApplePayReadyOrSkip()) &&
      this.isPaymentMethodReadyOrSkip('enablePaypal', 'payPal') &&
      this.isPaymentMethodReadyOrSkip('enableGooglePay', 'googlePay')
    );
  }

  async isApplePayReadyOrSkip() {
    return (
      !this.featureFlagService.featureFlags.enableApplePay ||
      !(await this._applePayProvider.isSupported()) ||
      (await this.isApplePayReady())
    );
  }

  async isApplePayReady() {
    this.applePayReadyFlag = await this._applePayProvider.isSupported() &&     this.isPaymentMethodReady('enableApplePay', 'applePay')
    return this.applePayReadyFlag;
  }

  isPaymentMethodReady(featureFlagName: string, paymentMethodName: string) {
    return (
      this.featureFlagService.featureFlags[featureFlagName] &&
      this.isPaymentMethodSupported(paymentMethodName)
    );
  }

  isPaymentMethodReadyOrSkip(
    featureFlagName: string,
    paymentMethodName: string
  ): boolean {
    //this means if the payment method is not enabled or supported then we can skip the check for that
    return (
      !this.featureFlagService.featureFlags[''] ||
      this.isPaymentMethodReady(featureFlagName, paymentMethodName)
    );
  }

  isPaymentMethodSupported(paymentMethodName: string) {
    return new PaymentMethodSupportedPipe().transform(
      paymentMethodName,
      this.storeInfoData?.storeDetails.handoffModes ?? [],
      this.cart?.handoffMode ?? 'carryout'
    ).hasValidPayment;
  }

  ngOnDestroy(): void {
    if (!this.subscription.closed) {
      this.subscription.unsubscribe();
    }
  }

  handleExpressPaymentMethodSupportedChange(val: boolean) {
    this.expressPaySupported = this.expressPaySupported || val;
  }

  getPaymentInfo(): Observable<PaymentInfo> {
    const selectedFormValue = this.selectedPaymentMethodForm.value;
    const giftCardStatus = this.giftCardPaymentStatus$.value;
    const creditCardStatus = this.creditCardPaymentStatus$.value;

    //scrollToCreditCardSection
    selectedFormValue?.creditCard && this.scrollToCreditCardSection();

    const methodsToUse: PaymentMethodLike[] =
      this.getSelectedNonExpressPaymentMethods();

    if (methodsToUse.length < 1) {
      this.valid.emit(false);
      return NEVER;
    }

    if (
      selectedFormValue.giftCard &&
      giftCardStatus.priceAfterGiftCards > 0 &&
      giftCardStatus.appliedGiftCardCountWithZeroBalance > 0 &&
      creditCardStatus.appliedCreditCardCount === 0 &&
      !this.creditCardPaymentStatus$.value.newCreditCard
    ) {
      this.validGiftCards.emit(false);
    }

    return combineLatest(methodsToUse.map((m) => m.getPaymentInfo())).pipe(
      map(([...methods]) =>
        methods.reduce(
          (acc, next) => {
            const newPayments = [
              ...(acc.payments ?? []),
              ...(next.payments ?? []),
            ];
            return {
              ...acc,
              billingMethod: next.billingMethod,
              payments:
                newPayments.length > 0
                  ? this.filterPaymentsWithOnlyAmountGreaterThan0(newPayments)
                  : undefined,
            };
          },
          {
            billingMethod: 'payInStore',
          }
        )
      )
    );
  }

  handleExpressPay(event: PaymentInfo) {
    this.expressPay.emit(event);
  }

  public async getPlaceOrderResponse() {
    await this.creditCardPaymentMethod?.placeOrderResponse();
  }

  handleGiftCardStatusChange(val: GiftCardPaymentMethodStatusEvent) {
    this.giftCardPaymentStatus$.next(val);
    this.creditCardAmount =
      this.giftCardPaymentStatus$.value.priceAfterGiftCards;
    if (
      this.giftCardPaymentStatus$.value.appliedGiftCardCountWithZeroBalance >
        0 ||
      this.giftCardPaymentStatus$.value.appliedGiftCardCount > 0
    ) {
      this.handlePaymentTypeChange(PaymentType.giftCard)();
    }
  }

  handleCreditCardStatusChange(val: CreditCardPaymentMethodStatusEvent) {
    this.creditCardPaymentStatus$.next(val);
    if (this.creditCardPaymentStatus$.value.appliedCreditCardCount > 0) {
      this.handlePaymentTypeChange(PaymentType.creditCard)();
    }
  }

  public turnOffPaymentMethod(paymentType: PaymentType, payments: OrderSubmitRequestPayment[]) {
    this.selectedPaymentMethodForm.patchValue({
      [paymentType]: false,
    });
    if (paymentType === PaymentType.creditCard) {
      // auto recheck cc payment checkbox only if cc fails.
      const cc = payments.filter(
        (f) =>
          f.type === 'anonymousCredit' ||
          f.type === 'registeredCredit' );
      if (cc.length > 0){
        this.creditCardPaymentMethod.onCheckboxUpdate(true);
      }
    }
  }

  public resetPaymentMethodForm() {
    this.selectedPaymentMethodForm.patchValue({
      [PaymentType.inStore]: false,
      [PaymentType.giftCard]: false,
      [PaymentType.creditCard]: false,
      [PaymentType.applePay]: false,
      [PaymentType.venmo]: false,
      [PaymentType.payPal]: false,
      [PaymentType.googlePay]: false,
    });
    this.creditCardPaymentMethod.turnAllAppliedCreditCardsOff();
    this.giftCardPaymentMethod.turnAllAppliedGiftCardsOff();
  }

  private incrementCount() {
    this.buttonCount.next(this.buttonCount.value + 1);
  }

  private filterPaymentsWithOnlyAmountGreaterThan0(
    payments: OrderSubmitRequestPayment[]
  ) {
    const a = payments.filter(
      (f) => f.requestedAmount && f.requestedAmount > 0
    );
    return a;
  }

  private scrollToCreditCardSection() {
    const element = document.getElementById('paymentSection');
    element?.scrollIntoView({ behavior: 'smooth' });
  }

  private getPaymentTypeControl(
    paymentType: PaymentType
  ): AbstractControl<boolean> {
    return this.selectedPaymentMethodForm.controls[paymentType];
  }

  private handlePaymentTypeChange(paymentType: PaymentType) {
    return () => {
      const allowedTypes = new Set(AllowedSplits[paymentType]);
      const allTypes = Object.keys(PaymentType) as PaymentType[];

      const newFormValue = Object.fromEntries(
        allTypes
          .filter((pt) => !allowedTypes.has(pt))
          .filter((pt) => pt !== paymentType)
          .map((pt) => [pt, false])
      );

      this.selectedPaymentMethodForm.patchValue(newFormValue);
      // if gift cards are not allowed for this method
      if (newFormValue[PaymentType.giftCard] !== undefined) {
        this.giftCardPaymentMethod.turnAllAppliedGiftCardsOff();
      }

      if (newFormValue[PaymentType.creditCard] !== undefined) {
        this.creditCardPaymentMethod.turnAllAppliedCreditCardsOff();
      }
    };
  }

  public getSelectedNonExpressPaymentMethods() {
    const selectedFormValue = this.selectedPaymentMethodForm.value;
    const giftCardStatus = this.giftCardPaymentStatus$.value;
    const creditCardStatus = this.creditCardPaymentStatus$.value;

    const methodsToUse: PaymentMethodLike[] = Object.entries({
      ...selectedFormValue,
      [PaymentType.giftCard]:
        selectedFormValue[PaymentType.giftCard] ||
        giftCardStatus.appliedGiftCardCount > 0,
      [PaymentType.creditCard]:
        selectedFormValue[PaymentType.creditCard] ||
        creditCardStatus.appliedCreditCardCount > 0 ||
        creditCardStatus.appliedCreditCardCountWithZeroBalance > 0,
    } as Record<PaymentType, boolean>)
      .filter(([, v]) => Boolean(v))
      .map(([k]) => k)
      .map(
        (paymentType) =>
          ({
            [PaymentType.inStore]: this.inStorePaymentMethod,
            [PaymentType.giftCard]: this.giftCardPaymentMethod,
            [PaymentType.creditCard]: this.creditCardPaymentMethod,
          }[paymentType])
      )
      .filter(Boolean);
    return methodsToUse;
  }

  changePlaceOrder(paymentType: string | null) {
    this.digitalWalletPaymentType = paymentType;
    this.payWithDigitalWallet.emit(paymentType)
  }

  resetRadioSelection() {
    this.digitalWalletPaymentType = null;
    this.payWithDigitalWallet.emit(null);
  }
}
