import { Pipe, PipeTransform } from '@angular/core';

import { LocationResponseData } from '../../../ecomm/types/search-location.types';
import { LocationInfo, StoreInfo } from '../../../ecomm/types/store-info.types';

@Pipe({
  name: 'getLocationDetails'
})
export class GetLocationDetailsPipe implements PipeTransform {
  transform(
    storeInfoOrLocationDetails: StoreInfo | LocationResponseData | null,
    selectedHandoffMode?: string
  ): LocationInfo | null {
    if (this.isStoreInfo(storeInfoOrLocationDetails)) {
      const { storeDetails } = storeInfoOrLocationDetails;
      return {
        ...storeDetails,
        lat: storeDetails.lat,
        long: storeDetails.long,
        storeTimings: storeDetails.storeHours
      };
    }

    if (this.isLocationResponseData(storeInfoOrLocationDetails)) {
      const { location } = storeInfoOrLocationDetails;
      return {
        ...location,
        lat: location.latitude,
        long: location.longitude,
        storeTimings: (
          location?.storeHandoffModeHours?.find(
            (item) => item.handoffMode?.toLowerCase() === selectedHandoffMode
          ) || {}
        ).hours,
        carryoutOverride: location.carryoutOverride,
        deliveryOverride: location.deliveryOverride
      };
    }
  
    return null;
  }

  // Type guard functions
   /* eslint-disable @typescript-eslint/no-explicit-any */
  isStoreInfo(obj: any): obj is StoreInfo {
    return (
      obj !== null &&
      typeof obj === 'object' &&
      'storeDetails' in obj &&
      'categories' in obj &&
      'fulfillmentTimes' in obj
    );
  }

  isLocationResponseData(obj: any): obj is LocationResponseData {
    return obj !== null && typeof obj === 'object' && 'location' in obj;
  }
}
