import {
  Component,
  EventEmitter,
  forwardRef,
  HostBinding,
  Inject,
  Input,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

import { CheckboxComponent } from '../../../../common';
import {
  ConditionalPaymentMethodLike,
  ExpressPaymentMethodLike,
  PaymentInfo,
  PaymentMethodLike,
  PaymentType
} from '../payment-method/payment-method.types';
import moment from 'moment-timezone';
import { filter, firstValueFrom, ReplaySubject, switchMap, tap } from 'rxjs';
import { FeatureFlagService } from '../../../../../ecomm/utils/feature-flag/feature-flag.service';
import {
  BRAIN_TREE,
  BrainTreeSDK,
  PaypalSDK
} from '../../../../../ecomm/providers/brain-tree/brain-tree.provider';
import {
  Config,
  CONFIG
} from '../../../../../ecomm/providers/config/config.provider';
import { NotificationService } from '../../../../../ecomm/utils/notification/notification.service';

declare let paypal: PaypalSDK;

@Component({
  selector: 'wri-paypal-payment-method',
  templateUrl: './paypal-payment-method.component.html',
  styleUrls: ['./paypal-payment-method.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => PaypalPaymentMethodComponent)
    }
  ]
})
export class PaypalPaymentMethodComponent
  extends CheckboxComponent
  implements ExpressPaymentMethodLike, ConditionalPaymentMethodLike, OnInit {
  @Input() totalPrice = 0;
  @Input() expressPayPrerequisitesMet = false;
  @Input() paymentsSelected: PaymentMethodLike[] = [];
  @Input() isPaymentMethodSupported = false;
  @Output() expressPay = new EventEmitter<PaymentInfo>();
  @Output() expressPayClicked = new EventEmitter<string>();
  @Output() paymentSupported = new EventEmitter<boolean>();
  @HostBinding('class.method-supported')
  methodSupported = false;
  private paypalContainerExists = new ReplaySubject<boolean>(1);
  private readonly PAYPAL_ERROR_MSG =
    'There was an issue with PayPal. Please try again.';
  private readonly UPDATE_GUEST_INFO =
    'Please update the form before using PayPal.';
  constructor(
    @Inject(BRAIN_TREE)
    private brainTreeSdkProvider: Promise<BrainTreeSDK | undefined>,
    private notificationService: NotificationService,
    private featureFlagService: FeatureFlagService,
    @Inject(CONFIG) private config: Config
  ) {
    super();
  }

  @ViewChild('paypal') set paypalContainer(val: HTMLElement | null) {
    this.paypalContainerExists.next(!!val);
  }

  async ngOnInit(): Promise<void> {
    if (
      !this.featureFlagService.featureFlags.enablePaypal ||
      !this.brainTreeSdkProvider
    ) {
      this.paymentSupported.emit(false);
      return;
    }

    const brainTreeSdk = await this.brainTreeSdkProvider;
    if (!brainTreeSdk) {
      this.paymentSupported.emit(false);
      return;
    }
    try {
      const brainTreeClient = await brainTreeSdk.client.create({
        authorization: this.config.brainTree.authorization
      });
      if (!brainTreeClient) {
        this.paymentSupported.emit(false);
        return;
      }

      const brainTreeDataCollector = await brainTreeSdk.dataCollector.create({
        client: brainTreeClient
      });
      if (!brainTreeDataCollector) {
        this.paymentSupported.emit(false);
        return;
      }

      const paypalCheckout = await brainTreeSdk.paypalCheckout.create({
        client: brainTreeClient
      });
      if (!paypalCheckout) {
        this.paymentSupported.emit(false);
        return;
      }

      await paypalCheckout.loadPayPalSDK({
        currency: 'USD',
        vault: true
      });

      const paypalConfig = paypal.Buttons({
        fundingSource: paypal.FUNDING.PAYPAL,
        style: {
          color: 'gold',
          shape: 'rect',
          height: 48,
          'border-radius': '8',
          label: 'paypal',
          tagline: false
        },
        createBillingAgreement: () => {
          return paypalCheckout.createPayment({
            flow: 'vault'
          });
        },
        onApprove: (data) => {
          const captureTime = moment().format();
          return paypalCheckout.tokenizePayment(data).then((payload) => {
            this.writeValue(true);
            this.expressPay.emit({
              billingMethod: 'onlinePay',
              payments: [
                {
                  type: 'payPal',
                  requestedAmount: this.totalPrice,
                  nonce: this.config.brainTree.useFakeNonce
                    ? 'fake-venmo-account-nonce'
                    : payload.nonce,
                  payerId: payload.details.payerId,
                  source: 'desktop',
                  dataCapture: {
                    rawData:
                      brainTreeDataCollector.rawDeviceData.correlation_id,
                    dataEventId:
                      brainTreeDataCollector.rawDeviceData.correlation_id,
                    captureTime: captureTime
                  },
                  dataDynamic: {
                    captureTime: captureTime
                  }
                }
              ]
            });
          });
        },
        onClick: async (_data, actions) => {
          this.expressPayClicked.emit(PaymentType.payPal);
          if (this.paymentsSelected.length === 0) {
            if (this.expressPayPrerequisitesMet) {
              return actions.resolve();
            } else {
              this.notificationService.showError(this.UPDATE_GUEST_INFO);
              this.scrollToSection('guest-signup-form');
              return actions.reject();
            }
          } else {
            return actions.reject();
          }
        },
        onError: async (error) => {
          if (!`${error}`.includes('#paypal-button does not exist')) {
            this.notificationService.showError(this.PAYPAL_ERROR_MSG);
          }
        }
      });
      await firstValueFrom(
        this.paypalContainerExists.pipe(
          filter(Boolean),
          switchMap(() => paypalConfig.render('#paypal-button')),
          tap(() => {
            if (
              this.featureFlagService.featureFlags.enablePaypal &&
              this.isPaymentMethodSupported
            ) {
              this.methodSupported = true;
              this.paymentSupported.emit(true);
            }
          })
        )
      );
    } catch (err) {
      this.paymentSupported.emit(false);
      console.error(err);
      return;
    }
  }

  private scrollToSection(elementId: string, extraOffset = 0) {
    const element = document.getElementById(elementId);
    const headerOffset = 45 + extraOffset;
    const elementPosition = element?.getBoundingClientRect().top || 0;
    const offsetPosition = elementPosition + window.pageYOffset - headerOffset;
    window.scrollTo({
      behavior: 'smooth',
      top: offsetPosition
    });
  }
}
