<wri-page-loading-indicator *ngIf="isLoading"></wri-page-loading-indicator>

<div class="promo-heading coupon-applied-wrapper">
  <span tabindex="0">
    {{ !isCouponApplied ? 'Offer:' : 'Offer Applied:' }}</span
  >
  <a
      *ngIf="isCouponApplied"
      wriFocusOutline
      (click)="removePromoCode()"
      data-testid="remove-promo-code"
  >
    <wri-icon class="icon" icon="wri-delete"></wri-icon>
  </a>
</div>
<ng-container
    *ngIf="isCouponApplied; then CouponApplied; else ApplyPromoCode"
></ng-container>
<ng-template #ApplyPromoCode>
  <ng-container
      *ngIf="
      activeOfferFeatureState?.activeOfferDetails;
      then ApplyActivePromoCode
    "
  ></ng-container>
  <ng-template #ApplyActivePromoCode>
    <div class="apply-promo-code-wrapper" [ngClass]="{ 'error-border-color': showClippedOfferErrorSection }">
      <div class="flex">
        <wri-icon icon="wri-offer-badge" class="icon"></wri-icon>
        <div class="offer-code-data">
          <div>
            Code: {{ activeOfferFeatureState.activeOfferDetails?.code }}
          </div>
          <div>
            Description:
            <span class="offer-code-description">{{
                activeOfferFeatureState.activeOfferDetails?.customerDescription
              }}</span>
          </div>
          <a
              *ngIf="isMobile"
              wriFocusOutline
              tabindex="0"
              (click)="
              quickApplyPromoCode(activeOfferFeatureState.activeOfferDetails?.code)
            "
              class="apply-btn wri-btn wri-btn-text"
              aria-label="Apply"
              data-testid="quick-apply-active-offer"
          >
            Apply
          </a>
        </div>
        <a
            *ngIf="!isMobile"
            wriFocusOutline
            tabindex="0"
            (click)="
            quickApplyPromoCode(activeOfferFeatureState.activeOfferDetails?.code)
          "
            class="apply-btn wri-btn wri-btn-text"
            aria-label="Apply"
            data-testid="quick-apply-active-offer"
        >
          Apply
        </a>
      </div>
    </div>

    <div *ngIf="showClippedOfferErrorSection"
         class="apply-promo-code-error bottom-margin"
         data-testid="apply-active-offer-error-section">
    <span data-testid="apply-active-offer-error-text" class="apply-promo-code-error-text">
      {{ offerInputErrorMessage }}
      <a *ngIf="showInfoIcon" wriFocusOutline data-testid="active-offer-error-info"
         (click)="openOfferInfoModal(activeOfferFeatureState.activeOfferDetails?.code)">
        <wri-icon class="icon" icon="wri-info"></wri-icon>
      </a>
    </span>

    </div>

    <a
        *ngIf="!promoCodeSectionWhenOfferActive"
        wriFocusOutline
        tabindex="0"
        class="different-offer-btn wri-btn wri-btn-text"
        aria-label="Enter a different offer"
        (click)="handleDifferentOfferBtnClick()"
    >
      + Enter a different offer
    </a>
  </ng-template>
  <div
      *ngIf="
      (activeOfferFeatureState?.activeOfferDetails &&
        promoCodeSectionWhenOfferActive) ||
      !activeOfferFeatureState?.activeOfferDetails
    "
      class="apply-promo-code"
      [ngClass]="{ 'error-border-color': showInputApplyOfferErrorSection }"
      data-testid="apply-promo-code-section"
  >
    <input
        type="text"
        class="form-control border-right-none promo-code-input"
        placeholder="Enter Offer Code"
        name="couponCode"
        [(ngModel)]="couponCodeInput"
        data-testid="coupon-code-input"
        (input)="resetOfferErrorOnModify()"
        required
    />

    <button
        class="wri-btn wri-btn-primary-icon"
        (click)="applyPromoCode()"
        aria-label="Apply Offer Code"
        data-testid="apply-promo-code"
    >
      <wri-icon class="icon" icon="wri-plus"></wri-icon>
    </button>
  </div>
  <div *ngIf="showInputApplyOfferErrorSection"
       class="apply-promo-code-error"
       data-testid="apply-promo-code-error-section">
    <span data-testid="apply-promo-code-error-text" class="apply-promo-code-error-text">
      {{ offerInputErrorMessage }}
      <a *ngIf="showInfoIcon" wriFocusOutline data-testid="offer-info"
         (click)="openOfferInfoModal(this.couponCodeInput.trim())">
        <wri-icon class="icon" icon="wri-info"></wri-icon>
      </a>
    </span>

  </div>
</ng-template>
<ng-template #CouponApplied>
  <div
      class="coupon-applied"
      [ngClass]="{
      'invalid-coupon': cartFeatureState.cart?.offer.status === 'invalid'
    }"
      data-testid="coupon-applied-section"
  >
    <span tabindex="0" class="coupon-code" data-testid="coupon-code">
      Code: <b>{{ cartFeatureState.cart?.offer.code }}</b>
    </span>
    <span tabindex="0" data-testid="coupon-code-desc">
      Description:
      <span class="coupon-desc">{{
          cartFeatureState.cart?.offer.customerDescription
        }}</span>
    </span>
  </div>
  <div
      data-testid="coupon-error-section"
      class="coupon-error"
      *ngIf="cartFeatureState.cart?.offer.status === 'invalid'"
  >
    {{ cartFeatureState.cart?.offer.statusReason }}
    <a wriFocusOutline (click)="openOfferInfoModal(this.cartFeatureState?.cart?.offer?.code)">
      <wri-icon class="icon" icon="wri-info"></wri-icon>
    </a>
  </div>
</ng-template>
<ng-template #commonModal let-close="close" let-dismiss="dismiss">
  <wri-common-modal [close]="close" [dismiss]="dismiss">
    <div *ngIf="offerInfoLoading" class="spinner-wrapper-overlay">
      <wri-spinner></wri-spinner>
    </div>
    <div class="wri-modal__wrapper" *ngIf="!offerInfoLoading">
      <div class="wri-modal__title">
        {{
          offerErrorResponse
            ? 'Oops!'
            : offerDetails?.promotionalCriteria
            ? 'Offer Eligibility'
            : 'Offer Description'
        }}
      </div>
      <div class="wri-modal__description">
        {{
          offerDetails?.promotionalCriteria ||
            offerDetails?.customerDescription ||
            offerErrorResponse
        }}
      </div>
    </div>
  </wri-common-modal>
</ng-template>
