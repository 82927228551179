export type ModGroups = {
  modifierGroupId: string;
  modifiers: Modifiers[];
  sortOrder?: number;
  modifierGroupSortOrder?: number
};

export type Modifiers = {
  modifierId: string;
  quantity: number;
  price?: number;
  name?: string;
  unitPrice?: number;
  modifierGroups?: ModGroups[];
  touched?: boolean;
  isValid?: boolean;
};

export const FlavorSpiceHeading = {
  LIMITED_TIME: 'limited time',
  HOT: 'all the heat',
  MEDIUM: 'some heat',
  MILD: 'no heat'
} as const;

export const FlavorSpiceHeadingColors = {
  LIMITED_TIME: '#D82525',
  HOT: '#D82525',
  MEDIUM: '#FD8809',
  MILD: '#006938'
} as const;

export const HeatIconClasses = {
  HOT: 'hot',
  MEDIUM: 'medium',
  MILD: 'mild',
  UNFILLED: 'unfilled',
  UNKNWON: 'unknown'
} as const;
