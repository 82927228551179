/* eslint-disable @typescript-eslint/no-non-null-assertion */
import {
  AfterViewInit,
  Component,
  EventEmitter,
  forwardRef,
  Input,
  OnInit,
  Output
} from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { find, findIndex } from 'lodash';

import { AnalyticsService } from '../../../../ecomm/providers/legacy-providers/analytics.service';
import {
  MenuItem,
  MenuModifier,
  MenuModifierGroup,
  MenuModifierGroupItem
} from '../../../../ecomm/types/store-info.types';
import {
  HeatScalePipe,
  ModGroups,
  ModifierModalElementChangeService,
  Modifiers,
  PushOrReplacePipe
} from '../../../common';
import { ChooseFlavorModalComponent } from '../choose-flavor-modal/choose-flavor-modal.component';
import { ModifierModalComponent } from '../modifier-modal/modifier-modal.component';

@Component({
  selector: 'wri-add-on-card',
  templateUrl: './add-on-card.component.html',
  styleUrls: ['./add-on-card.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => AddOnCardComponent)
    }
  ]
})
export class AddOnCardComponent implements OnInit, AfterViewInit {
  @Input() item: MenuItem | undefined;
  @Input() first = false;
  @Input() image: string | undefined;
  @Input() imageDescription: string | undefined;
  @Input() name: string | undefined;
  @Input() description: string | undefined;
  @Input() price!: number;
  @Input() calorieRange: string | number | undefined;
  @Input() parentItemName!: string;

  /** addOnModifiersGroups is the list of flavors available in an add-on item*/
  @Input() addOnModifiersGroups?: MenuModifierGroup[] = [];
  @Output() valuesChanged = new EventEmitter<{ modifiers: Modifiers[] | [] }>();
  hot: MenuModifier[] = [];
  medium: MenuModifier[] = [];
  mild: MenuModifier[] = [];
  limited: MenuModifier[] = [];
  selectedAddOnFlavor: MenuModifier | undefined; // = {} as Modifier| undefined;
  selectedAddOnModifiers: Modifiers[] = [];
  private hasSelectedModifierElementsChanged = false;
  public isAddOnValid = false;

  /** selectedItemModifierGroups is to store selected flavors in add-on item
   * in this case - only one flavor is added
   */
  selectedItemModifierGroups: ModGroups[] = [];
  private heatScaleTransform = new HeatScalePipe();
  /** Implement ControlValueAccessor */
  private disabled = false;
  private touched = false;

  constructor(
    public modalService: NgbModal,
    private modifierModalElementChangeService: ModifierModalElementChangeService,
    private analyticsService: AnalyticsService
  ) {}

  /** Implement ControlValueAccessor */
  set value(value: MenuModifier) {
    this.onChange(value);
  }

  ngOnInit() {
    if (this.addOnModifiersGroups)
      this.addOnModifiersGroups.forEach((mod) => {
        if (mod.type === 'flavor') {
          this.buildFlavorModifiersInAddOns();
        }
      });
  }

  ngAfterViewInit(): void {
    if (this.item?.isDefault && this.item.modifierGroups) {
      if (this.hasOnlyFlavorsModGroup()) {
        this.item.modifierGroups[0].modifierGroupElements.forEach(
          (modifierGroupElement) => {
            if (modifierGroupElement.modifier?.isDefault) {
              this.selectedAddOnFlavor = modifierGroupElement.modifier;
              if (this.item?.modifierGroups)
                this.updateAddOnModifiersWithFlavor(this.selectedAddOnFlavor);
              this.valuesChanged.emit({
                modifiers: this.selectedAddOnModifiers
              });
            }
          }
        );
      } else {
        this.item.modifierGroups?.forEach((itemModifierGroup) => {
          itemModifierGroup.modifierGroupElements.forEach(
            (modifierGroupElement) => {
              if (
                modifierGroupElement.modifier &&
                modifierGroupElement.modifier?.isDefault &&
                !(modifierGroupElement.modifier as MenuModifier)?.outOfStock &&
                modifierGroupElement.modifier?.availableInSchedule
              ) {
                this.modifierModalElementChangeService.onChangeModalModifierElement(
                  this.selectedItemModifierGroups, // this variable will be updated with default selected modifiers
                  true,
                  itemModifierGroup.maxSelectionsAllowed > 1
                    ? 'multiple'
                    : 'single',
                  itemModifierGroup.id,
                  modifierGroupElement.modifier!,
                  itemModifierGroup.sortOrder
                );
              } else if (
                modifierGroupElement.item &&
                modifierGroupElement.item?.isDefault &&
                !(modifierGroupElement.item as MenuModifier)?.outOfStock &&
                modifierGroupElement.item?.availableInSchedule
              ) {
                this.modifierModalElementChangeService.onChangeModalModifierElement(
                  this.selectedItemModifierGroups, // this variable will be updated with default selected modifiers
                  true,
                  itemModifierGroup.maxSelectionsAllowed > 1
                    ? 'multiple'
                    : 'single',
                  itemModifierGroup.id,
                  modifierGroupElement.item!,
                  itemModifierGroup.sortOrder
                );
              }
            }
          );
        });
        this.updateAddOnModifiersWithOtherModGroups();
        this.valuesChanged.emit({
          modifiers: this.selectedAddOnModifiers
        });
        this.isAddOnValid = true;
      }
    }
  }

  hasPrice(price: number): boolean {
    return parseFloat(price?.toString()) !== 0;
  }

  isAddOnCardSelected(): boolean {
    if (this.addOnModifiersGroups && this.addOnModifiersGroups.length > 0) {
      if (this.hasOnlyFlavorsModGroup())
        return this.selectedAddOnFlavor ? true : false;
      else return this.isAddOnValid;
    }
    return find(this.selectedAddOnModifiers, ['modifierId', this.item?.id])
      ? true
      : false;
  }

  private getSelectedAddonModifierIndex() {
    return findIndex(this.selectedAddOnModifiers, [
      'modifierId',
      this.item?.id
    ]);
  }

  async addOnClick() {
    this.logAddOnsGAEvent('add_ons_view');
    // for complex modifiers
    if (this.addOnModifiersGroups && this.addOnModifiersGroups.length > 0) {
      await this.openModal();
    } else {
      // for simple modifiers
      // toggle / remove from array if already present
      if (this.isAddOnCardSelected()) {
        const selectedAddonModifierIndex = this.getSelectedAddonModifierIndex();
        if (selectedAddonModifierIndex > -1)
          this.selectedAddOnModifiers.splice(selectedAddonModifierIndex, 1);
      } else {
        this.selectedAddOnModifiers = new PushOrReplacePipe().transform(
          this.selectedAddOnModifiers,
          'modifierId',
          this.item?.id || '',
          'modifiers',
          // objToPush
          {
            modifierId: this.item?.id || '',
            quantity: 1,
            price: this.item?.price,
            name: this.item?.name
          }
        );
      }
      this.valuesChanged.emit({
        modifiers: this.selectedAddOnModifiers
      });
      this.logAddOnsGAEvent('add_ons_add');
    }
  }

  private logAddOnsGAEvent(eventName: string): void {
    try {
      this.analyticsService.logGaEvent({
        event: eventName,
        value: this.price || 0,
        currency: 'USD',
        item: this.name || '',
        parent_item: this.parentItemName || ''
      });
    } catch (ignore) {
      // if GA cannot log event (ie due to an ad-blocker), catch error and continue
    }
  }

  toggleAddOn($event: MouseEvent) {
    if (
      this.hasOnlyFlavorsModGroup() &&
      this.selectedAddOnFlavor !== undefined
    ) {
      $event.stopImmediatePropagation();
      this.selectedAddOnFlavor = undefined;
      const selectedAddonModifierIndex = this.getSelectedAddonModifierIndex();
      if (selectedAddonModifierIndex > -1) {
        this.logAddOnsGAEvent('add_ons_remove');
        this.selectedAddOnModifiers.splice(selectedAddonModifierIndex, 1);
      }
      this.valuesChanged.emit({
        modifiers: this.selectedAddOnModifiers
      });
    } else if (!this.hasOnlyFlavorsModGroup() && this.isAddOnValid) {
      $event.stopImmediatePropagation();
      this.hasSelectedModifierElementsChanged = false;
      const selectedAddonModifierIndex = this.getSelectedAddonModifierIndex();
      if (selectedAddonModifierIndex > -1) {
        this.logAddOnsGAEvent('add_ons_remove');
        this.selectedItemModifierGroups = [];
        this.selectedAddOnModifiers.splice(selectedAddonModifierIndex, 1);
        this.isAddOnValid = false;
      }
      this.valuesChanged.emit({
        modifiers: this.selectedAddOnModifiers
      });
    }
  }

  async addOnEdit($event: MouseEvent) {
    $event.stopImmediatePropagation();
    if (this.addOnModifiersGroups && this.addOnModifiersGroups.length > 0) {
      await this.openModal();
    }

    this.valuesChanged.emit({
      modifiers: this.selectedAddOnModifiers
    });
  }

  onChange: (v: MenuModifier | undefined) => void = () => void 0;

  onTouched: () => void = () => void 0;

  writeValue(newValue: MenuModifier): void {
    this.value = newValue;
  }

  registerOnChange(
    onChange: (v: MenuModifier | undefined) => Modifiers | undefined
  ): void {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: () => void): void {
    this.onTouched = onTouched;
  }

  markAsTouched() {
    if (!this.touched) {
      this.onTouched();
      this.touched = true;
    }
  }

  setDisabledState(disabled: boolean) {
    this.disabled = disabled;
  }

  private updateAddOnModifiersWithFlavor(
    selectedAddOnFlavor: MenuModifier | undefined
  ) {
    const selectedAddOnObjForPayload: Modifiers = {
      modifierId: selectedAddOnFlavor?.id || '',
      quantity: this.addOnModifiersGroups
        ? this.addOnModifiersGroups[0].aggregateQuantity
        : 1,
      price: selectedAddOnFlavor?.price,
      name: selectedAddOnFlavor?.name
    };

    /** if modifierGroup already exists in selectedItemModifierGroups array,
     * then replace, else push*/
    this.selectedItemModifierGroups = new PushOrReplacePipe().transform(
      this.selectedItemModifierGroups,
      'modifierGroupId',
      this.addOnModifiersGroups ? this.addOnModifiersGroups[0].id : '',
      'modifiers',
      // objToPush
      {
        modifierGroupId: this.addOnModifiersGroups
          ? this.addOnModifiersGroups[0].id
          : '',
        modifiers: [selectedAddOnObjForPayload]
      }
    );

    // trigger change in the form to reflect modifier modal values
    this.onTouched();
    this.onChange(this.selectedAddOnFlavor);
    this.selectedAddOnModifiers = new PushOrReplacePipe().transform(
      this.selectedAddOnModifiers,
      'modifierId',
      this.item?.id || '',
      'modifiers',
      // objToPush
      {
        modifierId: this.item?.id || '',
        quantity: 1,
        price: this.item?.price,
        name: this.item?.name,
        modifierGroups: this.selectedItemModifierGroups
      }
    );
    this.valuesChanged.emit({
      modifiers: this.selectedAddOnModifiers
    });
  }

  private async handleFlavorModal() {
    const modalRef = this.modalService.open(ChooseFlavorModalComponent, {
      windowClass: 'add-on-flavor-modal',
      centered: true,
      scrollable: true,
      modalDialogClass: 'modal-fullscreen-md-down'
    });
    modalRef.componentInstance.limited = this.limited;
    modalRef.componentInstance.hot = this.hot;
    modalRef.componentInstance.medium = this.medium;
    modalRef.componentInstance.mild = this.mild;
    modalRef.componentInstance.editFlavor = this.selectedAddOnFlavor;

    const values = await modalRef.result;
    if (values) {
      const { selectedAddOnFlavor, editFlavor, isApplied } = values;
      if (isApplied) {
        this.selectedAddOnFlavor = selectedAddOnFlavor ?? editFlavor;
        this.updateAddOnModifiersWithFlavor(this.selectedAddOnFlavor);
        this.logAddOnsGAEvent('add_ons_add');
      }
    }
  }

  private updateAddOnModifiersWithOtherModGroups() {
    // trigger change in the form to reflect modifier modal values
    this.addOnModifiersGroups?.forEach((itemModifierGroup) => {
      if (itemModifierGroup.type == 'flavor') {
        const selectedFlavorModifer = this.selectedItemModifierGroups.find(
          (a) => a.modifierGroupId === itemModifierGroup.id
        );
        if (selectedFlavorModifer) {
          selectedFlavorModifer.modifiers.forEach((modifierGroupElement) => {
            const selectedAddOnObjForPayload: Modifiers = {
              modifierId: modifierGroupElement?.modifierId || '',
              quantity: this.addOnModifiersGroups
                ? itemModifierGroup.aggregateQuantity
                : 1,
              price: modifierGroupElement?.price,
              name: modifierGroupElement?.name
            };

            this.selectedItemModifierGroups = new PushOrReplacePipe().transform(
              this.selectedItemModifierGroups,
              'modifierGroupId',
              itemModifierGroup.id,
              'modifiers',
              // objToPush
              {
                modifierGroupId: this.addOnModifiersGroups
                  ? itemModifierGroup.id
                  : '',
                modifiers: [selectedAddOnObjForPayload]
              }
            );
          });
        }
      }
    });
    this.onTouched();
    this.onChange(this.selectedAddOnFlavor);
    this.selectedAddOnModifiers = new PushOrReplacePipe().transform(
      this.selectedAddOnModifiers,
      'modifierId',
      this.item?.id || '',
      'modifiers',
      // objToPush
      {
        modifierId: this.item?.id || '',
        quantity: 1,
        price: this.item?.price,
        name: this.item?.name,
        modifierGroups: this.selectedItemModifierGroups
      }
    );
    this.valuesChanged.emit({
      modifiers: this.selectedAddOnModifiers
    });
  }

  private async handleStandardModal() {
    const modalRef = this.modalService.open(ModifierModalComponent, {
      windowClass: 'modifier-common-modal',
      centered: true,
      scrollable: true,
      modalDialogClass: 'modal-fullscreen-md-down'
    });
    modalRef.componentInstance.data = this.addOnModifiersGroups;
    modalRef.componentInstance.limited = this.limited;
    modalRef.componentInstance.hot = this.hot;
    modalRef.componentInstance.medium = this.medium;
    modalRef.componentInstance.mild = this.mild;
    modalRef.componentInstance.editFlavor = this.selectedAddOnFlavor;
    modalRef.componentInstance.selectedItemModifierGroups =
      this.selectedItemModifierGroups;
    modalRef.componentInstance.hasSelectedModifierElementsChanged =
      this.hasSelectedModifierElementsChanged;

    const values = await modalRef.result;
    if (values) {
      const { itemModifierGroups, isValid, isApplied } = values;
      this.isAddOnValid = isValid;
      if (isApplied) {
        this.hasSelectedModifierElementsChanged = true;
        this.selectedItemModifierGroups = itemModifierGroups;
        this.updateAddOnModifiersWithOtherModGroups();
        this.logAddOnsGAEvent('add_ons_add');
      }
    }
  }

  public hasOnlyFlavorsModGroup(): boolean {
    const hasFlavorsIndex = findIndex(this.addOnModifiersGroups, [
      'type',
      'flavor'
    ]);
    if (
      hasFlavorsIndex !== undefined &&
      hasFlavorsIndex > -1 &&
      this.addOnModifiersGroups?.length === 1
    ) {
      return true;
    }
    return false;
  }

  private async openModal() {
    if (this.hasOnlyFlavorsModGroup()) {
      await this.handleFlavorModal();
    } else {
      await this.handleStandardModal();
    }
  }

  private buildFlavorModifiersInAddOns() {
    const flavorIndex = findIndex(this.addOnModifiersGroups, [
      'type',
      'flavor'
    ]);
    if (
      flavorIndex > -1 &&
      this.addOnModifiersGroups &&
      this.addOnModifiersGroups[flavorIndex]?.modifierGroupElements
    ) {
      this.sortByHeatScale(
        this.addOnModifiersGroups[flavorIndex].modifierGroupElements
      );
      this.sortByIsPopular(
        this.addOnModifiersGroups[flavorIndex].modifierGroupElements
      );

      this.addOnModifiersGroups[flavorIndex].modifierGroupElements
        .filter((el) => el.modifier)
        .map((el) => {
          if (el.modifier!.isLimitedTime) {
            this.limited.push(el.modifier!);
          } else {
            const heatScale = this.heatScaleTransform.transform(el.modifier!);
            if (heatScale > 3) {
              this.hot.push(el.modifier!);
            }
            if (heatScale === 2 || heatScale === 3) {
              this.medium.push(el.modifier!);
            }
            if (heatScale < 2) {
              this.mild.push(el.modifier!);
            }
          }
        });
    }
  }

  private sortByIsPopular(mod: MenuModifierGroupItem[]) {
    mod.sort(function (x, y) {
      return x.modifier?.isPopular === y.modifier?.isPopular
        ? 0
        : x.modifier?.isPopular
        ? -1
        : 1;
    });
  }

  private sortByHeatScale(el: MenuModifierGroupItem[]) {
    el?.sort((x, y) => {
      return (
        this.heatScaleTransform.transform(y.modifier!) -
        this.heatScaleTransform.transform(x.modifier!)
      );
    });
  }
}
