<div class="wri-price-summary-wrapper" data-testid="price-summary">
  <div class="flex-col subtotal-summary">
    <span data-testid="subtotal">Subtotal</span>
    <span data-testid="subtotal-val">{{ cart?.subtotal | currency }}</span>
  </div>
  <div
    *ngIf="
      feesCalculationService.isDelivery(
        handoffModeFromOrderDetails
          ? cart?.handoffMode
          : cartFeatureState?.cart?.handoffMode
      )
    "
    class="flex-col subtotal-delivery-fees"
  >
    <span data-testid="delivery-fees">Delivery Fees</span>
    <span data-testid="delivery-fees-val">{{
      feesCalculationService.calculateDeliveryFees(cart.fees) | currency
    }}</span>
  </div>
  <div class="flex-col tax-summary">
    <span data-testid="tax">
      <ng-container
        *ngIf="
          feesCalculationService.isStandardCategory(cart?.fees);
          then taxAndFees;
          else onlyTax
        "
      ></ng-container>

      <ng-template #taxAndFees>
        {{ cart?.fees.length > 0 | taxFeesPreposition }}
        <a
          wriFocusOutline
          role="button"
          class="info-icon"
          data-testid="info-icon"
          (click)="openFeesModal()"
        >
          <wri-icon
            icon="wri-info"
            role="text"
            aria-label="view tax and fees breakout"
          ></wri-icon>
        </a>
      </ng-template>
      <ng-template #commonModal let-close="close" let-dismiss="dismiss">
        <wri-common-modal [close]="close" [dismiss]="dismiss">
          <div class="wri-modal__wrapper">
            <div
              data-testid="tax-and-fees-modal-title"
              class="wri-modal__title"
            >
              Tax & Fees
            </div>
            <ul class="tax-and-fees-modal__list-wrapper">
              <li
                data-testid="tax-and-fees-modal-label"
                class="tax-and-fees-modal__total_tax_wrapper"
              >
                <span class="tax-and-fees-modal__label">Tax: </span>
                <span
                  data-testid="tax-and-fees-modal-tax-value"
                  class="tax-and-fees-modal__value"
                  >{{ cart?.totalTax | currency }}</span
                >
              </li>
              <li
                *ngFor="let fee of cart?.fees"
                class="tax-and-fees-modal__line-item"
              >
                <ng-container
                  *ngIf="
                    fee.category !== 'delivery' && fee.category !== 'donation'
                  "
                >
                  <div data-testid="tax-and-fees-modal-fee-label">
                    <span
                      data-testid="tax-and-fees-modal-fee-name-value"
                      class="tax-and-fees-modal__label"
                    >
                      {{ fee.name }}:
                    </span>
                    <span
                      data-testid="tax-and-fees-modal-fee-amount-value"
                      class="tax-and-fees-modal__value"
                      >{{ fee.amount | currency }}</span
                    >
                  </div>
                  <span
                    *ngIf="fee.description"
                    data-testid="tax-and-fees-modal-description"
                    class="tax-and-fees-modal__description"
                  >
                    {{ fee.description }}
                  </span>
                </ng-container>
              </li>
            </ul>
          </div>
        </wri-common-modal>
      </ng-template>
      <ng-template #onlyTax>Tax</ng-template>
    </span>
    <span data-testid="tax-val">{{
      feesCalculationService.calculateFees(cart?.fees) + cart?.totalTax
        | currency
    }}</span>
  </div>
  <ng-container *ngIf="cart?.offer">
    <div class="flex-col discount-summary">
      <span data-testid="discount">Discount</span>
      <span data-testid="discount-val" class="flex-col">
        -{{ cart?.offer?.priceAdjustment | currency }}
      </span>
    </div>
  </ng-container>

  <div class="flex-col subtotal-tip-amount" *ngIf="cart?.tip">
    <span data-testid="tip-amount">Tip</span>
    <span data-testid="tip-amount-val">{{ cart?.tip.amount | currency }}</span>
  </div>
  <div
    class="flex-col donation-amount"
    *ngIf="isDonationCategoryAvailableInCart()"
  >
    <span data-testid="donation-description">{{
      getDonationDescription()
    }}</span>
    <span data-testid="donation-amount-val" class="donation-amount-val">{{
      getDonationAmount() | currency
    }}</span>
  </div>

  <wri-donation  *ngIf="showRoundUpFeesSelection" [cart]="cart" [roundupCharityData]="roundupCharityData"></wri-donation>

  <div class="flex-col total-summary">
    <span data-testid="total" class="flex-col total-text">
      TOTAL
      <span class="total-quantity">
        ({{getCartItemsQuantity() | pluralize : 'Item' : 's' }})
      </span>
    </span>
    <span data-testid="total-val" class="flex-col total-price">
      {{ cart?.total | currency }}
    </span>
  </div>
</div>
